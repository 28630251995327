import React, { useState, useRef } from 'react';
import { jsPDF } from "jspdf";
import axios from '../axios';
import { Link } from 'react-router-dom';

const StandAccordion = ({ content, id, onChange }) => {
    const ChangeStandUserStatus = (e, id) => {
        const StandUserStatus = async () => {
            let apidata = {
                user_id: id
            }
            await axios.post('stand/stand_user_status_change', apidata)
                .then((result) => {
                    if (result.data.status == true) {
                        const message = result.data.message;
                        window.location.reload()
                    }
                    // setErrorMessage('')
                }).catch((error) => {
                    console.log(error);
                    // setErrorMessage(error.response.data.message)
                })
        };
        StandUserStatus();
    }

    const StandUserDelete = (id) => {
        const StandUserdelete = async () => {
            let apidata = {
                id: id
            }
            await axios.post('stand/StandUserDelete', apidata)
                .then((result) => {
                    if (result.data.status == true) {
                        // const message = result.data.message;
                        window.location.reload()
                    }
                }).catch((error) => {
                    console.log(error);
                })
        };
        StandUserdelete();
    }
    return (
        <>
            <div className="accordion-item">
                <div className="acc-tabs">
                    <span className="row acc-tabs-heading">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                            {id}
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-2 col-sm-2 col-11">
                            <div className='stand_content_image'>
                                {/* className='stand_content' */}
                                <div>
                                    <span>{content.user_details.first_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 text-center col-12">
                            <span>
                                {content.user_details.email}
                            </span>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center col-12">
                            <span>
                                {content.type == 1 ? 'Seller Admin' : 'Seller Employee'}
                            </span>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12">
                            <span className="festival_delete">
                                <h3
                                    onClick={() => {
                                        const confirmBox = window.confirm(
                                            "Do you really want to delete this User?"
                                        )
                                        if (confirmBox === true) {
                                            StandUserDelete(content.id)
                                        }
                                    }}><i className='fa fa-trash'></i></h3>
                            </span>
                        </div>
                    </span>
                    {/* <h2 onClick={onToggle}> <i className="fa fa-chevron-down arrowRotate"></i></h2> */}
                    {/* 
                    {active &&
                        <div className="tabShowHide" ref={contentEl}>

                            <table className="stand-tbl">
                                <tbody>
                                    <tr>
                                        <td width="10%">TOTAL SALE
                                            <strong>{content.total_sale == null ? '0' : content.total_sale}</strong>
                                        </td>
                                        <td width="10%">NUMBER OF BUYER <strong>{content.number_of_buyer == null ? '0' : content.number_of_buyer}</strong></td>
                                        <td width="10%">AVERAGE BASKET <strong>{content.average_basket == null ? '0' : content.average_basket}</strong></td>
                                        <td width="10%">SALES AMOUNT <strong>{content.sales_amount == null ? '0' : content.sales_amount}</strong></td>
                                        <td width="15%">WITHDRAW STATUS <strong className="yellow-text">PENDING</strong></td>
                                        <td width="15%">
                                            PASSWORD FOR SALER ADMIN
                                            <strong className="yellow-text">
                                                <div>{ShowAdminPassword != null ? ShowAdminPassword : content.password_seller_admin}</div>
                                                <input
                                                    name='password_seller_admin'
                                                    defaultValue={content.password_seller_admin}
                                                    onChange={(e) => setAdminPassword(e.target.value)}
                                                />
                                                <button className='stand_btn' onClick={() => password_update('admin')}>Update</button>
                                            </strong>
                                            {Error.AdminPassword != null &&
                                                <div className='error_text'>{Error.AdminPassword}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" height="40"></td>
                                    </tr>
                                    <tr>
                                        <td width="10%">SET INDIVIDUAL COMMISSION
                                            <div>
                                                <strong>{content.stand_commission}%</strong>
                                                <input
                                                    name='stand_commission'
                                                    defaultValue={content.stand_commission}
                                                    onChange={(e) => setCommission(e.target.value)}
                                                    style={{ width: '70px' }}
                                                />
                                                <button className='stand_btn' onClick={() => stand_commission_change()}>Update</button>
                                            </div>
                                        </td>
                                        <td colspan="4"></td>
                                        <td width="10%">
                                            PASSWORD FOR SALER EMPLOYEE
                                            <strong className="yellow-text">
                                                <div>{ShowEmployeePassword != null ? ShowEmployeePassword : content.password_seller_employee}</div>
                                                <input
                                                    name='password_seller_employee'
                                                    defaultValue={content.password_seller_employee}
                                                    onChange={(e) => setEmployeePassword(e.target.value)}
                                                />
                                                <button className='stand_btn' onClick={() => password_update('employee')}>Update</button>
                                            </strong>
                                            {Error.EmployeePassword != null &&
                                                <div className='error_text'>{Error.EmployeePassword}</div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    } */}
                </div>
            </div >
        </>

    );
};

export default StandAccordion;