import React, { useEffect, useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios'

import { Routes, Route, Outlet, Link, useParams } from "react-router-dom"
import LoginHeader from '../component/loginHeader'

// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';

function Settings() {
    let { id } = useParams()

    const sessiontoken = localStorage.getItem('token');
    const session_user_data = JSON.parse(localStorage.getItem('user_data'));
    if (sessiontoken === null) {
        window.location.href = "/";
    }


    const [Currency, setCurrency] = useState([{}])
    const [festivalDetail, setfestivalDetail] = useState({});

    const [Type, setType] = useState('')
    const [Message, setMessage] = useState('')

    const [CurrencyId, setCurrencyId] = useState('')

    const [Commission, setCommission] = useState('')
    const [CreditCard, setCreditCard] = useState('')


    const [CardData, setCardData] = useState('');
    const [CardDelete, setCardDelete] = useState(false);
    const [formData, setFormData] = useState({ name: null, number: null, mm: null, yy: null, cvc: null });
    const [validate, setValidate] = useState(false);

    const [UserDetails, setUserDetails] = useState({});


    const handleInput = (e) => {
        if (e.target.name === "number" && e.target.value) {
            e.target.value = e.target.value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ').trim().slice(0, 19);
        }

        if (e.target.name === "mm" || e.target.name === "yy") {
            e.target.value = e.target.value.toString().replace(/[^0-9]/g, '').substring(0, 2);
            if (e.target.name === "mm" && e.target.value > 12) e.target.value = "12"
        }

        if (e.target.name === "cvc") {
            e.target.value = e.target.value.substring(0, 4);
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleError = (target, message, type = "add") => {
        if (type === "add") {
            const submitBtn = document.querySelector('.btn-submit');
            submitBtn.classList.add("shake");
            submitBtn.addEventListener("animationend", () => submitBtn.classList.remove("shake"));
        }

        document.querySelector(`.label${target}`).nextElementSibling.innerHTML = message;
        document.querySelector(`[name="${target}"]`).classList[type](`input--error`);
        document.querySelector(`.label${target}`).nextElementSibling.classList[type === "add" ? "remove" : "add"]("info--hidden");
    }

    const animateSlider = (validate) => {
        let axis;
        window.matchMedia("(max-width: 750px)").matches ? axis = "Y" : axis = "X";
        // document.querySelector('.cardOverflow > div').style.transform = `translate${axis}(50${axis === "Y" ? "vh" : "vw"})`;

        document.body.classList.add("body-slider");

        setTimeout(() => {
            setValidate(validate);
            document.body.classList.remove("body-slider");
            // document.querySelector('.cardOverflow > div').style.transform = "translate(0)";
        }, 500);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        for (let i in formData) {
            if (!formData[i]) {
                handleError(i, "Can`t be blank");
            } else handleError(i, "", "remove");
        }

        if (formData.number) {
            if (formData.number.length < 19) {
                handleError("number", "Number is too short");
            } else if (formData.number.match(/[^0-9\s]/g)) {
                handleError("number", "Wrong format, numbers only");
            } else handleError("number", "", "remove");
        }

        if (formData.cvc) {
            if (formData.cvc.length < 3) {
                handleError("cvc", "CVC is too short");
            } else handleError("cvc", "", "remove");
        }

        if (!formData.mm) handleError("mm", "Can`t be blank");
        if (!formData.yy) handleError("yy", "Can`t be blank");

        if (document.querySelectorAll('.input--error').length === 0) animateSlider(true);
    }

    const resetForm = () => {
        setFormData({ name: null, number: null, mm: null, yy: null, cvc: null });
        animateSlider(false);
    }


    //Currency
    const festivalCurrency = (event) => {
        const { value } = event.target
        setCurrencyId(value)
    }

    const hendaleCurrency = (e) => {
        e.preventDefault();
        setType('3')
    }
    //End Currency

    const festivalCommission = (event) => {
        // let interval = setTimeout(() => {
        const { value } = event.target

        setCommission(value)
        // }, 1500);
        // return () => clearTimeout(interval);
    }

    const hendaleCommission = (e) => {
        e.preventDefault();
        setType('2')
    }

    const festivalCreditCard = (event) => {
        const { value } = event.target
        setType('1')
        setCreditCard(value)
    }

    const cardDelete = () => {
        setCardDelete(true);
    }


    const [BankFormData, setBankFormData] = useState({ festival_id: id, bank_account_name: null, bank_account_number: null });
    const [BankformErrors, setBankFormErrors] = useState({});
    const [BankValidate, setBankValidate] = useState(false);

    const BankSubmit = (e) => {
        e.preventDefault();
        setBankFormErrors(validateBank(BankFormData));
        const error = validateBank(BankFormData);
        if (Object.keys(error).length === 0) {
            setBankValidate(true);
        }

    }

    const handleChangeBank = (e) => {
        const { name, value } = e.target
        setBankFormData({ ...BankFormData, [name]: value });
    }

    const validateBank = (values) => {
        const errors = {};

        if (!values.bank_account_name) {
            // errors.bank_account_name = "Account name is required!";
        }

        if (!values.bank_account_number) {
            // errors.bank_account_number = "Account Number is required!";
        } else if (values.bank_account_number.match(/[^0-9\s]/g)) {
            errors.bank_account_number = "Wrong format, numbers only";
        }

        return errors;
    };

    useEffect(() => {
        const currency_list = async () => {
            await axios.get('commonapi/currency_master')
                .then((result) => {
                    let getData = result.data.data
                    setCurrency(getData)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        currency_list()


        const festival_details = async () => {
            let apidata = {}
            apidata.festival_id = id
            await axios.post('festival/festivalDetails', apidata)
                .then((result) => {
                    let getData = result.data.data
                    setfestivalDetail(getData);
                }).catch((error) => {
                    console.log(error);
                })
        }
        festival_details()

        if (Type !== '') {
            let apidata = {};
            if (Type == '3') {
                apidata.festival_id = id
                apidata.type = '3'
                apidata.festival_currency = CurrencyId
            } else if (Type == '2') {
                apidata.festival_id = id
                apidata.type = '2'
                apidata.festival_commission = Commission
            } else if (Type == '1') {
                apidata.festival_id = id
                apidata.type = '1'
                apidata.festival_credit_card = CreditCard
            }
            const festivalUpdate = async () => {
                await axios.post('festival/festivalSetting', apidata)
                    .then((result) => {
                        setType('')
                        setCurrencyId('')
                        let message = result.data.message
                        setMessage(message)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            festivalUpdate();

            festival_details();
        }

        const customer_card_get = async () => {
            let apidata = {
                user_id: session_user_data.id,
                festival_id: id
            }
            await axios.post('stripe/GetCard', apidata)
                .then((result) => {
                    if (result.data.status != false) {
                        let data = result.data.data
                        setCardData(data.last4)
                    } else {
                        setCardData('')
                    }
                    // setMessage(message)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        customer_card_get();


        if (CardDelete) {
            const customer_card_delete = async () => {
                let apidata = {
                    user_id: session_user_data.id,
                    festival_id: id
                }
                await axios.post('stripe/DeleteCard', apidata)
                    .then((result) => {
                        let message = result.message
                        setMessage(message)
                        setCardDelete(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            customer_card_delete();
            customer_card_get();
        }


        if (validate) {
            const customer_card_create = async () => {
                let apidata = {
                    user_id: session_user_data.id,
                    festival_id: id,
                    number: formData.number,
                    exp_month: formData.mm,
                    exp_year: formData.yy,
                    cvc: formData.cvc,
                    name: formData.name,
                }
                await axios.post('stripe/CreateCard', apidata)
                    .then((result) => {
                        let message = result.data.message
                        setMessage(message)
                        setValidate(false);
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            customer_card_create();
            customer_card_get();
        }


        if (BankValidate) {
            // console.log(festivalDetail);
            // console.log(BankFormData);
            const bank_update_details = async () => {
                await axios.post('stripe/UpdateBankDetails', BankFormData)
                    .then((result) => {
                        let message = result.data.message
                        setMessage(message)

                        setBankValidate(false);
                    }).catch((error) => {
                        console.log(error);
                    })
            }
            bank_update_details();
            festival_details()
        }


        const user_details = async () => {
            let apiuser = {
                user_id: session_user_data.id,
                standId: 0,
                type: 0,
                language_id: session_user_data.language_id,
            }
            await axios.post('users/view-profile', apiuser)
                .then((result) => {
                    // console.log('result', result.data.data)
                    setUserDetails(result.data.data)
                    // let message = result.data.message
                    // setMessage(message)

                    // setBankValidate(false);
                }).catch((error) => {
                    console.log(error);
                })
        }
        user_details();


        const timer = setTimeout(() => {
            setMessage('')
        }, 3000);
        return () => clearTimeout(timer);

    }, [Type, validate, CardDelete, BankValidate])
    return (
        <>
            <Header />
            <div>Settings</div>

            <section className="c-dashboard-section">
                <LoginHeader Stand={false} standData={[]} />
                <h4><div className='success_text' style={{ textAlign: "center" }}>{Message}</div></h4>
                <div className="container-fluid setting-page">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="setting-box">
                                <h2>CREDIT CARD</h2>
                                {(() => {
                                    if (CardData != '') {
                                        return (
                                            <div className='row'>
                                                <div className='col-md-7'>
                                                    **** **** **** {CardData}
                                                </div>
                                                <div className='col-md-5'>
                                                    <a onClick={() => {
                                                        const confirmBox = window.confirm(
                                                            "Do you really want to delete this Card?"
                                                        )
                                                        if (confirmBox === true) {
                                                            cardDelete()
                                                        }
                                                    }}

                                                        style={{ cursor: 'pointer' }}>
                                                        <i className='fa fa-trash'></i>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <form className='cardForm' onSubmit={handleSubmit}>
                                                <label className='labelname'>
                                                    Cardholder Name
                                                    <input type="text" placeholder="e.g. Jane Appleseed" onChange={handleInput} name="name" className='card-input form-control' />
                                                </label>
                                                <p className='error_text info info--hidden' aria-live="polite"></p>

                                                <label className='labelnumber'>
                                                    Card Number
                                                    <input type="text"
                                                        placeholder="e.g. 1234 5678 9123 0000"
                                                        onChange={handleInput}
                                                        name="number"
                                                        className='card-input form-control'
                                                        minLength={19}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                </label>
                                                <p className='error_text info info--hidden' aria-live="polite"></p>

                                                <div className='cvc-mmyy'>
                                                    <label className='labelmm labelyy'>
                                                        Exp. Date (MM/YY)
                                                        <div>
                                                            <input type="text" placeholder='MM' onChange={handleInput} name="mm" className='card-input form-control' />
                                                            <input type="text" placeholder='YY' onChange={handleInput} name="yy" className='card-input form-control' />
                                                        </div>
                                                    </label>
                                                    <p className='error_text info info--hidden' aria-live="polite"></p>

                                                    <label className='labelcvc'>
                                                        CVC
                                                        <input type="text" placeholder='e.g. 123' onChange={handleInput} name="cvc" className='card-input form-control' />
                                                    </label>
                                                    <p className='error_text info info--hidden' aria-live="polite"></p>
                                                </div>

                                                {/* <div className='col-md-12'> */}
                                                <div className="custome">
                                                    {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                                    <button className="btn-submit btn-primary btn" style={{ marginTop: '5px' }} type="submit" >
                                                        <span>Submit</span>
                                                    </button>
                                                </div>
                                                {/* </div> */}
                                                {/* <button type='submit' className='btn-submit btn-primary'>Confirm</button> */}
                                            </form>
                                        )
                                    }
                                })()}


                                <div className='row' style={{ fontSize: '15px', marginTop: '15px' }}>
                                    <h2>Bank Account Details</h2>

                                    <form onSubmit={BankSubmit}>
                                        <div className='col-md-12'>
                                            {festivalDetail.bank_account_number != null
                                                ? <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Account Number"
                                                    name="bank_account_number"
                                                    id="bank_account_number"
                                                    onChange={handleChangeBank}
                                                    defaultValue={festivalDetail.bank_account_number}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} />
                                                : <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Account Number"
                                                    name="bank_account_number"
                                                    id="bank_account_number"
                                                    onChange={handleChangeBank}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} />}

                                            {BankformErrors.bank_account_number != null &&
                                                <div className='error_text'>{BankformErrors.bank_account_number}</div>
                                            }
                                        </div>

                                        <div className='col-md-12' style={{ marginTop: '15px' }}>
                                            {festivalDetail.bank_account_name != null
                                                ? <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Account Name"
                                                    name="bank_account_name"
                                                    id="bank_account_name"
                                                    onChange={handleChangeBank}
                                                    defaultValue={festivalDetail.bank_account_name} />
                                                : <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Account Name"
                                                    name="bank_account_name"
                                                    id="bank_account_name"
                                                    onChange={handleChangeBank} />
                                            }
                                            {BankformErrors.bank_account_name != null &&
                                                <div className='error_text'>{BankformErrors.bank_account_name}</div>
                                            }
                                        </div>

                                        <div className='col-md-12'>
                                            <div className="custome">
                                                {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                                <button className="btn" style={{ marginTop: '15px' }} type="submit" >
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="setting-box">
                                <h2>SET GENERAL COMMISSION IN %</h2>

                                <div className='row' style={{ fontSize: '15px' }}>
                                    <form onSubmit={hendaleCommission}>
                                        <div className='col-md-12'>

                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="SET GENERAL COMMISSION IN %"
                                                name="festival_commission"
                                                id="festival_commission"
                                                onChange={festivalCommission}
                                                value={(Commission) ? Commission : festivalDetail.festival_commission}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} />
                                        </div>

                                        <div className='col-md-12'>
                                            <div className="custome">
                                                {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                                <button className="btn" style={{ marginTop: '15px' }} type="submit" >
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="setting-box">
                                <h2>CURRENCY SELECTION</h2>
                                <form onSubmit={hendaleCurrency}>
                                    <select
                                        name='currency'
                                        id="currency"
                                        className="form-control"
                                        onChange={festivalCurrency}
                                    >
                                        <option value=''>Select Currency</option>
                                        {Currency.length > 0 &&
                                            Currency.map((data, index) => (
                                                <option key={index} value={data.currency_code} selected={festivalDetail.festival_currency == data.currency_code}>{data.currency_name}</option>
                                            ))
                                        }
                                    </select>

                                    <div className='col-md-12'>
                                        <div className="custome">
                                            {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                            <button className="btn" style={{ marginTop: '15px' }} type="submit" >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="setting-box">
                                <h2>ADMIN INFORMATION</h2>
                                <div className='row' style={{ fontSize: '15px' }}>

                                    <div className='col-md-12'>
                                        <label>Name:-<span>{UserDetails?.first_name + ' ' + UserDetails?.people_name}</span></label>
                                    </div>

                                    {/* <div className='col-md-12'>
                                        <label>Last Name:-<span>{session_user_data.people_name}</span></label>
                                    </div> */}

                                    <div className='col-md-12'>
                                        <label>Email:-<span>{UserDetails?.email}</span></label>
                                    </div>

                                    <div className='col-md-12'>
                                        <label>Phone Number:-<span>{UserDetails?.phonecode} {UserDetails?.phone}</span></label>
                                    </div>

                                    <div className='col-md-12 c-profile-image'>
                                        <label>
                                            Profile Picture:-
                                            {UserDetails?.photo == '' &&
                                                <img src="/asset/images/profile.png" alt="profile.png" style={{ width: '20%', borderRadius: '59px' }} />
                                            }
                                            {UserDetails.photo != '' &&
                                                <img src={`${UserDetails?.photo?.replace('public/', '')}`} style={{ width: '20%', borderRadius: '59px' }} />
                                            }
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <Footer />
            <Outlet />
        </>
    );
}

export default Settings
