import React, { useState, useEffect } from 'react'
import axios from '../axios';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom'

import Header from '../component/header'
import Footer from '../component/footer'

import DatePicker from "react-multi-date-picker"
import PhoneInput from 'react-phone-input-2';

const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
const imagesize = 2;

function Profile() {
    const token = localStorage.getItem('token');
    if (token === null) {
        window.location.href = "/";
    }
    const session_user_data = JSON.parse(localStorage.getItem('user_data'));


    const initialValuesProfile = { first_name: session_user_data.first_name, last_name: session_user_data.people_name, email: session_user_data.email, birth_date: session_user_data.birth_date, gender: session_user_data.gender, language_id: '0', currency_id: "0", phonecode: session_user_data.phonecode, phone: session_user_data.phone, 'profile_pic': null };
    const [formValues, setFormValues] = useState(initialValuesProfile);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [message, setMessage] = useState('');
    console.log(message);
    const [CountryGet, setCountryGet] = useState([{}]);

    const [formImageErrors, setFormImageErrors] = useState({});

    const [value, setValue] = useState()
    const [PreviewImage, setPreviewImage] = useState("")
    // const [UserDetails, setUserDetails] = useState({})

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log({ [name]: value })
        setFormValues({ ...formValues, [name]: value });
    };

    const handleOnChange = (value, data, event, formattedValue) => {
        setFormValues({ ...formValues, ['phonecode']: '+ ' + data.dialCode, 'phone': value.slice(data.dialCode.length) });
    }

    const handleFile = (e) => {
        const { name, files } = e.target;
        if (!files[0].type.match(imageMimeType)) {
            const errors = {};
            errors.profile_pic = "This file format is not supported";
            setFormImageErrors(errors);
        } else if ((files[0].size / 1024 / 1024) > imagesize) {
            const errors = {};
            errors.profile_pic = "Max upload file size is 2048";
            setFormImageErrors(errors);
        } else {
            setFormValues({ ...formValues, 'profile_pic': files[0] });
            setPreviewImage(URL.createObjectURL(e.target.files[0]))
            const errors = {};
            setFormImageErrors(errors);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.first_name) {
            errors.first_name = "First name is required!";
        }

        if (!values.last_name) {
            errors.last_name = "Last Name is required!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        if (!values.phonecode) {
            errors.phonecode = "Phone code is required!";
        }

        if (!values.phone) {
            errors.phone = "Phone number is required!";
        }


        return errors;
    };

    useEffect(() => {
        const CountryGet = async () => {
            await axios.get('commonapi/CountryGet')
                .then((result) => (
                    setCountryGet(result.data.data)
                )).catch((error) => {
                    console.log(error)
                })
        }
        CountryGet()

        if (Object.keys(formImageErrors).length === 0 && Object.keys(formErrors).length === 0 && isSubmit) {
            // console.log(isSubmit);
            const formsubmit = async () => {
                let new_form = new FormData();
                new_form.append('userId', session_user_data.id)
                new_form.append('first_name', formValues.first_name)
                new_form.append('last_name', formValues.last_name)
                new_form.append('email', formValues.email)
                new_form.append('phonecode', formValues.phonecode)
                new_form.append('phone', formValues.phone)
                new_form.append('birth_date', formValues.birth_date)
                new_form.append('gender', formValues.gender)
                new_form.append('language_id', formValues.language_id)
                new_form.append('currency_id', formValues.currency_id)
                new_form.append('profile-pic', formValues.profile_pic)
                await axios.post('users/edit-web_update', new_form)
                    .then((result) => {
                        session_user_data.first_name = formValues.first_name
                        session_user_data.people_name = formValues.last_name
                        session_user_data.email = formValues.email
                        session_user_data.phonecode = formValues.phonecode
                        session_user_data.phone = formValues.phone
                        session_user_data.birth_date = formValues.birth_date
                        session_user_data.gender = formValues.gender
                        session_user_data.language_id = formValues.language_id
                        session_user_data.currency_id = formValues.currency_id
                        session_user_data.photo = result.data.data.photo
                        localStorage.setItem('user_data', JSON.stringify(session_user_data))
                        setIsSubmit(false)
                        console.log(result)
                        // setMessage(result.data)
                        const message = result.data.message;
                        setMessage(message)

                        if (result.data.status !== false) {
                            // setIsSubmitAddFestival(resultget.data)  
                        }
                    }).catch((error) => {
                        const message = error.response.data.message;
                        alert(message)

                    })
                const timer = setTimeout(() => {
                    setMessage('')
                    window.location.reload()
                }, 10000);
                setIsSubmit(false)
                return () => clearTimeout(timer);
            }
            formsubmit()
        } else {

            const user_details = async () => {
                let apiuser = {
                    user_id: session_user_data.id,
                    standId: 0,
                    type: 0,
                    language_id: session_user_data.language_id,
                }
                await axios.post('users/view-profile', apiuser)
                    .then((result) => {
                        // console.log('result', result.data.data)
                        // setUserDetails(result.data.data)

                        let userDetails = result.data.data
                        const initialValuesProfile = {
                            first_name: userDetails.first_name,
                            last_name: userDetails.people_name,
                            email: userDetails.email,
                            birth_date: userDetails.birth_date,
                            gender: userDetails.gender,
                            language_id:userDetails.language_id,
                            currency_id: userDetails.currency_id,
                            phonecode: userDetails.phonecode,
                            phone: userDetails.phone,
                            profile_pic: userDetails.photo
                        };
                        setFormValues(initialValuesProfile)
                        // let message = result.data.message
                        // setMessage(message)

                        // setBankValidate(false);
                    }).catch((error) => {
                        console.log(error);
                    })
            }
            user_details();
        }
    }, [formErrors]);

    return (
        <>
            <div className="c-wrapper">
                <Header />
                <section className="c-dashboard-section profile_page">

                    <div className="tab-content">
                        <div className='container-fluid'>
                            {message != '' &&
                                <div className='success_text'>{message}</div>
                            }
                            <h1>Edit Profile Page</h1>

                            <div className="c-festival-create-form">
                                <form onSubmit={handleSubmit}>
                                    <div className='row mb-2'>

                                        <div className='col-md-6'>

                                            <label>First Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter First Name"
                                                name="first_name"
                                                id="first_name"
                                                maxLength="50"
                                                defaultValue={session_user_data.first_name}
                                                onChange={handleChange}
                                                value={formValues.first_name}
                                            />
                                            {formErrors.first_name != null &&
                                                <div className='error_text'>{formErrors.first_name}</div>
                                            }
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Last Name"
                                                name="last_name"
                                                id="last_name"
                                                maxLength="50"
                                                defaultValue={session_user_data.people_name}
                                                onChange={handleChange}
                                                value={formValues.last_name}
                                            />
                                            {formErrors.last_name != null &&
                                                <div className='error_text'>{formErrors.last_name}</div>
                                            }
                                        </div>
                                    </div>

                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter Email"
                                                name="email"
                                                id="email"
                                                disabled
                                                defaultValue={session_user_data.email}
                                                onChange={handleChange}
                                                value={formValues.email}
                                            // kristal31@gmail.com
                                            />
                                        </div>

                                        <div className='col-md-6'>
                                            <label>Phone Number</label>
                                            <div className='c-form-field c-phone-field'>
                                                <PhoneInput
                                                    country='ch'
                                                    enableSearch='true'
                                                    onChange={handleOnChange}
                                                    placeholder="Enter Phone Number"
                                                    disabled
                                                    value={session_user_data.phonecode + session_user_data.phone}
                                                />
                                                {formErrors.phonecode != null &&
                                                    <div className='error_text'>{formErrors.phonecode}</div>
                                                }
                                                {formErrors.phone != null &&
                                                    <div className='error_text'>{formErrors.phone}</div>
                                                }
                                                {/* <select
                                                    name='phonecode'
                                                    id="phonecode"
                                                    disabled="disabled"
                                                >
                                                    {CountryGet.length > 0 &&
                                                        CountryGet.map((res, index) => (
                                                            <option value={`+${res.phone_code}`} selected={session_user_data.phonecode == res.phone_code}>
                                                                {`+${res.phone_code}`}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter Phone number"
                                                    name="phone_number"
                                                    id="phone_number"
                                                    disabled="disabled"
                                                    defaultValue={session_user_data.phone} /> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <label>Profile Image</label>
                                                <div className='col-md-10'>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder="Enter Phone number"
                                                        name="profile_pic"
                                                        id="profile_pic"
                                                        onChange={handleFile} />
                                                    {formImageErrors.profile_pic != null &&
                                                        <div className='error_text'>{formImageErrors.profile_pic}</div>
                                                    }
                                                </div>
                                                <div className='col-md-2'>

                                                    <img src={PreviewImage ? PreviewImage : session_user_data.photo == '' ? '/asset/images/default_image.png' : `https://api.cph4.ch/${session_user_data.photo.replace('public/', '')}`} alt="profile.png" className="profile_img" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-md-6'>
                                            <label>Gender</label>
                                            <select
                                                className="form-control"
                                                name='gender'
                                                id='gender'
                                                defaultValue={session_user_data.gender}
                                                onChange={handleChange}
                                            >
                                                <option value=''>Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <label>Date Of Birth</label>
                                            {/* <input
                                            type="date"
                                            className="form-control"
                                            placeholder="Enter Phone number"
                                            name="phone_number"
                                            id="phone_number" /> */}

                                            <DatePicker
                                                placeholder='Enter Birth Date'
                                                value={formValues.birth_date}
                                                maxDate={new Date()}
                                                onChange={(date) => setFormValues({ ...formValues, ['birth_date']: date })}
                                            />
                                            {/* <DatePicker
                                                render={<CustomInput defaultValue={session_user_data.birth_date} value={formValues.birth_date} onChange={handleChange} />}
                                            /> */}
                                            {formErrors.birth_date != null &&
                                                <div className='error_text'>{formErrors.birth_date}</div>
                                            }
                                        </div>
                                    </div>

                                    <center>
                                        <div className="col-md-3">
                                            <div className="custome">
                                                <button className="btn-submit btn-primary btn" type="submit" >
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </center>
                                </form>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
            <Footer />

        </>
    )
}

export default Profile