import React, { useState, useRef } from 'react';
import { jsPDF } from "jspdf";
import axios from '../axios';
import { Link } from 'react-router-dom';

const StandAccordion = ({ content, id, onChange }) => {
    const first=  content
    console.log(first)
    const ChangeStandUserStatus = (e, id) => {
        const StandUserStatus = async () => {
            let apidata = {
                lottery_id: id
            }
            await axios.post('lottery/total_lottery_bid', apidata)
                .then((result) => {
                    if (result.data.status == true) {
                        const message = result.data.message;
                        window.location.reload()
                    }
                    // setErrorMessage('')
                }).catch((error) => {
                    // console.log(error);
                    // setErrorMessage(error.response.data.message)
                })
        };
        StandUserStatus();
    }

    const StandUserDelete = (id) => {
        const StandUserdelete = async () => {
            let apidata = {
                id: id
            }
            await axios.post('stand/StandUserDelete', apidata)
                .then((result) => {
                    if (result.data.status == true) {
                        // const message = result.data.message;
                        window.location.reload()
                    }
                }).catch((error) => {
                    // console.log(error);
                })
        };
        StandUserdelete();
    }
    return (
        <>
            <div className="accordion-item">
                <div className="acc-tabs">
                    <span className="row acc-tabs-heading">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                            {id}
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-2 col-sm-2 col-11">
                            <div className='stand_content_image'>
                                {/* className='stand_content' */}
                                <div>
                                    {/* <span>{first}</span> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-4 text-center col-12">
                            <span>
                               Bid Amount: {content.bid_amount}
                            </span>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center col-12">
                            <span>
                                {/* {content.type == 1 ? 'Seller Admin' : 'Seller Employee'} */}
                            </span>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12">
                            {/* <span className="festival_delete">
                                <h3
                                    onClick={() => {
                                        const confirmBox = window.confirm(
                                            "Do you really want to delete this User?"
                                        )
                                        if (confirmBox === true) {
                                            StandUserDelete(content.id)
                                        }
                                    }}><i className='fa fa-trash'></i></h3>
                            </span> */}
                        </div>
                    </span>
                   
                </div>
            </div >
        </>

    );
};

export default StandAccordion;