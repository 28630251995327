import React, { useState, useRef } from 'react';
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";

const Accordion = ({ content, active, onToggle, name, onClick, onChange }) => {
    const Accname = name;

    const contentEl = useRef();
    return (
        <>
            <div className="accordion-item">
                <div className="acc-tabs" >
                    <span className="row acc-tabs-heading">
                        {/* onClick={onToggle} */}
                        <div className="col-xl-4 col-lg-2 col-md-3 col-sm-1 col-10">
                            <Link className="download_text" to={`/${content.id}/dashboard`}>{content.name}</Link>
                            {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                            <Link className="download_text" to={`/${content.id}/dashboard`}>
                                <button className="btn cutome_festival_open" type="submit" >
                                    <span>Open Festival</span>
                                </button>
                            </Link>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-4 text-center col-12">
                            <a href={content.qrcod_image} download={`${content.name}.png`} className="download_text" >DOWNLOAD QR CODE FESTIVAL</a>  <img className="qr_code_image" src={content.qrcod_image} />
                        </div>

                        <div className="col-xl-2 col-lg-4 col-md-5 col-sm-5 col-12">
                            <span className='stand_status'>{content.status}</span>
                            <span className="form-check form-switch">
                                <input className={`form-check-input ${content.status == 'active' ? '' : 'myinactive'}`} checked={content.status == 'active' ? 'checked' : ''} onChange={onChange} type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" for="flexSwitchCheckDefault"></label>
                            </span>

                            <span className="festival_delete">
                                <h3 onClick={onClick}><i className='fa fa-trash'></i></h3>
                            </span>
                        </div>
                    </span>
                    {/* {active === true && <h2 onClick={onToggle}> <i className='fa fa-chevron-up'></i></h2>}
                    {active === false && <h2 onClick={onToggle}> <i className='fa fa-chevron-down'></i></h2>} */}



                    {active &&
                        <div className="tabShowHide accordion_tab_content"
                            ref={contentEl}>
                            {/* <table className="stand-tbl">
                                <tbody>
                                    <tr>
                                        <td width="10%">TOTAL SALE <strong>700 </strong></td>
                                        <td width="10%">NUMBER OF BUYER <strong>700</strong></td>
                                        <td width="10%">AVERAGE BASKET <strong>700</strong></td>
                                        <td width="10%">SALES AMOUNT <strong>700</strong></td>
                                        <td width="15%">WITHDRAW STATUS <strong className="yellow-text">PENDING</strong></td>
                                        <td width="15%">PASSWORD FOR SALER ADMIN<strong className="yellow-text">PALEO2022</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" height="40"></td>
                                    </tr>
                                    <tr>
                                        <td width="10%">SET INDIVIDUAL COMMISSION
                                            <strong>2%</strong></td>
                                        <td colspan="4"></td>
                                        <td width="10%">PASSWORD FOR SALER EMPLOYEE<strong className="yellow-text">PALEO2029</strong></td>
                                    </tr>
                                </tbody>

                            </table> */}
                        </div>
                    }
                </div>
            </div >
        </>

    );
};

export default Accordion;