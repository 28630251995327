import React, { useEffect, useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios'

import { BrowserRouter as Router, Route, Link, Routes, useLocation, useParams } from "react-router-dom";


function LoginHeader({ Stand, standData }) {

    console.log('Stand---->', Stand)

    const location = useLocation();
    let { id } = useParams()
    const sessiontoken = localStorage.getItem('token');
    const sessionuser = JSON.parse(localStorage.getItem('user_data'));

    // console.log(sessionuser.festival_role);

    if (sessiontoken === null) {
        window.location.href = "/";
    }
    const [festivalDetail, setfestivalDetail] = useState({});

    const DownloadQr = (e) => {
        for (var i = 0; i < standData.length; i++) {
            let url = standData[i].qr_image;
            let a = document.createElement('a');
            a.href = url;
            a.download = standData[i].name;
            a.click();
        }

    }

    useEffect(() => {
        const festival_details = async () => {
            let apidata = {}
            apidata.festival_id = id
            await axios.post('festival/festivalDetails', apidata)
                .then((result) => {
                    let getData = result.data.data
                    setfestivalDetail(getData);
                }).catch((error) => {
                    console.log(error);
                })
        }
        festival_details()
    }, [])

    return (
        <>
            <div className="container-fluid">
                <h2 className="c-section-title">{location.pathname === `/${id}/dashboard` && <span className="purple-text">{festivalDetail.name}</span>} {(location.pathname === "/festival" ? 'Festival' : '')} {(location.pathname === `/${id}/dashboard` ? 'Dashboard' : '')} {(location.pathname === `/${id}/stand` ? 'Stand' : '')} {(location.pathname === `/${id}/settings` ? 'Settings' : '')} {(location.pathname === `/${id}/team-management` ? 'Team management' : '')}</h2>

                {Stand &&
                    <div className="stand-right-link">
                        <a href="javascript:;" onClick={DownloadQr}>Download all QR Code</a>
                        <a href="javascript:;" className="create-stand-link" onClick={(e) => {
                            e.preventDefault();
                            window.$('#CreateStand').modal('show');
                        }}> <span>+</span> CREATE A STAND</a>
                    </div>
                }

            </div>
            <div>
                <div className="container-fluid">
                    <div className="tab-container-one">
                        <ul className="nav nav-tabs">
                            <li className={"nav-item " + (location.pathname === "/festival" ? 'active' : '')}>
                                <Link className={"nav-link " + (location.pathname === "/festival" ? 'active' : '')} to="/festival">My Festival</Link>
                            </li>

                            {sessionuser.festival_role != '5' &&
                                <li className={"nav-item " + (location.pathname === `/${id}/dashboard` ? 'active' : '')}>
                                    <Link className={"nav-link " + (location.pathname === `/${id}/dashboard` ? 'active' : '')} to={`/${id}/dashboard`}>Dashboard</Link>
                                </li>
                            }

                            <li className={"nav-item " + (location.pathname === `/${id}/stand` ? 'active' : '')}>
                                <Link className={"nav-link " + (location.pathname === `/${id}/stand` ? 'active' : '')} to={`/${id}/stand`}>Stand</Link>
                            </li>
                            {sessionuser.festival_role != '5' &&
                                <li className={"nav-item " + (location.pathname === `/${id}/settings` ? 'active' : '')}>
                                    <Link className={"nav-link " + (location.pathname === `/${id}/settings` ? 'active' : '')} to={`/${id}/settings`}>Settings</Link>
                                </li>
                            }

                            {sessionuser.festival_role != '5' &&
                                <li className={"nav-item " + (location.pathname === `/${id}/team-management` ? 'active' : '')}>
                                    <Link className={"nav-link " + (location.pathname === `/${id}/team-management` ? 'active' : '')} to={`/${id}/team-management`}>Team management</Link>
                                </li>
                            }
                            {/* <li className="nav-item"><a className="nav-link" href="#stand" role="tab" aria-controls="stand" data-bs-toggle="tab">Stand</a></li>
                            <li className="nav-item"><a className="nav-link" href="#settings" role="tab" aria-controls="settings" data-bs-toggle="tab">Settings</a></li>
                            <li className="nav-item"><a className="nav-link" href="#team-management" role="tab" aria-controls="team-management" data-bs-toggle="tab">Team management</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginHeader
