import React, { useEffect, useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios';

import { Routes, Route, Outlet, Link, useLocation, useParams } from "react-router-dom";
import LoginHeader from '../component/loginHeader';
import Product_token from '../component/Product_token';

import DatePicker from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

function FesivalList() {
    const location = useLocation();
    let { id } = useParams()
    const ticket_id = id;
    const sessiontoken = localStorage.getItem('token');
    if (sessiontoken === null) {
        window.location.href = "/";
    }
    const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
    const imagesize = 2;

    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const initialValuesAddProduct = { product_name: "", file: "", about_ticket: "" };
    const initialValuesAddFestival = { festival_name: "", festival_position: user_data.festival_role, festival_count: "50", start_date: new Date(), end_date: new Date((new Date()).valueOf() + 1000 * 3600 * 24), first_name: user_data.first_name, last_name: user_data.people_name, email: user_data.email, phone_number: user_data.phone };
    const [formValuesAddFestival, setFormValuesAddFestival] = useState(initialValuesAddFestival);
    const [formValuesAddProduct, setFormValuesAddProduct] = useState(initialValuesAddProduct);
    // const [formErrorsAddFestival, setFormErrorsAddFestival] = useState({});
    const [isSubmitAddFestival, setIsSubmitAddFestival] = useState(false);

    const [formErrorsAddProduct, setFormErrorsAddFestival] = useState({});
    const [isLoading, setisLoading] = useState(true);

    const [Message, setMessage] = useState("");
    const [inputList, setInputList] = useState([{ name: '', content: '', checkbox_status: '1' }]);
    // console.log(inputList)
    // handle input change
    // const handleInputChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const list = [...inputList];
    //     list[index][name] = value;
    //     setInputList(list);
    // };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { name: '', content: '', checkbox_status: '1' }]);
    };

    const [FestivalData, setFestivalData] = useState({});


    const [clicked, setClicked] = useState("0");

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    //Add Festival form
    const handleChangeAddFestival = (e) => {
        const { name, value } = e.target;
        setFormValuesAddFestival({ ...formValuesAddFestival, [name]: value });
    };

    // const handleSubmitAddFestival = (e) => {
    //     e.preventDefault();
    //     setFormErrorsAddFestival(validateAddFestival(formValuesAddFestival));
    //     setIsSubmitAddFestival(true);
    // };
    const handleSubmitAddToken = (e) => {
        e.preventDefault();
        setFormErrorsAddFestival(validateAddFestival(formValuesAddProduct));
        setIsSubmitAddFestival(true);
    };

    const handleInputChange = (e, index) => {
        if (e.target.files != undefined) {
            const { name, files } = e.target;
            if (!files[0].type.match(imageMimeType)) {
                const errors = {};
                errors.file = "This file format is not supported";
                // setFormErrorsAddFestival(errors);
                setFormValuesAddProduct({ ...formValuesAddProduct, [name]: "" });
            } else if ((files[0].size / 1024 / 1024) > imagesize) {
                const errors = {};
                errors.file = "Max upload file size is 2048";
                // setFormErrorsAddFestival(errors);
                setFormValuesAddProduct({ ...formValuesAddProduct, [name]: "" });
            } else {
                setFormValuesAddProduct({ ...formValuesAddProduct, [name]: files[0] });
                const errors = {};
                errors.file = "";
                // setFormErrors(errors);
            }
        }
        else {
            const { name, value } = e.target;
            setFormValuesAddProduct({ ...formValuesAddProduct, [name]: value });
            // setFormValuesAddProductCheck({ ...formValuesAddProductCheck, [name]: value });
            const list = [...inputList];
            list[index][name] = value;
            setInputList(list);
        }
    };


    // Add Festival form
    const validateAddFestival = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.festival_name) {
            errors.festival_name = "Festival name is required!";
        }

        if (!values.festival_position) {
            errors.festival_position = "Position Of Festival is required!";
        }

        if (!values.festival_count) {
            errors.festival_count = "Festival goer is required!";
        }

        if (moment(new Date()).format("YYYY/MM/DD") > moment(values.start_date).format("YYYY/MM/DD")) {
            errors.start_date = "Start Date not allow";
        }

        if (moment(new Date()).format("YYYY/MM/DD") > moment(values.end_date).format("YYYY/MM/DD")) {
            errors.end_date = "End Date not allow";
        }

        if (!values.start_date) {
            errors.start_date = "Start Date is required!";
        }

        if (!values.end_date) {
            errors.end_date = "End Date is required!";
        }


        if (values.start_date > values.end_date) {
            errors.date = "Please enter end date greater then start date";
        }

        return errors;
    };

    const FestivalDelete = (index) => {
        const deletefestival = async () => {
            let apidata = {}
            apidata.festival_id = index
            await axios.post('festival/festival_delete', apidata)
                .then((result) => {
                    // console.log(result.data.message)
                    window.location.reload();
                    // setMessage(result.data.message)
                }).catch((error) => {
                    console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        deletefestival();
    }

    //Status Change event
    const [FestivalId, SetFestivalId] = useState('');
    const StatusChange = (id) => {
        SetFestivalId(id);
    }


    useEffect(() => {

        const FestivalList = async () => {
            // let apidata = {}
            // if (user_data.parent_user_id != null) {
            //     apidata.member_id = user_data.parent_user_id
            // } else {
            //     apidata.member_id = user_data.id
            // }
            let apidata = {}
            apidata.id = id

            await axios.post('service/detailproduct', apidata)
                .then((result) => {

                    let getData = result.data.data
                    setFestivalData(getData);
                    setMessage('')
                    setisLoading(false)

                }).catch((error) => {
                    console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        FestivalList();

        // if (Object.keys(formErrorsAddFestival).length === 0 && isSubmitAddFestival) {
        //     const Festivalcreate = async () => {
        //         formValuesAddFestival.user_id = user_data.id
        //         await axios.post('festival/memberFestivalCreate', formValuesAddFestival)
        //             .then((result) => {
        //                 setMessage(result.data.message)
        //                 window.$('#Createfestival').modal('hide');
        //                 window.location.reload();
        //             }).catch((error) => {
        //                 console.log(error.response.data.message);
        //                 alert(error.response.data.message);
        //                 // setloggedinError(error.response.data);
        //             })
        //     };
        //     Festivalcreate();
        // }

        if (FestivalId != '') {
            const FestivalStatus = async () => {
                let product_id = {
                    "product_id": FestivalId
                }
                await axios.post('service/service_product_change_status', product_id)
                    .then((result) => {
                        setMessage(result.data.message);
                        FestivalList();
                        SetFestivalId('');
                        // window.location.reload()
                    }).catch((error) => {
                        console.log(error);
                        // setloggedinError(error.response.data);
                    })
            }
            FestivalStatus();
        }

        if (Object.keys(formErrorsAddProduct).length === 0 && isSubmitAddFestival) {
            // console.log(inputList)
            const productcreate = async () => {
                let ProductData = new FormData();
                ProductData.append("product_name", formValuesAddProduct.product_name)
                ProductData.append("file", formValuesAddProduct.file)
                ProductData.append("service_id", ticket_id)
                ProductData.append("about_ticket", formValuesAddProduct.about_ticket)

                for (var i = 0; i < inputList.length; i++) {
                    ProductData.append("attribute_detail[]", JSON.stringify(inputList[i]))
                    // console.log(inputList[i])
                }
                // console.log(ProductData)
                await axios.post('service/addProduct', ProductData)
                    .then((result) => {
                        console.log(result)
                        // setMessage(result.data.message)
                        // window.$('#Createfestival').modal('hide');
                        window.location.reload();
                    }).catch((error) => {
                        console.log(error.response.data.message);
                        alert(error.response.data.message);
                        // setloggedinError(error.response.data);
                    })
            };
            productcreate();
        }



    }, [FestivalId, formErrorsAddProduct]);

    return (
        // this.state.isLoading ? *showLoadingScreen* 
        <>
            <Header />

            <div>Product</div>


            <section className="c-dashboard-section">
                {/* {location.pathname != "/festival" &&
                    <LoginHeader Stand={false} standData={[]} />
                } */}

                <div className="tab-content">

                    <div className="container-fluid">
                        <h2 className="c-section-title">Product</h2>
                        <Link to="/tokenization">Back</Link>
                        {/* <a href="#" onClick={(e) => {
                            e.preventDefault();
                            window.$('#Createfestival').modal('show');
                        }} className="create-festival-link"> <span>+</span> Creat A New</a> */}
                        <div className="custome-btn" onClick={(e) => {
                            e.preventDefault();
                            window.$('#Createproduct_' + ticket_id).modal('show');
                        }}><button> Creat A New</button></div>
                    </div>

                    {Message != '' &&
                        <h3><div className='success_text' style={{ textAlign: "center" }}>{Message}</div></h3>
                    }
                    <div className="container-fluid festival-management-page">
                        <div className="accordion-content">


                            {/* {FestivalData > 0 &&
                                FestivalData.map((data, index) => ( */}
                            <Product_token
                                name="festival"
                                onToggle={() => handleToggle(FestivalData)}
                                onClick={() => {
                                    const confirmBox = window.confirm(
                                        "Do you really want to delete this Festival?"
                                    )
                                    if (confirmBox === true) {
                                        FestivalDelete(FestivalData.id)
                                    }
                                }}
                                onChange={() => StatusChange(FestivalData.id)}
                                active={clicked === FestivalData}
                                key={FestivalData}
                                content={FestivalData} />
                            {/* ))
                            } */}

                            {FestivalData === 0 && <div className='no_record'>No data found</div>}
                        </div>

                    </div>
                </div>
            </section>

            {/* <!-- Login Modal --> */}

            <div className="modal fade c-login-modal" id={`Createproduct_${ticket_id}`} tabindex="-1" aria-labelledby="Createproduct" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">

                            <div className="c-festival-create-form">
                                <div className="col-sm-12">
                                    <center> <h2>Create Product</h2></center>
                                    <form onSubmit={handleSubmitAddToken}>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Product name"
                                                name="product_name"
                                                id="product_name"
                                                maxLength="50"
                                                // value={content.id}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter About The Ticket"
                                                name="about_ticket"
                                                id="about_ticket"
                                                maxLength="50"
                                                onChange={handleInputChange} />

                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="file"
                                                className="form-control"
                                                placeholder="Enter Upload logo"
                                                name="file"
                                                id="file"
                                                accept=".png,.jpg,.jpeg,.webp"
                                                onChange={handleInputChange} />

                                        </div>

                                        {inputList.map((x, i) => {
                                            return (
                                                <div className="box">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <input
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Enter Name"
                                                                // value={x.name}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <input
                                                                className="form-control"
                                                                name="content"
                                                                placeholder="Enter Content"
                                                                // value={x.content}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="btn-box">
                                                        {inputList.length !== 1 && (
                                                            <button className="mr10" onClick={() => handleRemoveClick(i)}>
                                                                Remove
                                                            </button>
                                                        )}
                                                        {inputList.length - 1 === i && (
                                                            <button onClick={handleAddClick}>Add</button>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}


                                        <div className="custome">
                                            <button className="btn" type="submit" >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
            <Outlet />
        </>
    );
}

export default FesivalList
