import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom'
import Footer from '../component/footer';
import Header from '../component/header';
import axios from '../axios';
function Confidentility() {
    const [LanguageData, setLanguageData] = useState({});
    let language_id = localStorage.getItem('language_id');
    useEffect(() => {
        

        if (language_id == null) {
            language_id = 2;
            console.log();
        }
        //get languages
        const getcms = async () => {
            const apiData = { language_id: language_id, page_id: 3 };
            await axios.post('commonapi/cms_page', apiData)
                .then((result) => {
                    // const obj = result.data.data;
                    const data = result.data.data;
                    setLanguageData(data);
                    // const message = result.data.message;
                }).catch((error) => {
                    console.log(error);
                })
        };
        getcms();
    }, [language_id]);

    return (
        <>
            <div className="c-wrapper">
                <Header />
                <section className="c-festival-form-section">
                    {Object.keys(LanguageData).length > 0 &&
                        <div dangerouslySetInnerHTML={{ __html: LanguageData.description }} />
                    }
                </section>
                <Footer />
            </div>
        </>
    );
}

export default Confidentility