import React, { useState, useRef } from 'react';
import { jsPDF } from "jspdf";
import axios from '../axios';
import { Link, useParams } from 'react-router-dom';

const StandAccordion = ({ content, active, onToggle, onChange, id }) => {
    const contentEl = useRef();
    const festival_id = useParams()
    const [AdminPassword, setAdminPassword] = useState(content.password_seller_admin);
    const [ShowAdminPassword, setShowAdminPassword] = useState(null);

    const [EmployeePassword, setEmployeePassword] = useState('');
    const [ShowEmployeePassword, setShowEmployeePassword] = useState(null);

    const [Commission, setCommission] = useState('');

    const [Error, setError] = useState({ AdminPassword: null, EmployeePassword: null });

    // const download = (number_of_file, image, file_name) => {
    //     if (number_of_file == null) {
    //         return false
    //     }
    //     var img = document.createElement("img")
    //     img.setAttribute("src", image)
    //     const doc = new jsPDF();
    //     for (let index = 0; index < number_of_file; index++) {
    //         if (index != 0) {
    //             doc.addPage("a4");
    //         }
    //         doc.addImage(image, 0, 0, 210, 210);
    //     }
    //     doc.save(file_name + ".pdf");
    // };

    const password_update = (type) => {
        if (type == 'admin') {
            let error = {}
            if (!AdminPassword) {
                error.AdminPassword = 'Admin Password Requried!'
            }

            if(AdminPassword.length < 6){
                error.AdminPassword = 'Admin Password Min length 6!'
            }

            if (content.password_seller_employee === AdminPassword) {
                error.AdminPassword = "Password not same"
            }

            setError(error);
            if (Object.keys(error).length == 0) {

                const StandAdminPasswordChage = async () => {
                    let apidata = {
                        stand_id: content.id,
                        type: 'admin',
                        admin_password: AdminPassword,
                        employee_password: '',
                    }
                    await axios.post('stand/StandPasswordChange', apidata)
                        .then((result) => {
                            let getData = result.data.status
                            if (getData == true) {
                                setShowAdminPassword(AdminPassword)
                                window.location.reload()
                            }
                            // setStandData(getData);

                        }).catch((error) => {
                            // alert();
                            console.log(error);
                        })
                };
                StandAdminPasswordChage();
            }

        } else {
            let error = {}
            if (!EmployeePassword) {
                error.EmployeePassword = 'Employee Password Requried!'
            }

            if(EmployeePassword.length < 6){
                error.EmployeePassword = 'Employee Password Min length 6!'
            }

            if (content.password_seller_admin === EmployeePassword) {
                error.EmployeePassword = "Password not same"
            }

            setError(error);
            if (Object.keys(error).length == 0) {

                const StandEmployeePasswordChage = async () => {
                    let apidata = {
                        stand_id: content.id,
                        type: 'employee',
                        admin_password: '',
                        employee_password: EmployeePassword,
                    }
                    await axios.post('stand/StandPasswordChange', apidata)
                        .then((result) => {
                            let getData = result.data.status
                            if (getData == true) {
                                setShowEmployeePassword(EmployeePassword)
                                window.location.reload()
                            }
                        }).catch((error) => {
                            console.log(error);
                        })
                };
                StandEmployeePasswordChage();
            }
        }
    }


    const stand_commission_change = () => {
        const StandCommissionChange = async () => {
            let apidata = {
                stand_id: content.id,
                stand_commission: Commission
            }
            await axios.post('stand/StandCommissionChange', apidata)
                .then((result) => {
                    let getData = result.data.status
                    if (getData == true) {
                        window.location.reload()
                    }
                }).catch((error) => {
                    console.log(error);
                })
        };
        StandCommissionChange();
    }

    return (
        <>
            <div className="accordion-item">
                <div className="acc-tabs">
                    <span className="row acc-tabs-heading">
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                            <div>
                                <span>{id}</span>
                                <img className="stand_upload_logo_image" src={content.upload_logo} />
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                            <div className='stand_content_image'>
                                {/* className='stand_content' */}
                                <div className='stand_name'>
                                    <span>{content.name}</span>
                                </div>
                                <div className='stand_name'>
                                    <Link to={`/${content.id}/StandUser`} state={{ id: festival_id.id }}>
                                        Stand User List
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-center col-4">
                            <a
                                href={content.qr_image}
                                // 
                                download={`${content.name}.png`}
                            // onClick={() => {
                            //     let field = prompt("How many download file ?")
                            //     if (field == null) {
                            //         return false;
                            //     }

                            //     if (field) {
                            //         let file_name = content.name
                            //         let image = content.qr_image
                            //         download(field, image, file_name)
                            //     }
                            // }
                            // }
                            >
                                DOWNLOAD QR CODE STAND
                            </a>
                            <img className="qr_code_image" src={content.qr_image} />
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                            <span className='stand_status'>{content.status}</span> STAND
                            <span className="form-check form-switch">
                                <input className={`form-check-input ${content.status == 'active' ? '' : 'myinactive'}`} checked={content.status == 'active' ? 'checked' : ''} onChange={onChange} type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" for="flexSwitchCheckDefault"></label>
                            </span>
                        </div>
                    </span>
                    <h2 onClick={onToggle}> <i className="fa fa-chevron-down arrowRotate"></i></h2>

                    {active &&
                        <div className="tabShowHide" ref={contentEl}>

                            <table className="stand-tbl">
                                <tbody>
                                    <tr>
                                        <td width="10%">TOTAL SALE
                                            <strong>{content.total_sale == null ? '0' : content.total_sale}</strong>
                                        </td>
                                        <td width="10%">NUMBER OF BUYER <strong>{content.number_of_buyer == null ? '0' : content.number_of_buyer}</strong></td>
                                        <td width="10%">AVERAGE BASKET <strong>{content.average_basket == null ? '0' : content.average_basket}</strong></td>
                                        <td width="10%">SALES AMOUNT <strong>{content.sales_amount == null ? '0' : content.sales_amount}</strong></td>
                                        <td width="15%">WITHDRAW STATUS <strong className="yellow-text">PENDING</strong></td>
                                        <td width="15%">
                                            PASSWORD FOR SALER ADMIN
                                            <strong className="yellow-text">
                                                <div>{ShowAdminPassword != null ? ShowAdminPassword : content.password_seller_admin}</div>
                                                <input
                                                    name='password_seller_admin'
                                                    defaultValue={content.password_seller_admin}
                                                    onChange={(e) => setAdminPassword(e.target.value)}
                                                    minLength={6}
                                                />
                                                <button className='stand_btn' onClick={() => password_update('admin')}>Update</button>
                                            </strong>
                                            {Error.AdminPassword != null &&
                                                <div className='error_text'>{Error.AdminPassword}</div>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" height="40"></td>
                                    </tr>
                                    <tr>
                                        <td width="10%">SET INDIVIDUAL COMMISSION
                                            <div>
                                                <strong>{content.stand_commission}%</strong>
                                                <input
                                                    name='stand_commission'
                                                    defaultValue={content.stand_commission}
                                                    onChange={(e) => setCommission(e.target.value)}
                                                    style={{ width: '70px' }}
                                                />
                                                <button className='stand_btn' onClick={() => stand_commission_change()}>Update</button>
                                            </div>
                                        </td>
                                        <td colspan="4"></td>
                                        <td width="10%">
                                            PASSWORD FOR SALER EMPLOYEE
                                            <strong className="yellow-text">
                                                <div>{ShowEmployeePassword != null ? ShowEmployeePassword : content.password_seller_employee}</div>
                                                <input
                                                    name='password_seller_employee'
                                                    defaultValue={content.password_seller_employee}
                                                    onChange={(e) => setEmployeePassword(e.target.value)}
                                                    minLength={6}
                                                />
                                                <button className='stand_btn' onClick={() => password_update('employee')}>Update</button>
                                            </strong>
                                            {Error.EmployeePassword != null &&
                                                <div className='error_text'>{Error.EmployeePassword}</div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    }
                </div>
            </div >
        </>

    );
};

export default StandAccordion;