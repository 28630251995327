import React from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import { Routes, Route, Outlet, Link } from "react-router-dom";


function Main() {
    return (
        <>
            <Header />

            <section class="c-download-scan-section">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-7 c-download-scan-left">
                            <h1>Download your festival <div>compagnon and start</div> taking fun!</h1>
                            <br />
                                <div>
                                    <img src="/asset/images/apple-store-scan.png" />
                                    <img src="/asset/images/android-store-scan-img.png" />
                                </div>
                        </div>
                        <div class="col-sm-5 c-download-scan-right">
                            <img src="/asset/images/festival-mobile-img.png" />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <Outlet />
        </>
    );
}

export default Main