import React, { useState, useEffect } from 'react'
import axios from '../axios';
import { BrowserRouter as Router, Route, Link, Routes, useLocation, useParams } from 'react-router-dom'

import Header from '../component/header'
import Footer from '../component/footer'

function ForgotPassword() {

    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    console.log(search)
    if (search == '') {
        window.location.href = '/'
    }
    // const formData = [{ id }]

    const initialValuesAddFestival = { password: "", confirm_password: "" };
    const [formValuesAddFestival, setFormValuesAddFestival] = useState(initialValuesAddFestival);

    const [formErrorsAddFestival, setFormErrorsAddFestival] = useState({});

    const [isSubmit, setIsSubmitAddFestival] = useState(false);

    const [message, setMessage] = useState('');


    const handleChangeAddFestival = (e) => {
        const { name, value } = e.target;
        setFormValuesAddFestival({ ...formValuesAddFestival, [name]: value });
    };

    const handleSubmitAddFestival = (e) => {
        e.preventDefault();
        setFormErrorsAddFestival(validateAddFestival(formValuesAddFestival));
        setIsSubmitAddFestival(true);
    };

    // Add Festival form
    const validateAddFestival = (values) => {
        console.log(values);
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 8) {
            errors.password = "Password must be more than 8 characters";
        } else if (values.password.length > 10) {
            errors.password = "Password cannot exceed more than 10 characters";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Repeat Password is required";
        } else if (values.confirm_password.length < 8) {
            errors.confirm_password = "Repeat Password must be more than 8 characters";
        } else if (values.confirm_password.length > 10) {
            errors.confirm_password = "Repeat Password cannot exceed more than 10 characters";
        } else if (values.confirm_password != values.password) {
            errors.confirm_password = "Repeat Password cannot match the password";
        }

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrorsAddFestival).length === 0 && isSubmit) {

            const password = async () => {

                let api_data = {
                    password: formValuesAddFestival.password
                }
                await axios.post('/reset-password/' + token, api_data)
                    .then((result) => {
                        console.log(result);
                        const resultget = result.data;
                        // console.log(resultget);
                        const message = result.data.message;
                        setMessage(message)


                        if (result.data.status !== false) {
                            // setIsSubmitAddFestival(resultget.data)  
                        }
                    }).catch((error) => {
                        const message = error.response.data.message;
                        alert(message)
                        // if (message === 'All ready mobile number register!') {
                        // all_ready_resgister = true;
                        // window.$('#registerverificationModal').modal('hide');
                        // }
                    })
                const timer = setTimeout(() => {
                    setMessage('')
                    window.location.reload()
                }, 3000);
                setIsSubmitAddFestival(false)
                return () => clearTimeout(timer);
                setIsSubmitAddFestival(false)

            };
            password();
        }
    }, [isSubmit]);

    return (
        <>
            <div className="c-wrapper">
                <Header />
                <section className="c-dashboard-section profile_page">

                    <div className="tab-content">
                        <div className='container-fluid'>

                            <div className="c-festival-create-form">
                                {message != '' &&
                                    <div className='success_text'>{message}</div>
                                }
                                <form onSubmit={handleSubmitAddFestival}>
                                    <div className='row mb-2'>
                                    <div className='col-md-3'></div>  
                                        <div className='col-md-6'>

                                            <label>Password</label>
                                            <input
                                                type="password"
                                                className="form-control col-md-6"
                                                placeholder="Password"
                                                name="password"
                                                maxLength="50"
                                                value={formValuesAddFestival.password}
                                                onChange={handleChangeAddFestival}
                                            />
                                            {formErrorsAddFestival.password != null &&
                                                <div className='error_text'>{formErrorsAddFestival.password}</div>
                                            }

                                        </div>
                                        <div className='col-md-3'></div>
                                        <div className='col-md-3'></div>
                                        <div className='col-md-6'>
                                            <label>Confirm Password</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Repeat password"
                                                name="confirm_password"
                                                maxLength="50"
                                                value={formValuesAddFestival.confirm_password}
                                                onChange={handleChangeAddFestival}
                                            />
                                            {formErrorsAddFestival.confirm_password != null &&
                                                <div className='error_text'>{formErrorsAddFestival.confirm_password}</div>
                                            }
                                            {/* {formErrors.last_name != null &&
                                                <div className='error_text'>{formErrors.last_name}</div>
                                            } */}
                                        </div>
                                        <div className='col-md-3'></div>
                                    </div>


                                    <center>
                                        <div className="col-md-3">
                                            <div className="custome">
                                                <button className="btn-submit btn-primary btn" type="submit" >
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </center>
                                </form>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
            <Footer />

        </>
    )
}

export default ForgotPassword