import routes, { useState, useEffect } from './routes';
import { Routes, Route, Outlet, Link } from "react-router-dom";


function RouteWithSubRoutes(route) {
    return (
        <>
            <Routes>
                {route.pathFalse != false &&
                    <Route path={route.path} exact element={route.component} />
                }
            </Routes>
        </>
    );
}

function App() {

    const Routes = routes;


    return (
        <>
            {
                Routes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} />
                ))
            }
        </>
    );
}

export default App;
