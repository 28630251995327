import React, { useState, useRef } from 'react';
import { useEffect } from 'react';

import { format } from 'date-fns';
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import axios from '../axios';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
const imageMimeType = /image\/(png|jpg|jpeg|webp)/;
const imagesize = 2;


const Accordion = ({ content, active, onToggle, name, onClick, onChange }) => {
    // console.log(content)
    const Accname = name;
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const [Message, setMessage] = useState("");
    const initialValuesAddFestival = {
        title: content.title, user_id: content.user_id, artist_id: content.artist_id,
        artwork_name: content.artwork_name, about_the_artist: content.about_the_artist, about_the_artwork: content.about_the_artwork, file: content.file,
        conditions: content.conditions, number_of_tickets: content.number_of_tickets, artwork_once_won: content.artwork_once_won,
        about_the_value: content.about_the_value, min_bid_amount: content.min_bid_amount,
        max_bid_amount: content.max_bid_amount, start_date_time: content.start_date_time, end_date_time: content.end_date_time,
        question_text: content.question_text, winner_amount: content.winner_amount, answer_text1: "", answer_text2: "", answer_text3: "", answer_text4: ""
    };
    const [contents, setFestivalData] = useState([{}]);
    console.log(contents);
    let today = new Date(contents.start_date_time);
    let enddate = new Date(contents.end_date_time);
    //console.log(enddate.toLocaleDateString("en-US"))
   
    const [formValuesEditTokenization, setFormValuesEditTokenization] = useState(initialValuesAddFestival);

    const [formErrorsEditTokenization, setFormErrorsEditTokenization] = useState({});
    const [isSubmitEditTokenization, setIsSubmitEditTokenization] = useState(false);
    const [formErrorsAddFestival, setFormErrorsAddFestival] = useState({});
    const [isSubmitAddFestival, setIsSubmitAddFestival] = useState(false);
    // console.log(formValuesEditTokenization)
    //Add Festival form
    // const handleChangeAddFestival = (e) => {
    //     const { name, value } = e.target;
    //     setFormValuesAddFestival({ ...formValuesAddFestival, [name]: value });
    // };
    const [Artist, setArtist] = useState({});
    const handleChangeEditTokenization = (e) => {
        if (e.target.files != undefined) {
            const { name, files } = e.target;
            if (!files[0].type.match(imageMimeType)) {
                const errors = {};
                errors.file = "This file format is not supported";
                // setFormErrorsAddFestival(errors);
                setFormValuesEditTokenization({ ...formValuesEditTokenization, [name]: "" });
            } else if ((files[0].size / 1024 / 1024) > imagesize) {
                const errors = {};
                errors.file = "Max upload file size is 2048";
                setFormErrorsAddFestival(errors);
                setFormValuesEditTokenization({ ...formValuesEditTokenization, [name]: "" });
            } else {
                setFormValuesEditTokenization({ ...formValuesEditTokenization, [name]: files[0] });
                const errors = {};
                errors.file = "";
                // setFormErrors(errors);
            }
        } else {
            const { name, value } = e.target;
            setFormValuesEditTokenization({ ...formValuesEditTokenization, [name]: value });
        }
        // const { name, value } = e.target;
        // setFormValuesEditTokenization({ ...formValuesEditTokenization, [name]: value });
    };

    const handleSubmitEditTokenization = (e) => {
        e.preventDefault();
        setFormErrorsEditTokenization(validateAddFestival(formValuesEditTokenization));
        setIsSubmitEditTokenization(true);
    };

    const [deletelottery, setIsDeletelottery] = useState(null);
    const handleClickDeleteLottery = (id) => {
        setIsDeletelottery(id);
    };

    // const handleSubmitAddFestival = (e) => {
    //     e.preventDefault();
    //     setFormErrorsAddFestival(validateAddFestival(formValuesEditFestival));
    //     setIsSubmitAddFestival(true);
    // };
    const validateAddFestival = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        // if (!values.product_name) {
        //     errors.product_name = "Product name is required!";
        // }

        return errors;
    };


    const contentEl = useRef();
    useEffect(() => {
        const getartist = async () => {
            await axios.get('users/artist')
                .then((result) => {
                    if (result.data.status == true) {
                        setArtist(result.data.data)
                    }
                })
        };
        getartist();

        const lotterylist = async () => {
            let apidata = { id: content.id, user_id: content.user_id, standId: 0, type: 0, language_id: 2 }


            await axios.post('lottery/detail', apidata)
                .then((result) => {
                    let getData = result.data.data
                    setFestivalData(getData);
                    setMessage('')

                    // setisLoading(false)

                }).catch((error) => {
                    // console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        lotterylist();

        // delete Lottery
        if (deletelottery != null) {
            const deletelotterys = async () => {
                const id = {
                    id: deletelottery
                }
                await axios.post('lottery/delete', id)
                    .then((result) => {
                        alert('record deleted');
                        window.location.reload();
                    })
            };
            deletelotterys();
        }

        if (Object.keys(formErrorsEditTokenization).length === 0 && isSubmitEditTokenization) {

            let question_text;
            if (formValuesEditTokenization.question_text != '') {
                question_text = {
                    // "id": contents.lottery_question_answer_list[0].id,
                    "question_text": formValuesEditTokenization.question_text
                }
            } else {
                question_text = {
                    // "id": contents.lottery_question_answer_list[0].id,
                    "answer_text": contents.lottery_question_answer_list[0].question_text
                }
            }
            // console.log(question_text)
            let answer1;
            if (formValuesEditTokenization.answer_text1 != '') {
                answer1 = {
                    "id": contents.lottery_question_answer_list[0].lottery_answer_list[0].id,
                    "answer_text": formValuesEditTokenization.answer_text1
                }
            } else {
                answer1 = {
                    "id": contents.lottery_question_answer_list[0].lottery_answer_list[0].id,
                    "answer_text": contents.lottery_question_answer_list[0].lottery_answer_list[0].answer_text
                }
            }


            let answer2;
            if (formValuesEditTokenization.answer_text2 != '') {
                answer2 = {
                    "id": contents.lottery_question_answer_list[0].lottery_answer_list[1].id,
                    "answer_text": formValuesEditTokenization.answer_text2
                }
            } else {
                answer2 = {
                    "id": contents.lottery_question_answer_list[0].lottery_answer_list[1].id,
                    "answer_text": contents.lottery_question_answer_list[0].lottery_answer_list[1].answer_text
                }
            }

            let answer3;
            if (formValuesEditTokenization.answer_text3 != '') {
                answer3 = {
                    "id": contents.lottery_question_answer_list[0].lottery_answer_list[2].id,
                    "answer_text": formValuesEditTokenization.answer_text3
                }
            } else {
                answer3 = {
                    "id": contents.lottery_question_answer_list[0].lottery_answer_list[2].id,
                    "answer_text": contents.lottery_question_answer_list[0].lottery_answer_list[2].answer_text
                }
            }

            let answer4;
            if (formValuesEditTokenization.answer_text4 != '') {
                answer4 = {
                    "id": contents.lottery_question_answer_list[0].lottery_answer_list[3].id,
                    "answer_text": formValuesEditTokenization.answer_text4
                }
            } else {
                answer4 = {
                    "id": contents.lottery_question_answer_list[0].lottery_answer_list[3].id,
                    "answer_text": contents.lottery_question_answer_list[0].lottery_answer_list[3].answer_text
                }
            }

            const Tokenizationsupdate = async () => {
                let StandData = new FormData();
                StandData.append("id", content.id)
                StandData.append("title", formValuesEditTokenization.title)
                StandData.append("user_id", user_data.id)
                StandData.append("artist_id", formValuesEditTokenization.artist_id)
                StandData.append("artwork_name", formValuesEditTokenization.artwork_name)
                StandData.append("about_the_artist", formValuesEditTokenization.about_the_artist)
                StandData.append("about_the_artwork", formValuesEditTokenization.about_the_artwork)
                StandData.append("timer_end", formValuesEditTokenization.timer_end)
                StandData.append("winner_amount", formValuesEditTokenization.winner_amount)
                StandData.append("file", formValuesEditTokenization.file)
                StandData.append("number_of_tickets", formValuesEditTokenization.number_of_tickets)
                StandData.append("conditions", formValuesEditTokenization.conditions)
                StandData.append("artwork_once_won", formValuesEditTokenization.artwork_once_won)
                StandData.append("about_the_value", formValuesEditTokenization.about_the_value)
                StandData.append("end_date_time", formValuesEditTokenization.end_date_time)
                StandData.append("start_date_time", formValuesEditTokenization.start_date_time)
                StandData.append("min_bid_amount", formValuesEditTokenization.min_bid_amount)
                StandData.append("max_bid_amount", formValuesEditTokenization.max_bid_amount)
                StandData.append("question_text", formValuesEditTokenization.question_text)
                StandData.append("answer_text[]", JSON.stringify([answer1, answer2, answer3, answer4]))
                
                await axios.post('lottery/update', StandData)
                    .then((result) => {
                        setMessage(result.data.message)
                        window.location.reload();
                    }).catch((error) => {
                        // console.log(error.response.data.message);
                        alert(error.response.data.message);
                        // setloggedinError(error.response.data);
                    })
            };
            Tokenizationsupdate();
        }

    }, [formErrorsAddFestival, deletelottery, isSubmitEditTokenization]);
    return (
        <>
            <div className="accordion-item lottery-page">
                <div className="acc-tabs" >
                    <span className="row acc-tabs-heading">
                        <div className="col-xl-4 col-lg-2 col-md-3 col-sm-1 col-10" onClick={onToggle}>
                            {content.title}
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-5 col-sm-5 col-12">
                            <Link className="download_text" to={`/${content.id}/lottery_build`}>
                                <button className="btn cutome_festival_open" type="submit" >
                                    <span>Open Bid</span>
                                </button>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12">
                            <span className='stand_status'>{content.status}</span>
                            <span className="form-check form-switch">
                                <input className={`form-check-input ${content.status == 'active' ? '' : 'myinactive'}`} checked={content.status == 'active' ? 'checked' : ''} onChange={onChange} type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" for="flexSwitchCheckDefault"></label>
                            </span>

                        </div>
                    </span>
                    {active === true && <h2 onClick={onToggle}> <i className='fa fa-chevron-up'></i></h2>}
                    {active === false && <h2 onClick={onToggle}> <i className='fa fa-chevron-down'></i></h2>}

                    {active &&
                        <div className="tabShowHide accordion_tab_content"
                            ref={contentEl}>
                            <form onSubmit={handleSubmitEditTokenization}>
                                <table className="stand-tbl">
                                    <tbody>
                                        <tr>
                                            <td width="15%">Title </td>
                                            <td width="15%"><input type="text" defaultValue={contents.title} onChange={handleChangeEditTokenization} name="title"></input></td>
                                            <td width="15%">About The Value</td>
                                            <td className="value_textarea">
                                                <input type="text" defaultValue={contents.about_the_value} onChange={handleChangeEditTokenization} name="about_the_value"></input></td>

                                            <td width="15%">
                                                {/* <input type="date" selected={contents.timer_end} onChange={handleChangeEditTokenization} name="timer_end"></input> */}
                                            </td>
                                            <td width="10%" className="pt-2" align="right">Image</td>
                                            <td><span className="browse_btn">
                                                <input type="file"
                                                    onChange={handleChangeEditTokenization} name="file"
                                                    id="file" /></span>
                                            </td>
                                            {contents.file == '' &&
                                                <td rowSpan="3" width="15%"><img src="/asset/images/demo-user.jpg" alt="profile.png" style={{ height: '100%', width: '100%' }} /></td>
                                            }
                                            {contents.file != '' &&
                                                <td rowSpan="3" width="15%"><img src={contents.file} style={{ height: '100%', width: '100%' }} /></td>
                                            }
                                        </tr>
                                        <tr>

                                            <td width="15%">Artisat Name </td>
                                            <td width="15%">
                                                <select
                                                    onChange={handleChangeEditTokenization}
                                                    // value={formValuesAddFestival.artist_id}
                                                    name="artist_id"
                                                    id="artist_id"
                                                    defaultValue={contents.artist_id}
                                                    className="form-control">
                                                    <option Selected >Select Artist</option>
                                                    {Artist.length > 0 &&
                                                        Artist.map((res, i) => (
                                                            <option value={res.id}>{res.first_name}{res.people_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </td>
                                            {/* <input type="text" onChange={handleChangeEditTokenization} name="artist_id"></input></td> */}
                                            <td width="15%">Winner Amount</td>
                                            <td width="15%"><input type="text" defaultValue={contents.winner_amount} onChange={handleChangeEditTokenization} name="winner_amount"></input></td>

                                        </tr>
                                        <tr>
                                            <td>Start Date </td>
                                            <td>
                                                {/* <DatePicker
                                                    className="form-control text-left mr-2 col-md-6"
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    todayButton="Today"
                                                    selected={contents.start_date_time}
                                                    onChange={(date) => setFormValuesEditTokenization({ ...formValuesEditTokenization, 'start_date_time': date })}
                                                    startDate={formValuesEditTokenization.start_date_time}
                                                    // endDate={formValuesEditTokenization.end_date_time}
                                                    minDate={formValuesEditTokenization.start_date_time}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                /> */}
                                                {/* {today.toLocaleDateString("en-US")} */}
                                                <input type="date" defaultValue={today.toLocaleDateString("en-US")}
                                                    min={new Date().toISOString().split("T")[0]} onChange={handleChangeEditTokenization}
                                                    name="start_date_time">
                                                </input>
                                            </td>
                                            <td>End Date </td>
                                            <td><input type="date" defaultValue={enddate.toLocaleDateString("en-US")}
                                                min={new Date().toISOString().split("T")[0]}
                                                Selected={contents.end_date_time} onChange={handleChangeEditTokenization}
                                                name="end_date_time"></input></td>
                                        </tr>
                                        <tr>
                                            <td>Min Bid Amount</td>
                                            <td><input type="text" defaultValue={contents.min_bid_amount}
                                                onChange={handleChangeEditTokenization}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                name="min_bid_amount" />
                                            </td>
                                            <td>Mix Bid Amount</td>
                                            <td><input type="text" defaultValue={contents.max_bid_amount}
                                                onChange={handleChangeEditTokenization}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                name="max_bid_amount" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Artwork Name </td>
                                            <td><input type="text" defaultValue={contents.artwork_name} onChange={handleChangeEditTokenization} name="artwork_name"></input></td>
                                            <td>Number Of Ticket </td>
                                            <td><input type="text" defaultValue={contents.number_of_tickets} onChange={handleChangeEditTokenization} name="number_of_tickets"></input></td>
                                        </tr>
                                        <tr>
                                            <td>About The Artisat</td>
                                            <td><textarea rows="3" name="about_the_artist" onChange={handleChangeEditTokenization} >{contents.about_the_artist}</textarea></td>
                                            <td>Conditions </td>
                                            <td><textarea rows="3" name="conditions" onChange={handleChangeEditTokenization}>{contents.conditions}</textarea></td>
                                        </tr>
                                        <tr>
                                            <td>About The Artwork</td>
                                            <td><textarea rows="3" onChange={handleChangeEditTokenization} name="about_the_artwork">{contents.about_the_artwork}</textarea></td>
                                            <td colSpan="2">Asking Question about where to display this Artwork once won?<br />
                                                <span className="lottery_radio_btn">
                                                    <input type="radio" name="artwork_once_won" className="yes_radio_btn"
                                                        defaultChecked={contents.artwork_once_won === '1'} value="1" onChange={handleChangeEditTokenization} />Yes
                                                    <input type="radio" name="artwork_once_won" className="no_radio_btn"
                                                        defaultChecked={contents.artwork_once_won === '0'} value="0" onChange={handleChangeEditTokenization} />No
                                                </span>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td> Question</td>
                                            {contents.lottery_question_answer_list.length > 0 &&
                                                contents.lottery_question_answer_list.map((res, i) => (
                                                    <td>  <input type="text" defaultValue={res.question_text} onChange={handleChangeEditTokenization} name="question_text"></input>
                                                    </td>
                                                ))
                                            }
                                            {/* {contents.lottery_question_answer_list } */}
                                            {/* <input type="text"  onChange={handleChangeEditTokenization} name="question_text"></input> */}
                                            <td>Answer</td>
                                            <td>
                                                <input type="text" defaultValue={contents.lottery_question_answer_list[0].lottery_answer_list[0].answer_text} onChange={handleChangeEditTokenization} name="answer_text1"></input>
                                                <input type="text" defaultValue={contents.lottery_question_answer_list[0].lottery_answer_list[1].answer_text} onChange={handleChangeEditTokenization} name="answer_text2"></input>
                                                <input type="text" defaultValue={contents.lottery_question_answer_list[0].lottery_answer_list[2].answer_text} onChange={handleChangeEditTokenization} name="answer_text3"></input>
                                                <input type="text" defaultValue={contents.lottery_question_answer_list[0].lottery_answer_list[3].answer_text} onChange={handleChangeEditTokenization} name="answer_text4"></input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5"></td>
                                            <td width="10%">
                                                <div className="custome">
                                                    <button className="btn" onClick={() => handleClickDeleteLottery(content.id)}>
                                                        <span>Delete</span></button>
                                                </div>
                                            </td>
                                            <td width="10%">
                                                <div className="custome">
                                                    <button className="btn" type="submit" ><span>Save</span></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Accordion;