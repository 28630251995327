import React, { useEffect, useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios';
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import LoginHeader from '../component/loginHeader';
import Lotterys from '../component/lottery';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
const imagesize = 2;

function Lotterey() {
    const location = useLocation();
    const sessiontoken = localStorage.getItem('token');
    if (sessiontoken === null) {
        window.location.href = "/";
    }

    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const user_datas = { user_id: user_data.id }
    // console.log(user_datas);
    const initialValuesAddTicket = {
        title: "", user_id: "", artist_id: "", artwork_name: "",
        about_the_artist: "", about_the_artwork: "", file: "", email_of_artist_responsibility: "",
        artist_resale_rights: "", conditions: "", number_of_tickets: "", artwork_once_won: "", winner_amount: "",
        about_the_value: "", question_text: "", min_bid_amount: "",
        max_bid_amount: "", answer_text1: "", answer_text2: "", answer_text3: "", answer_text4: "", start_date_time: new Date(), end_date_time: new Date((new Date()).valueOf() + 1000 * 3600 * 24)
    };
    const [formValuesAddFestival, setFormValuesAddFestival] = useState(initialValuesAddTicket);
    // console.log(formValuesAddFestival);
    const [Artist, setArtist] = useState({});

    const [formErrorsAddFestival, setFormErrorsAddFestival] = useState({});
    const [isSubmitAddFestival, setIsSubmitAddFestival] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [Message, setMessage] = useState("");
    const [FestivalData, setFestivalData] = useState([{}]);
    const [clicked, setClicked] = useState("0");

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    //Add Festival form
    const handleChangeAddFestival = (e) => {
        if (e.target.files != undefined) {
            const { name, files } = e.target;
            if (!files[0].type.match(imageMimeType)) {
                const errors = {};
                errors.file = "This file format is not supported";
                setFormErrorsAddFestival(errors);
                setFormValuesAddFestival({ ...formValuesAddFestival, [name]: "" });
            } else if ((files[0].size / 1024 / 1024) > imagesize) {
                const errors = {};
                errors.file = "Max upload file size is 2048";
                setFormErrorsAddFestival(errors);
                setFormValuesAddFestival({ ...formValuesAddFestival, [name]: "" });
            } else {
                setFormValuesAddFestival({ ...formValuesAddFestival, [name]: files[0] });
                const errors = {};
                errors.file = "";
                // setFormErrors(errors);
            }
        } else {
            const { name, value } = e.target;
            setFormValuesAddFestival({ ...formValuesAddFestival, [name]: value });
        }
    };

    const handleSubmitAddFestival = (e) => {
        e.preventDefault();
        setFormErrorsAddFestival(validateAddFestival(formValuesAddFestival));
        setIsSubmitAddFestival(true);
    };


    // Add Festival form
    const validateAddFestival = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (moment(new Date()).format("YYYY/MM/DD") > moment(values.start_date_time).format("YYYY/MM/DD")) {
            errors.start_date_time = "Start Date not allow";
        }

        if (moment(new Date()).format("YYYY/MM/DD") > moment(values.end_date_time).format("YYYY/MM/DD")) {
            errors.end_date_time = "End Date not allow";
        }

        if (!values.start_date_time) {
            errors.start_date_time = "Start Date is required!";
        }
        if (!values.end_date_time) {
            errors.end_date_time = "End Date is required!";
        }
        if (values.start_date_time > values.end_date_time) {
            errors.date = "Please enter end date greater then start date";
        }
        if (!values.title) {
            errors.title = "Title is required!";
        }
        if (!values.file) {
            errors.file = "Image is required!";
        }
        if (!values.answer_text1) {
            errors.answer_text1 = "Answer Text is required!";
        }
        if (!values.answer_text2) {
            errors.answer_text2 = "Answer Text is required!";
        }
        if (!values.answer_text3) {
            errors.answer_text3 = "Answer Text is required!";
        }
        if (!values.answer_text4) {
            errors.answer_text4 = "Answer Text is required!";
        }
        if (!values.question_text) {
            errors.question_text = "Question Text is required!";
        }
        if (!values.artist_id) {
            errors.artist_id = "Artist is required!";
        }
        if (!values.artwork_name) {
            errors.artwork_name = "Artwork Name required!";
        }
        if (!values.winner_amount) {
            errors.winner_amount = "Winner Amount required!";
        }
        if (!values.number_of_tickets) {
            errors.number_of_tickets = "Number Of Ticket is required!";
        }
        if (!values.about_the_artist) {
            errors.about_the_artist = "About The Artist is required!";
        }
        if (!values.about_the_value) {
            errors.about_the_value = "About Value  is required!";
        }
        if (!values.min_bid_amount) {
            errors.min_bid_amount = "Min Bid Amount  is required!";
        }
        if (!values.max_bid_amount) {
            errors.max_bid_amount = "Max bid Amount is required!";
        }
        if (!values.about_the_artwork) {
            errors.about_the_artwork = "About The Artwork is required!";
        }



        // if (!values.email_of_artist_responsibility) {
        //     errors.email_of_artist_responsibility = "Email Of Artist responsibilty is required!";
        // }


        return errors;
    };

    const FestivalDelete = (index) => {
        const deletefestival = async () => {
            let apidata = {}
            apidata.festival_id = index
            await axios.post('festival/festival_delete', apidata)
                .then((result) => {
                    // console.log(result.data.message)
                    window.location.reload();
                    // setMessage(result.data.message)
                }).catch((error) => {
                    console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        deletefestival();
    }

    //Status Change event
    const [LotteryId, SetLotteryId] = useState('');
    const StatusChange = (id) => {
        SetLotteryId(id);
    }


    useEffect(() => {
        const getartist = async () => {
            await axios.get('users/artist')
                .then((result) => {
                    if (result.data.status == true) {
                        setArtist(result.data.data)
                    }
                })
        };
        getartist();

        const FestivalList = async () => {
            let apidata = {}
            if (user_data.id != null) {
                apidata.user_id = user_data.id
            } else {
                apidata.user_id = user_data.id
            }
            await axios.post('lottery/listuserLottery', apidata)
                .then((result) => {
                    let getData = result.data.data
                    setFestivalData(getData);
                    setMessage('')
                    setisLoading(false)

                }).catch((error) => {
                    console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        FestivalList();

        if (Object.keys(formErrorsAddFestival).length === 0 && isSubmitAddFestival) {
            // console.log(formValuesAddFestival.title)
            const Festivalcreate = async () => {

                let StandData = new FormData();
                StandData.append("title", formValuesAddFestival.title)
                StandData.append("user_id", user_data.id)
                StandData.append("artist_id", formValuesAddFestival.artist_id)
                StandData.append("artwork_name", formValuesAddFestival.artwork_name)
                StandData.append("about_the_artist", formValuesAddFestival.about_the_artist)
                StandData.append("about_the_artwork", formValuesAddFestival.about_the_artwork)
                StandData.append("winner_amount", formValuesAddFestival.winner_amount)
                StandData.append("file", formValuesAddFestival.file)
                StandData.append("number_of_tickets", formValuesAddFestival.number_of_tickets)
                StandData.append("conditions", formValuesAddFestival.conditions)
                StandData.append("artwork_once_won", formValuesAddFestival.artwork_once_won)
                StandData.append("about_the_value", formValuesAddFestival.about_the_value)
                StandData.append("end_date_time", formValuesAddFestival.end_date_time)
                StandData.append("start_date_time", formValuesAddFestival.start_date_time)
                StandData.append("min_bid_amount", formValuesAddFestival.min_bid_amount)
                StandData.append("max_bid_amount", formValuesAddFestival.max_bid_amount)
                StandData.append("question_text", formValuesAddFestival.question_text)
                StandData.append("answer_text[]", [formValuesAddFestival.answer_text1])
                StandData.append("answer_text[]", [formValuesAddFestival.answer_text2])
                StandData.append("answer_text[]", [formValuesAddFestival.answer_text3])
                StandData.append("answer_text[]", [formValuesAddFestival.answer_text4])

                // StandData.append("answer_text[]",[formValuesAddFestival.answer_text1 ,formValuesAddFestival.answer_text2 ,formValuesAddFestival.answer_text3 ,formValuesAddFestival.answer_text4])
                console.log(StandData)
                await axios.post('lottery/create', StandData)
                    .then((result) => {
                        console.log(result);
                        setMessage(result.data.message)
                        window.$('#Createlottery').modal('hide');
                        // window.location.reload();
                    }).catch((error) => {
                        console.log(error.response.data.message);
                        // alert(error.response.data.message);
                        // setloggedinError(error.response.data);
                    })
            };
            Festivalcreate();
        }

        if (LotteryId != '') {
            console.log(LotteryId);
            const FestivalStatus = async () => {
                let lottery_id = {
                    "lottery_id": LotteryId
                }
                await axios.post('lottery/lottery_change_status', lottery_id)
                    .then((result) => {
                        setMessage(result.data.message);
                        FestivalList();
                        SetLotteryId('');
                    }).catch((error) => {
                        console.log(error);
                    })
            }
            FestivalStatus();
        }



    }, [formErrorsAddFestival, LotteryId]);

    return (
        // this.state.isLoading ? *showLoadingScreen* 
        <>
            <Header />

            <div>FESTIVAL MANAGEMENT</div>

            <section className="c-dashboard-section">
                {/* {location.pathname != "/festival" &&
                    <LoginHeader Stand={false} standData={[]} />
                } */}

                <div className="tab-content">

                    {/* {location.pathname != "/festival" &&
                        <div className="tab-pane" id="my-festival" role="tabpanel" aria-labelledby="my-festival-tab">
                            <h2>
                                <div className="container-fluid">
                                    <span>FESTIVAL MANAGEMENT</span>
                                </div>
                            </h2>
                        </div>
                    } */}

                    <div className="container-fluid">
                        <h2 className="c-section-title">Lottery</h2>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            window.$('#Createlottery').modal('show');
                        }} className="create-festival-link"> <span>+</span> Creat A New</a>
                    </div>

                    {Message != '' &&
                        <h3><div className='success_text' style={{ textAlign: "center" }}>{Message}</div></h3>
                    }
                    <div className="container-fluid festival-management-page">
                        <div className="accordion-content">

                            {isLoading == false && FestivalData.length > 0 &&
                                FestivalData.map((data, index) => (
                                    <Lotterys
                                        name="festival"
                                        onToggle={() => handleToggle(index)}
                                        onClick={() => {
                                            const confirmBox = window.confirm(
                                                "Do you really want to delete this Festival?"
                                            )
                                            if (confirmBox === true) {
                                                FestivalDelete(data.id)
                                            }
                                        }}
                                        onChange={() => StatusChange(data.id)}
                                        active={clicked === index}
                                        key={index}
                                        content={data} />
                                ))
                            }

                            {FestivalData.length === 0 && <div className='no_record'>No data found</div>}
                        </div>

                    </div>
                </div>
            </section>

            {/* <!-- Login Modal --> */}
            <div className="modal fade c-login-modal" id="Createlottery" tabindex="-1" aria-labelledby="Createlottery" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {/* <div className="c-modal-logo">
                                <h2></h2>
                            </div> */}
                            <div className="c-festival-create-form">
                                <div className="col-sm-12">
                                    <center> <h2>Create Lottery</h2></center>
                                    <form onSubmit={handleSubmitAddFestival}>
                                        <div className="c-form-field">
                                            <select
                                                onChange={handleChangeAddFestival}
                                                value={formValuesAddFestival.artist_id}
                                                name="artist_id"
                                                id="artist_id"
                                                className="form-control"
                                            >
                                                <option Selected >Select Artist</option>
                                                {/* <select className="form-control" name="companytype"
                                                        onChange={cardValue} value={cardData.companytype}> */}
                                                {/* <option selevcted disabled>Select Business </option> */}
                                                {Artist.length > 0 &&
                                                    Artist.map((res, i) => (
                                                        <option value={res.id}>{res.first_name}{res.people_name}</option>
                                                    ))
                                                }
                                                {/* </select> */}
                                            </select>
                                            {formErrorsAddFestival.artist_id != null &&
                                                <div className='error_text'>{formErrorsAddFestival.artist_id}</div>
                                            }

                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Title"
                                                name="title"
                                                id="title"
                                                maxLength="50"
                                                value={formValuesAddFestival.title}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.title != null &&
                                                <div className='error_text'>{formErrorsAddFestival.title}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Artwork name"
                                                name="artwork_name"
                                                id="artwork_name"
                                                maxLength="50"
                                                value={formValuesAddFestival.artwork_name}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.artwork_name != null &&
                                                <div className='error_text'>{formErrorsAddFestival.artwork_name}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Winner Amount"
                                                name="winner_amount"
                                                id="winner_amount"
                                                maxLength="50"
                                                value={formValuesAddFestival.winner_amount}
                                                onChange={handleChangeAddFestival}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} />
                                            {formErrorsAddFestival.winner_amount != null &&
                                                <div className='error_text'>{formErrorsAddFestival.winner_amount}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Number of Ticket"
                                                name="number_of_tickets"
                                                id="number_of_tickets"
                                                maxLength="50"
                                                value={formValuesAddFestival.number_of_tickets}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.number_of_tickets != null &&
                                                <div className='error_text'>{formErrorsAddFestival.number_of_tickets}</div>
                                            }
                                        </div>
                                        {/* <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Gift Code"
                                                name="gift_code"
                                                id="gift_code"
                                                maxLength="50"
                                                value={formValuesAddFestival.gift_code}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.gift_code != null &&
                                                <div className='error_text'>{formErrorsAddFestival.gift_code}</div>
                                            }
                                        </div> */}
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter About The Artist"
                                                name="about_the_artist"
                                                id="about_the_artist"
                                                maxLength="50"
                                                value={formValuesAddFestival.about_the_artist}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.about_the_artist != null &&
                                                <div className='error_text'>{formErrorsAddFestival.about_the_artist}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter About The Artwork"
                                                name="about_the_artwork"
                                                id="about_the_artwork"
                                                value={formValuesAddFestival.about_the_artwork}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.about_the_artwork != null &&
                                                <div className='error_text'>{formErrorsAddFestival.about_the_artwork}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter About The Value"
                                                name="about_the_value"
                                                id="about_the_value"
                                                value={formValuesAddFestival.about_the_value}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.about_the_value != null &&
                                                <div className='error_text'>{formErrorsAddFestival.about_the_value}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Condition"
                                                name="conditions"
                                                id="conditions"
                                                value={formValuesAddFestival.conditions}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.conditions != null &&
                                                <div className='error_text'>{formErrorsAddFestival.conditions}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Question"
                                                name="question_text"
                                                id="question_text"
                                                value={formValuesAddFestival.question_text}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.question_text != null &&
                                                <div className='error_text'>{formErrorsAddFestival.question_text}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Answer 1"
                                                name="answer_text1"
                                                id="answer_text1"
                                                value={formValuesAddFestival.answer_text1}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.answer_text1 != null &&
                                                <div className='error_text'>{formErrorsAddFestival.answer_text1}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Answer 2"
                                                name="answer_text2"
                                                id="answer_text2 "
                                                value={formValuesAddFestival.answer_text2}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.answer_text2 != null &&
                                                <div className='error_text'>{formErrorsAddFestival.answer_text2}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Answer 3"
                                                name="answer_text3"
                                                id="answer_text3 "
                                                value={formValuesAddFestival.answer_text3}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.answer_text3 != null &&
                                                <div className='error_text'>{formErrorsAddFestival.answer_text3}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Answer 4"
                                                name="answer_text4"
                                                id="answer_text4"
                                                value={formValuesAddFestival.answer_text4}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.answer_text4 != null &&
                                                <div className='error_text'>{formErrorsAddFestival.answer_text4}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <label>Artwork Once Won?</label><br />
                                            <input
                                                className="col-md-3"
                                                type="radio"
                                                name="artwork_once_won"
                                                id="artwork_once_won"
                                                value="1"
                                                onChange={handleChangeAddFestival} />Yes
                                            <input
                                                className="col-md-3"
                                                type="radio"
                                                name="artwork_once_won"
                                                id="artwork_once_won"
                                                value="0"
                                                onChange={handleChangeAddFestival} />No
                                            {formErrorsAddFestival.artwork_once_won != null &&
                                                <div className='error_text'>{formErrorsAddFestival.artwork_once_won}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="file"
                                                className="form-control"
                                                placeholder="Enter Upload logo"
                                                name="file"
                                                id="file"
                                                accept=".png,.jpg,.jpeg,.webp"
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.file != null &&
                                                <div className='error_text'>{formErrorsAddFestival.file}</div>
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="c-form-field col-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Min Bid Amount"
                                                    name="min_bid_amount"
                                                    id="min_bid_amount"
                                                    value={formValuesAddFestival.min_bid_amount}
                                                    onChange={handleChangeAddFestival}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} />
                                                {formErrorsAddFestival.min_bid_amount != null &&
                                                    <div className='error_text'>{formErrorsAddFestival.min_bid_amount}</div>
                                                }
                                            </div><div className="c-form-field col-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Max Bid Amount"
                                                    name="max_bid_amount"
                                                    id="max_bid_amount"
                                                    // value={formValuesAddFestival.max_bid_amount }
                                                    onChange={handleChangeAddFestival}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} />
                                                {formErrorsAddFestival.max_bid_amount != null &&
                                                    <div className='error_text'>{formErrorsAddFestival.max_bid_amount}</div>
                                                }
                                            </div>
                                        </div>
                                        <div autocomplete="off" className="date-picker">
                                            <div className="flex-row d-flex justify-content-center">
                                                <div className="input-group input-daterange row">
                                                    <div className="default-datepicker">
                                                        <DatePicker
                                                            className="form-control text-left mr-2 col-md-6"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="Start Date"
                                                            todayButton="Today"
                                                            selected={formValuesAddFestival.start_date_time}
                                                            onChange={(date) => setFormValuesAddFestival({ ...formValuesAddFestival, 'start_date_time': date })}
                                                            startDate={formValuesAddFestival.start_date_time}
                                                            endDate={formValuesAddFestival.end_date_time}
                                                            minDate={formValuesAddFestival.start_date_time}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        {formErrorsAddFestival.start_date_time != null &&
                                                            <div className='error_text'>{formErrorsAddFestival.start_date_time}</div>
                                                        }
                                                    </div>

                                                    <div className='default-datepicker'>
                                                        <DatePicker
                                                            className="form-control text-left mr-2  col-md-6"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="End date"
                                                            selected={formValuesAddFestival.end_date_time}
                                                            onChange={(date) => setFormValuesAddFestival({ ...formValuesAddFestival, 'end_date_time': date })}
                                                            selectsEnd
                                                            startDate={formValuesAddFestival.start_date_time}
                                                            endDate={formValuesAddFestival.end_date_time}
                                                            minDate={formValuesAddFestival.start_date_time}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        {formErrorsAddFestival.end_date_time != null &&
                                                            <div className='error_text'>{formErrorsAddFestival.end_date_time}</div>
                                                        }
                                                        {formErrorsAddFestival.date != null &&
                                                            <div className='error_text'>{formErrorsAddFestival.date}</div>
                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                        <div className="custome">
                                            {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                            <button className="btn" type="submit" >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Outlet />
        </>
    );
}

export default Lotterey
