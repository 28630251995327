import React, { useEffect, useState, useRef } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios';

import { Routes, Route, Outlet, Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import LoginHeader from '../component/loginHeader';
import { findAllInRenderedTree } from 'react-dom/test-utils';
import DatePicker from 'react-multi-date-picker';
import StandUserList from '../component/StandUserList';

function StandUser() {
    let { id } = useParams()

    let location = useLocation();

    let festival_id = location.state.id

    const [standUser, setStandUser] = useState([])

    const initialValues = { festival_id: festival_id, name: "", email: "", festival_role: "", stand_id: id };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setisLoading] = useState(true);

    const [ErrorMessage, setErrorMessage] = useState('');

    const handleChangeAddStandUser = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmitAddStandUser = (e) => {
        e.preventDefault();
        setFormErrors(validateStand(formValues));
        setIsSubmit(true);
    }

    const validateStand = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.festival_role) {
            errors.festival_role = "Festival role is required!";
        }

        if (!values.stand_id) {
            errors.stand_id = "Festival name is required!";
        }

        if (!values.name) {
            errors.name = "Name is required!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        return errors;
    };


    useEffect(() => {
        const StandUserList = async () => {
            let apidata = {
                stand_id: id
            }
            await axios.post('stand/stand_user_list', apidata)
                .then((result) => {
                    let getData = result.data.data
                    console.log(getData);
                    setStandUser(getData);
                    setisLoading(false)
                }).catch((error) => {
                    console.log(error);
                })
        };
        StandUserList();

        if (isSubmit && Object.keys(formErrors).length == 0) {
            setIsSubmit(false);
            const CreateStandUser = async () => {
                await axios.post('stand/CreatStandUser', formValues)
                    .then((result) => {
                        if (result.data.status == true) {
                            const message = result.data.message;
                            window.location.reload()
                        }
                        setErrorMessage('')
                    }).catch((error) => {
                        console.log(error);
                        setErrorMessage(error.response.data.message)
                    })
            };
            CreateStandUser();
        }
    }, [isSubmit]);



    return (
        <>
            <Header />
            <section className="c-dashboard-section c-stand-section stand_list">


                {/* <LoginHeader Stand={false} standData={[]} /> */}
                <div className="tab-content">
                    <div className="container">
                        {/* <div className='row'>
                            <div className='col-2'>
                                <div className="custome">
                                    <button>
                                        <a href={`/${festival_id}/stand`}>Back</a>
                                    </button>
                                </div>
                            </div>
                        </div> */}
                        <div className='row'>
                            <div className='col-sm-9'>
                                <h2>Stand User List</h2>
                            </div>
                            <div className="custome col-sm-3">
                                <a href={`/${festival_id}/stand`}>
                                    <button className="btn" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#CreateStand" style={{ marginTop: '0' }}>
                                        <span>Back</span>
                                    </button>
                                </a>
                                {/* <button className="btn" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#CreateStand" type="submit" style={{ marginTop: '0' }}>
                                    <span>Create Stand User</span>
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="accordion-content">
                            {standUser.length === 0 && <h3 className='text-center'>No data found</h3>}
                            {isLoading == false && standUser && standUser.length > 0 &&
                                standUser.map((data, index) => (
                                    <StandUserList
                                        name="stand"
                                        id={index + 1}
                                        key={index}
                                        content={data}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Login Modal --> */}
            <div className="modal fade c-login-modal" id="CreateStand" tabindex="-1" aria-labelledby="CreateStand" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {/* <div className="c-modal-logo">
                                <h2></h2>
                            </div> */}
                            <div className="c-festival-create-form">
                                <div className="col-sm-12">
                                    <center> <h2>Create Stand User</h2></center>
                                    {ErrorMessage != '' && <div className='error_text'>{ErrorMessage}</div>}
                                    <form onSubmit={handleSubmitAddStandUser}>
                                        <div className="c-form-field">

                                            <select
                                                className='form-control'
                                                name="festival_role"
                                                id="festival_role"
                                                onChange={handleChangeAddStandUser}
                                            // value={formValues.festival_id}
                                            >
                                                <option value="" >Please Select Role</option>
                                                <option value="2" >Seller Admin</option>
                                                <option value="3" >Seller Employee</option>

                                            </select>
                                            {formErrors.festival_role != null &&
                                                <div className='error_text'>{formErrors.festival_role}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Name"
                                                name="name"
                                                id="name"
                                                maxLength="50"
                                                value={formValues.name}
                                                onChange={handleChangeAddStandUser} />
                                            {formErrors.name != null &&
                                                <div className='error_text'>{formErrors.name}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter email"
                                                name="email"
                                                id="email"
                                                maxLength="50"
                                                value={formValues.email}
                                                onChange={handleChangeAddStandUser} />
                                            {formErrors.email != null &&
                                                <div className='error_text'>{formErrors.email}</div>
                                            }
                                        </div>

                                        <div className="custome">
                                            {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                            <button className="btn" type="submit" >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <Outlet />
        </>
    );
}

export default StandUser
