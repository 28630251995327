import React, { useState, useEffect } from 'react'
import axios from '../axios';
import Header from '../component/header'
import Footer from '../component/footer'

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

import OtpInput from 'react-otp-input';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import moment from 'moment';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


function Signup() {

    //Add Festival form
    const initialValuesAddFestival = { festival_name: "", festival_position: "", festival_count: "50", start_date: new Date(), end_date: new Date((new Date()).valueOf() + 1000 * 3600 * 24), first_name: "", last_name: "", email_address: "", phonecode: "", phone: "", password: "", confirm_password: "" };
    const [formValuesAddFestival, setFormValuesAddFestival] = useState(initialValuesAddFestival);
    const [formErrorsAddFestival, setFormErrorsAddFestival] = useState({});
    const [isSubmitAddFestival, setIsSubmitAddFestival] = useState(false);
    const [AddFestivalError, setAddFestivalError] = useState({});


    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [horizontal, sethorizontal] = useState();

    const [otp, setOTPInput] = useState("");
    const [isDisabled, setisDisabled] = useState(false);
    const [SendOtp, setSendOtp] = useState(false);
    const [OtpMessage, setOtpMessage] = useState("");
    const [OtpSuccess, setOtpSuccess] = useState(false);

    const [CountryGet, setCountryGet] = useState([{}]);

    //Otp
    const setOTP = (otp) => {
        setOTPInput({ otp })
        const otp_length = otp.length

        if (otp_length == 4) {
            setisDisabled(true);
            setSendOtp(true);
        }
    }

    const handleChangeHorizontal = (value) => {
        // console.log(e)
        sethorizontal(value)
        setFormValuesAddFestival({ 'festival_count': value });
    };


    // const sessiontoken = localStorage.getItem('token');
    // if (sessiontoken === null) {
    //     window.location.href = "/";
    // }


    const handleOnChange = (value, data, event, formattedValue) => {
        setFormValuesAddFestival({ ...formValuesAddFestival, ['phonecode']: '+ ' + data.dialCode, 'phone': value.slice(data.dialCode.length) });
    }

    //Add Festival form
    const handleChangeAddFestival = (e) => {
        const { name, value } = e.target;
        setFormValuesAddFestival({ ...formValuesAddFestival, [name]: value });
    };

    const handleSubmitAddFestival = (e) => {
        e.preventDefault();
        setFormErrorsAddFestival(validateAddFestival(formValuesAddFestival));
        setIsSubmitAddFestival(true);
    };

    // Add Festival form
    const validateAddFestival = (values) => {
        console.log(values);
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.festival_name) {
            errors.festival_name = "Festival name is required!";
        }

        if (!values.festival_position) {
            errors.festival_position = "Position Of Festival is required!";
        }

        if (!values.festival_count) {
            errors.festival_count = "Festival goer is required!";
        }

        if (!values.start_date) {
            errors.start_date = "Start Date is required!";
        }

        if (!values.end_date) {
            errors.end_date = "End Date is required!";
        }

        if (!values.first_name) {
            errors.first_name = "First name is required!";
        }

        if (!values.last_name) {
            errors.last_name = "Last name is required!";
        }

        if (!values.email_address) {
            errors.email_address = "Email is required!";
        } else if (!regex.test(values.email_address)) {
            errors.email_address = "This is not a valid email format!";
        }

        if (!values.phonecode) {
            errors.phonecode = "Phone code is required!";
        }

        if (!values.phone) {
            errors.phone = "Phone number is required!";
        }

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 8) {
            errors.password = "Password must be more than 8 characters";
        } else if (values.password.length > 10) {
            errors.password = "Password cannot exceed more than 10 characters";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Repeat Password is required";
        } else if (values.confirm_password.length < 8) {
            errors.confirm_password = "Repeat Password must be more than 8 characters";
        } else if (values.confirm_password.length > 10) {
            errors.confirm_password = "Repeat Password cannot exceed more than 10 characters";
        } else if (values.confirm_password != values.password) {
            errors.confirm_password = "Repeat Password cannot match the password";
        }

        if (moment(new Date()).format("YYYY/MM/DD") > moment(values.start_date).format("YYYY/MM/DD")) {
            errors.start_date = "Start Date not allow";
        }

        if (moment(new Date()).format("YYYY/MM/DD") > moment(values.end_date).format("YYYY/MM/DD")) {
            errors.end_date = "End Date not allow";
        }


        if (values.start_date > values.end_date) {
            errors.date = "Please enter end date greater then start date";
        }


        return errors;
    };

    const Resendotp = (e) => {
        setOTPInput({})
        setOtpMessage("")
        setIsSubmitAddFestival(true);
    }

    useEffect(() => {
        //Add Festival
        let Checkuser = false;
        if (Object.keys(formErrorsAddFestival).length === 0 && isSubmitAddFestival) {


            const Check_user = async () => {
                let che_api_data = {};
                che_api_data.email = formValuesAddFestival.email_address
                che_api_data.festival_name = formValuesAddFestival.festival_name
                che_api_data.phone = formValuesAddFestival.phone

                await axios.post('users/Check_user', che_api_data)
                    .then((result) => {
                        Checkuser = true;
                        // window.$('#registerverificationModal').modal('show');
                        // setIsSubmitAddFestival(false);
                        // setisDisabled(false);
                    }).catch((error) => {
                        console.log(error);
                        alert(error.response.data.message);
                        // const message = error.response.data.message;
                        // if (message === 'All ready mobile number register!') {
                        //     all_ready_resgister = true;
                        //     window.$('#registerverificationModal').modal('hide');
                        // }
                    })

                if (Checkuser) {
                    let api_data = {};
                    api_data.phonecode = formValuesAddFestival.phonecode
                    api_data.mobileno = formValuesAddFestival.phone
                    api_data.email = formValuesAddFestival.email_address

                    await axios.post('users/web-send-otp', api_data)
                        .then((result) => {
                            window.$('#registerverificationModal').modal('show');
                            setIsSubmitAddFestival(false);
                            setisDisabled(false);
                        }).catch((error) => {
                            const message = error.response.data.message;
                            if (message === 'All ready mobile number register!') {
                                // all_ready_resgister = true;
                                window.$('#registerverificationModal').modal('hide');
                            }
                        })
                }
            }
            Check_user();


        }


        if (SendOtp) {
            const VerifyOtp = async () => {
                let resgister = false;
                let api_data = {};
                api_data.mobileno = formValuesAddFestival.phone
                api_data.otpcode = otp.otp
                await axios.post('users/verify-otp', api_data)
                    .then((result) => {
                        console.log(result)
                        setSendOtp(false);
                        const message = result.data.message;
                        setOtpMessage(message);
                        setOtpSuccess(true);
                        setisDisabled(false);
                        resgister = true;
                    }).catch((error) => {
                        setSendOtp(false);
                        setisDisabled(false);
                        const message = error.response.data.message;
                        setOtpMessage(message);
                    })



                if (resgister) {
                    let register_data = formValuesAddFestival;
                    register_data.festival_role = 2;
                    register_data.register_type = 'email';
                    register_data.email = formValuesAddFestival.email_address;
                    await axios.post('festival-create-register', register_data)
                        .then((result) => {
                            const message = result.data.message;
                            if (result.data.status == false) {
                            } else {
                                const user_data = result.data.data;
                                localStorage.setItem('token', user_data.token);
                                localStorage.setItem('user_data', JSON.stringify(user_data));

                                window.location.href = '/festival';
                            }
                        }).catch((error) => {
                            //setRegisterError(error.response.data)
                        })
                }
            };
            VerifyOtp();
        }

        const CountryGet = async () => {
            await axios.get('commonapi/CountryGet')
                .then((result) => (
                    setCountryGet(result.data.data)
                )).catch((error) => {
                    console.log(error)
                })
        }
        CountryGet()


        //End Add Festival
    }, [formErrorsAddFestival, isSubmitAddFestival, isDisabled, SendOtp]);

    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());
    // const formatkg = (value) => { value + ' kg' }
    return (
        <>
            <div className="c-wrapper">
                <Header />
                <section className="c-festival-form-section">
                    <div className="container">
                        <center> <h1>FILL THE FORM TO GET ACCESS</h1> </center>
                        <form onSubmit={handleSubmitAddFestival}>
                            <div className="row c-festival-form">

                                <div className="col-sm-6 c-festival-form-left">
                                    <center> <h2>festival information</h2></center>

                                    <div className="c-form-field">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter festival name"
                                            name="festival_name"
                                            id="festival_name"
                                            maxLength="50"
                                            value={formValuesAddFestival.festival_name}
                                            onChange={handleChangeAddFestival} />
                                        {formErrorsAddFestival.festival_name != null &&
                                            <div className='error_text'>{formErrorsAddFestival.festival_name}</div>
                                        }
                                    </div>
                                    <div className="c-form-field c-festival-create-form">
                                        <select
                                            name='festival_position'
                                            className="form-control"
                                            onChange={handleChangeAddFestival}
                                            style={{ appearance: 'revert' }}
                                            value={formValuesAddFestival.festival_position}>
                                            <option value="" >Select Festival Position</option>
                                            <option value={'4'}>Festival Director</option>
                                            <option value={'5'}>Employee Manage</option>
                                        </select>
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your position for this festival"
                                            name="festival_position"
                                            id="festival_position"
                                            value={formValuesAddFestival.festival_position}
                                            onChange={handleChangeAddFestival} /> */}
                                        {formErrorsAddFestival.festival_position != null &&
                                            <div className='error_text'>{formErrorsAddFestival.festival_position}</div>
                                        }
                                    </div>
                                    <p>How many Fesival-goer do you have in your festival?</p>
                                    {/* <input type="range" min="100" max="350" /> */}
                                    <Slider
                                        min={50}
                                        max={100}
                                        tooltip={false}
                                        value={formValuesAddFestival.festival_count}
                                        handleLabel={formValuesAddFestival.festival_count}
                                        onChange={(value) => setFormValuesAddFestival({ ...formValuesAddFestival, 'festival_count': value })}
                                    />
                                    {formErrorsAddFestival.festival_count != null &&
                                        <div className='error_text'>{formErrorsAddFestival.festival_count}</div>
                                    }

                                    <p>When your festival start and End?</p>

                                    <form autocomplete="off" className="date-picker">
                                        <div className="flex-row d-flex justify-content-center">

                                            <div className="input-group input-daterange row">
                                                {/* <input type="text" id="start" placeholder="Start Date" />
                                            <span className="fa fa-calendar" id="fa-1"></span> */}

                                                <div className="default-datepicker">
                                                    <DatePicker
                                                        className="form-control text-left mr-2 col-md-6"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="Start date"
                                                        todayButton="Today"
                                                        selected={formValuesAddFestival.start_date}
                                                        onChange={(date) => setFormValuesAddFestival({ ...formValuesAddFestival, 'start_date': date })}
                                                        startDate={formValuesAddFestival.start_date}
                                                        endDate={formValuesAddFestival.end_date}
                                                        minDate={formValuesAddFestival.start_date}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                    />
                                                    {formErrorsAddFestival.start_date != null &&
                                                        <div className='error_text'>{formErrorsAddFestival.start_date}</div>
                                                    }
                                                </div>

                                                <div className='default-datepicker'>
                                                    <DatePicker
                                                        className="form-control text-left mr-2  col-md-6"
                                                        dateFormat="dd-MM-yyyy"
                                                        placeholderText="End date"
                                                        selected={formValuesAddFestival.end_date}
                                                        onChange={(date) => setFormValuesAddFestival({ ...formValuesAddFestival, 'end_date': date })}
                                                        selectsEnd
                                                        startDate={formValuesAddFestival.start_date}
                                                        endDate={formValuesAddFestival.end_date}
                                                        minDate={formValuesAddFestival.start_date}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                    />
                                                    {formErrorsAddFestival.end_date != null &&
                                                        <div className='error_text'>{formErrorsAddFestival.end_date}</div>
                                                    }
                                                    {formErrorsAddFestival.date != null && <div className='error_text'>{formErrorsAddFestival.date}</div>}
                                                </div>

                                                {/* <input type="text" id="end" placeholder="End Date" className="form-control text-left ml-2" />
                                            <span className="fa fa-calendar" id="fa-2"></span> */}
                                            </div>

                                        </div>
                                    </form>
                                    {/* <!-----
                                        <div className="c-form-field first-name">
                                            <input type="text" className="form-control" placeholder="Start date" name="first_name" id="first_name" />
                                        </div>
                                        <div className="c-form-field last-name">
                                            <input type="text" className="form-control" placeholder=" End date" name="first_name" id="first_name" />
                                        </div>
                                    ----> */}

                                </div>
                                <div className="col-sm-6 c-festival-form-right">
                                    <center> <h2>Personnal information</h2></center>
                                    <div className="c-form-field">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter your first name"
                                            name="first_name"
                                            id="first_name"
                                            maxLength="50"
                                            value={formValuesAddFestival.first_name}
                                            onChange={handleChangeAddFestival} />
                                        {formErrorsAddFestival.first_name != null &&
                                            <div className='error_text'>{formErrorsAddFestival.first_name}</div>
                                        }
                                    </div>
                                    <div className="c-form-field">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter your last name"
                                            name="last_name"
                                            id="last_name"
                                            maxLength="50"
                                            value={formValuesAddFestival.last_name}
                                            onChange={handleChangeAddFestival} />
                                        {formErrorsAddFestival.last_name != null &&
                                            <div className='error_text'>{formErrorsAddFestival.last_name}</div>
                                        }
                                    </div>
                                    <div className="c-form-field">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email Address"
                                            name="email_address"
                                            id="email_address"
                                            maxLength="50"
                                            value={formValuesAddFestival.email_address}
                                            onChange={handleChangeAddFestival} />
                                        {formErrorsAddFestival.email_address != null &&
                                            <div className='error_text'>{formErrorsAddFestival.email_address}</div>
                                        }
                                    </div>
                                    <div className="c-form-field">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            name="password"
                                            maxLength="50"
                                            value={formValuesAddFestival.password}
                                            onChange={handleChangeAddFestival}
                                        />
                                        {formErrorsAddFestival.password != null &&
                                            <div className='error_text'>{formErrorsAddFestival.password}</div>
                                        }
                                    </div>
                                    <div className="c-form-field">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Repeat password"
                                            name="confirm_password"
                                            maxLength="50"
                                            value={formValuesAddFestival.confirm_password}
                                            onChange={handleChangeAddFestival}
                                        />
                                        {formErrorsAddFestival.confirm_password != null &&
                                            <div className='error_text'>{formErrorsAddFestival.confirm_password}</div>
                                        }
                                    </div>
                                    <div className="c-form-field c-phone-field">
                                        <PhoneInput
                                            country='ch'
                                            enableSearch='true'
                                            onChange={handleOnChange}
                                            placeholder="Enter Phone Number"
                                        />
                                        {formErrorsAddFestival.phonecode != null &&
                                            <div className='error_text'>{formErrorsAddFestival.phonecode}</div>
                                        }
                                        {formErrorsAddFestival.phone != null &&
                                            <div className='error_text'>{formErrorsAddFestival.phone}</div>
                                        }
                                        {/* <select
                                            name='phonecode'
                                            id="phonecode"
                                            value={formValuesAddFestival.phonecode}
                                            onChange={handleChangeAddFestival}
                                        >
                                            {CountryGet.length > 0 &&
                                                CountryGet.map((res, index) => (
                                                    <option value={`+${res.phone_code}`}>{`+${res.phone_code}`}</option>
                                                ))
                                            }
                                        </select>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="phone"
                                            placeholder="Phone number"
                                            id="phone"
                                            value={formValuesAddFestival.phone}
                                            onChange={handleChangeAddFestival}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} />
                                        {formErrorsAddFestival.phone != null &&
                                            <div className='error_text'>{formErrorsAddFestival.phone}</div>
                                        } */}
                                    </div>
                                    <center><p>We ask this information only to contact you.
                                        We will never sell any information.</p> </center>
                                </div>
                                <div className="col-sm-12">
                                    {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#registerverificationModal" */}
                                    <button className="c-festival-btn" type='submit' >
                                        <span>SEND FORM TO CPH4 TEAM</span>
                                    </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </section>

                {/* <!-- Verification Modal --> */}
                <div show='true' className="modal fade c-login-modal" id="registerverificationModal" tabindex="-1" aria-labelledby="registerverificationModal" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body c-verification-modal">
                                <div className="c-verification-head">
                                    <h3>Verification code</h3>
                                    <p>Please enter the verification code at 6 digits sent to the number:<br /> {formValuesAddFestival.phonecode} {formValuesAddFestival.phone}</p>
                                </div>


                                <div className="c-verification-content">
                                    <div className="c-verification-inputs">

                                        <OtpInput
                                            value={otp.otp}
                                            onChange={setOTP}
                                            separator={<span>{ }</span>}
                                            inputStyle="inputStyle"
                                            numInputs={4}
                                            errorStyle="error"
                                            isInputNum={4}
                                            isInputSecure={false}
                                            shouldAutoFocus
                                            isDisabled={isDisabled} />
                                        {/*<input type="text" className="form-control" maxlength="1" />
                                        <input type="text" className="form-control" maxlength="1" />
                                        <input type="text" className="form-control" maxlength="1" />
                                        <input type="text" className="form-control" maxlength="1" />
                                        <input type="text" className="form-control" maxlength="1" />
                                        <input type="text" className="form-control" maxlength="1" />*/}
                                    </div>
                                    {OtpSuccess != false &&
                                        <div className='success_text'>{OtpMessage}</div>
                                    }
                                    {OtpSuccess != true &&
                                        <div className='error_text'>{OtpMessage}</div>
                                    }
                                    <div className="c-verification-desc">
                                        <p>Did not receive code ?
                                            <a href="#" onClick={Resendotp}> Send again</a>
                                        </p>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
                <Outlet />
            </div>
        </>
    );

}

export default Signup
