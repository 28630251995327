import React, { useEffect, useState, useRef } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios';

import { Routes, Route, Outlet, Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import LoginHeader from '../component/loginHeader';
import { findAllInRenderedTree } from 'react-dom/test-utils';
import DatePicker from 'react-multi-date-picker';
import LotteryBidList from '../component/LotteryBidList';

function Lottery_build() {
    let { id } = useParams()

    let location = useLocation();

    // let festival_id = location.state.id

    const [standUser, setStandUser] = useState([{}])
    console.log(standUser)
    const initialValues = { name: "", email: "", festival_role: "", stand_id: id };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setisLoading] = useState(true);

    const [ErrorMessage, setErrorMessage] = useState('');

    const handleChangeAddStandUser = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmitAddStandUser = (e) => {
        e.preventDefault();
        setFormErrors(validateStand(formValues));
        setIsSubmit(true);
    }

    const validateStand = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.festival_role) {
            errors.festival_role = "Festival role is required!";
        }

        if (!values.stand_id) {
            errors.stand_id = "Festival name is required!";
        }

        if (!values.name) {
            errors.name = "Name is required!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        return errors;
    };


    useEffect(() => {
        const StandUserList = async () => {
            let apidata = {
                lottery_id: id
            }
            await axios.post('lottery/total_lottery_bid', apidata)
                .then((result) => {
                    let getData = result.data.data
                    // console.log(getData);
                    setStandUser(getData);
                    setisLoading(false)
                }).catch((error) => {
                    console.log(error);
                })
        };
        StandUserList();
    }, [isSubmit]);



    return (
        <>
            <Header />
            <section className="c-dashboard-section c-stand-section stand_list">


                {/* <LoginHeader Stand={false} standData={[]} /> */}
                <div className="tab-content">
                    <div className="container">
                        {/* <div className='row'>
                            <div className='col-2'>
                                <div className="custome">
                                    <button>
                                        <a href={`/${festival_id}/stand`}>Back</a>
                                    </button>
                                </div>
                            </div>
                        </div> */}

                        <div className='row'>
                            <div className='col-sm-9'>
                                <h2>Lottery Bid</h2>
                            </div>
                            <div className="custome col-sm-3">

                                <Link to="/lottery">Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="accordion-content">
                            {standUser.length === 0 && <h3 className='text-center'>No data found</h3>}
                            {isLoading == false && standUser && standUser.length > 0 &&
                                standUser.map((data, index) => (
                                    <LotteryBidList
                                        name="stand"
                                        id={index + 1}
                                        key={index}
                                        content={data}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Login Modal --> */}


            <Footer />
            <Outlet />
        </>
    );
}

export default Lottery_build
