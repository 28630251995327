
import React from "react";
import Main from "./pages"
import Login from "./pages/login"
import Dashboard from "./pages/dashboard"
import Signup from "./pages/signup"
import TermsConditions from "./cms_page/TermsConditions"
import AmlKyc from "./cms_page/AmlKyc"
import Confidentility from "./cms_page/Confidentility"
import Home from "./pages/home"
import FesivalList from "./pages/festivalList"
import Stand from "./pages/Stand"
import Settings from "./pages/Settings"
import TeamManagement from "./pages/TeamManagement"
import Profile from "./pages/profile"
import PageNotFound from "./pages/page_not_found"
import StandUser from "./pages/StandUser"
import ForgotPassword from "./pages/forgotpassword"
import Tokenizations from "./pages/tokenization"
import Lotterey from "./pages/lottery"
import Product from "./pages/product"
import Lottery_build from "./pages/lottery_build"

const routes = [
    {
        path: "*",
        pathFalse: false,
        loginRoute: false,
        component: <PageNotFound />
    },
    {
        path: "/",
        pathFalse: true,
        loginRoute: false,
        component: <Main />
    },
    {
        path: "/login",
        pathFalse: true,
        loginRoute: false,
        component: <Login />
    },
    {
        path: "/signup",
        pathFalse: true,
        loginRoute: false,
        component: <Signup />
    },
    {
        path: "/director",
        pathFalse: true,
        loginRoute: false,
        component: <Home />
    },
    {
        path: "/terms-conditions",
        pathFalse: true,
        loginRoute: false,
        component: <TermsConditions />
    },
    {
        path: "/aml-kyc",
        pathFalse: true,
        loginRoute: false,
        component: <AmlKyc />
    },
    {
        path: "/confidentility",
        pathFalse: true,
        loginRoute: false,
        component: <Confidentility />
    },
    {
        path: "/home",
        pathFalse: true,
        loginRoute: false,
        component: <Home />
    },
    {
        path: "/reset-password",
        pathFalse: true,
        loginRoute: false,
        component: <ForgotPassword />
    },
    {
        path: "/:id/dashboard",
        pathFalse: true,
        loginRoute: false,
        component: <Dashboard />
    },
    {
        path: "/festival",
        pathFalse: true,
        loginRoute: false,
        component: <FesivalList />
    },
    {
        path: "/:id/stand",
        pathFalse: true,
        loginRoute: false,
        component: <Stand />
    },
    {
        path: "/:id/settings",
        pathFalse: true,
        loginRoute: false,
        component: <Settings />
    },
    {
        path: "/:id/team-management",
        pathFalse: true,
        loginRoute: false,
        component: <TeamManagement />
    },
    {
        path: "/profile",
        pathFalse: true,
        loginRoute: false,
        component: <Profile />
    },
    {
        path: "/:id/StandUser",
        pathFalse: true,
        loginRoute: false,
        component: <StandUser />
    },
    {
        path: "/tokenization",
        pathFalse: true,
        loginRoute: false,
        component: <Tokenizations />
    },
    {
        path: "/lottery",
        pathFalse: true,
        loginRoute: false,
        component: <Lotterey />
    },
    {
        path: "/:id/lottery_build",
        pathFalse: true,
        loginRoute: false,
        component: <Lottery_build />
    },
    {
        path: "/:id/product",
        pathFalse: true,
        loginRoute: false,
        component: <Product />
    }
];

export default routes;
