import React, { useEffect, useState, useRef } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios';

import { Routes, Route, Outlet, Link, useLocation, useParams } from "react-router-dom";
import LoginHeader from '../component/loginHeader';
import StandAccordion from '../component/StandAccordion';
import { findAllInRenderedTree } from 'react-dom/test-utils';
import DatePicker from 'react-multi-date-picker';


const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
const imagesize = 2;

function Stand() {
    const contentEl = useRef();

    const location = useLocation();

    let { id } = useParams()

    const sessiontoken = localStorage.getItem('token');
    const UserData = JSON.parse(localStorage.getItem('user_data'));
    if (sessiontoken === null) {
        window.location.href = "/";
    }
    const [startDate, setStartDate] = useState(new Date());

    const initialValues = { festival_id: id, stand_name: "", upload_logo: "", stand_commission: "", user_id: UserData.id };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setisLoading] = useState(true);

    const [Message, setMessage] = useState("");

    const [query, setQuery] = useState("")

    //Stand Create
    const handleChangeStand = (e) => {
        if (e.target.files != undefined) {
            const { name, files } = e.target;
            if (!files[0].type.match(imageMimeType)) {
                const errors = {};
                errors.upload_logo = "This file format is not supported";
                setFormErrors(errors);
                setFormValues({ ...formValues, [name]: "" });
            } else if ((files[0].size / 1024 / 1024) > imagesize) {
                const errors = {};
                errors.upload_logo = "Max upload file size is 2048";
                setFormErrors(errors);
                setFormValues({ ...formValues, [name]: "" });
            } else {
                setFormValues({ ...formValues, [name]: files[0] });
                const errors = {};
                errors.upload_logo = "";
                setFormErrors(errors);
            }
        } else {
            const { name, value } = e.target;
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const handleStandSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validateStand(formValues));
        setIsSubmit(true);
    };


    const validateStand = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.festival_id) {
            errors.festival_id = "Festival name is required!";
        }

        if (!values.stand_name) {
            errors.stand_name = "Stand Name is required!";
        }

        if (!values.upload_logo) {
            errors.upload_logo = "Upload logo is required!";
        }

        return errors;
    };



    const [StandData, setStandData] = useState([]);
    const [FestivalList, setFestivalList] = useState([{}]);

    const [clicked, setClicked] = useState("0");

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };


    //Status Change event
    const [StandId, SetStandId] = useState('');
    const StatusChange = (id) => {
        SetStandId(id);
    }

    useEffect(() => {
        const StandList = async () => {
            let apidata = {}
            apidata.festival_id = id
            await axios.post('stand/FestivalStandList', apidata)
                .then((result) => {
                    let getData = result.data.data
                    console.log("🚀 ~ file: Stand.js:120 ~ .then ~ getData", getData)
                    setStandData(getData);
                    setisLoading(false)
                }).catch((error) => {
                    console.log(error);
                })
        };
        StandList();

        const MemberFestivalList = async () => {
            let apidata = {}
            if (UserData.parent_user_id != null) {
                apidata.member_id = UserData.parent_user_id
            } else {
                apidata.member_id = UserData.id
            }

            await axios.post('festival/memberFestivalList', apidata)
                .then((result) => {
                    let getData = result.data.data
                    setFestivalList(getData);
                    // // setMessage('')

                }).catch((error) => {
                    console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        MemberFestivalList();

        if (Object.keys(formErrors).length === 0 && isSubmit) {
            const StandCreate = async () => {
                let StandData = new FormData();
                StandData.append("festival_id", id)
                StandData.append("stand_name", formValues.stand_name)
                StandData.append("upload_logo", formValues.upload_logo)
                StandData.append("user_id", formValues.user_id)
                StandData.append("stand_commission", formValues.stand_commission)
                await axios.post('stand/CreatStand', StandData)
                    .then((result) => {
                        setMessage(result.data.message);
                        StandList();
                        setIsSubmit(false);
                        setFormValues(initialValues)
                        window.location.reload()
                    }).catch((error) => {
                        console.log(error);
                        // setloggedinError(error.response.data);
                    })
            }
            StandCreate();
        }

        if (StandId != '') {
            const StandStatus = async () => {
                let stand_id = {
                    "stand_id": StandId
                }
                await axios.post('stand/FestivalStandListActive', stand_id)
                    .then((result) => {
                        setMessage(result.data.message);
                        StandList();
                        setIsSubmit(false);
                        setFormValues(initialValues)
                        SetStandId('');
                        // window.location.reload()
                    }).catch((error) => {
                        console.log(error);
                        // setloggedinError(error.response.data);
                    })
            }
            StandStatus();
        }

    }, [formErrors, StandId]);



    return (
        <>
            <Header />
            <div>Stand</div>

            <section className="c-dashboard-section c-stand-section stand_list">
                <LoginHeader Stand={true} standData={StandData} />
                <div className="tab-content">
                    {location.pathname == `/${id}/stand` &&
                        <div className="tab-pane" id="my-festival" role="tabpanel" aria-labelledby="my-festival-tab">
                            <h1>
                                <div className="container">
                                    <div className='stand-search'>
                                        <input type='text' className='col-md-12' placeholder='Search a stand' onChange={event => setQuery(event.target.value)} />
                                    </div>
                                </div>
                            </h1>
                        </div>
                    }

                    {Message != '' &&
                        <h3><div className='success_text' style={{ textAlign: "center" }}>{Message}</div></h3>
                    }
                    <div className="container-fluid">
                        <div className="accordion-content">
                            {isLoading == false && StandData.length > 0 &&
                                StandData.filter(data => {
                                    if (query === '') {
                                        return data;
                                    } else if (data.name.toLowerCase().includes(query.toLowerCase())) {
                                        return data;
                                    }
                                }).map((data, index) => (
                                    <StandAccordion
                                        name="stand"
                                        id={index + 1}
                                        onToggle={() => handleToggle(index)}
                                        onChange={() => StatusChange(data.id)}
                                        active={clicked === index}
                                        key={index}
                                        content={data} />
                                ))
                            }

                            {StandData.length === 0 &&
                                <h3 className='text-center'>No data found</h3>
                            }
                        </div>

                    </div>
                </div>
            </section>

            {/* <!-- Login Modal --> */}
            <div className="modal fade c-login-modal" id="CreateStand" tabindex="-1" aria-labelledby="CreateStand" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {/* <div className="c-modal-logo">
                                <h2></h2>
                            </div> */}
                            <div className="c-festival-create-form">
                                <div className="col-sm-12">
                                    <center> <h2>Create Stand</h2></center>
                                    <form onSubmit={handleStandSubmit}>
                                        <div className="c-form-field">

                                            <select
                                                disabled="disabled"
                                                className='form-control'
                                                name="festival_id"
                                                id="festival_id"
                                                onChange={handleChangeStand}
                                                value={formValues.festival_id}
                                            >
                                                <option value="" >Please Select Festival</option>
                                                {FestivalList.length > 0 &&
                                                    FestivalList.map((data, index) => (
                                                        <option value={data.id}>{data.name}</option>
                                                    ))
                                                }

                                            </select>
                                            {formErrors.festival_id != null &&
                                                <div className='error_text'>{formErrors.festival_id}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Stand Name"
                                                name="stand_name"
                                                id="stand_name"
                                                maxLength="50"
                                                onChange={handleChangeStand}
                                                value={formValues.stand_name} />
                                            {formErrors.stand_name != null &&
                                                <div className='error_text'>{formErrors.stand_name}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Stand Commission"
                                                name="stand_commission"
                                                id="stand_commission"
                                                maxLength="50"
                                                onChange={handleChangeStand}
                                                value={formValues.stand_commission}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} />
                                            {formErrors.stand_commission != null &&
                                                <div className='error_text'>{formErrors.stand_commission}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input
                                                type="file"
                                                className="form-control"
                                                placeholder="Enter Upload logo"
                                                name="upload_logo"
                                                id="upload_logo"
                                                accept=".png,.jpg,.jpeg,.webp"
                                                onChange={handleChangeStand} />
                                            {formErrors.upload_logo != null &&
                                                <div className='error_text'>{formErrors.upload_logo}</div>
                                            }
                                        </div>

                                        <div className="custome">
                                            {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                            <button className="btn" type="submit" >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <Outlet />
        </>
    );
}

export default Stand
