import React, { useState, useEffect } from 'react'
import axios from '../axios';
import OtpInput from 'react-otp-input';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom'

// import axios from 'axios';

function Footer() {
    //login form
    const local_language_id = localStorage.getItem('language_id');

    const initialValues = { email: "", password: "", logintype: "simple" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [loggedinData, setloggedinData] = useState({});
    const [loggedinError, setloggedinError] = useState({});

    const [otp, setOTPInput] = useState("");
    const [isDisabled, setisDisabled] = useState(false);
    const [OtpMessage, setOtpMessage] = useState("");
    const [OtpSuccess, setOtpSuccess] = useState(false);

    //Register form
    const initialValuesRegister = { role: "1", register_type: "email", first_name: "", last_name: "", email: "", birth_date: "", phonecode: "+91", phone: "", password: "", confirm_password: "", gender: "", language_id: "2", device_token: "web", device_type: "web/xyz", profile: "" };
    const [formValuesRegister, setFormValuesRegister] = useState(initialValuesRegister);
    const [formErrorsRegister, setFormErrorsRegister] = useState({});
    const [isSubmitRegister, setIsSubmitRegister] = useState(false);
    const [RegisterError, setRegisterError] = useState({});

    const [LanguageData, setLanguageData] = useState([{}]);


    //login form
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    //Otp
    const setOTP = (otp) => {
        setOTPInput({ otp })
        const otp_length = otp.length

        if (otp_length == 4) {
            setisDisabled(true);
        }
    }

    //Register form
    const handleChangeRegister = (e) => {
        if (e.target.files != undefined) {
            const { name, files } = e.target;
            setFormValuesRegister({ ...formValuesRegister, [name]: files[0] });
        } else {
            const { name, value } = e.target;
            setFormValuesRegister({ ...formValuesRegister, [name]: value });
        }
    };

    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        setFormErrorsRegister(validateRegister(formValuesRegister));
        setIsSubmitRegister(true);
    };

    const [LanguageId, setLanguageId] = useState();

    const googleTranslateElementInit = () => {
        // setCookie('google_translate_element', '/en/pt',1);
        new window.google.translate.TranslateElement(
            {
                includedLanguages: 'fr,en',
                // pageLanguage: "en",  
                autoDisplay: false
            },
            "google_translate_element"
        );
    };

    const language_set = (id) => {
        setLanguageId(id);
        console.log(id)
    }

    const save_language = (e) => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
        document.cookie = "googtrans=/auto/" + LanguageId;
        console.log(document.cookie);
        localStorage.setItem('language', "googtrans=/auto/" + LanguageId);
        localStorage.setItem('language_id', LanguageId);
        window.$('#languageSelection').modal('hide');
        window.location.reload()
    }


    const Resendotp = (e) => {
        setIsSubmit(true);
    }


    useEffect(() => {


        //Login
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            setIsSubmit(false);
            const Login = async () => {
                formValues.language_id= 2
                await axios.post('users/login', formValues)
                    .then((result) => {
                        const message = result.data.message;
                        if (result.data.status == false) {
                            // const notify = () => toast(message);
                            // notify();
                        } else {
                            setloggedinError({});
                            const response = result.data.data;
                            window.$('#loginModal').modal('hide');
                            window.$('#verificationModal').modal('show');
                            setloggedinData(response);
                            // const user_data = result.data.data;
                            // localStorage.setItem('token', user_data.access_token);
                            // localStorage.setItem('user_data', JSON.stringify(user_data.user));
                            // localStorage.setItem('message', message);
                            // navigate('/festival');
                        }
                    }).catch((error) => {
                        setloggedinError(error.response.data);
                    })
            };
            Login();
        }
        //End Login

        //Register
        if (Object.keys(formErrorsRegister).length === 0 && isSubmitRegister) {
            const Register = async () => {
                let Register = new FormData();
                Register.append("role", formValuesRegister.role)
                Register.append("register_type", formValuesRegister.register_type)
                Register.append("first_name", formValuesRegister.first_name)
                Register.append("people_name", formValuesRegister.last_name)
                Register.append("email", formValuesRegister.email)
                Register.append("gender", formValuesRegister.gender)
                Register.append("password", formValuesRegister.password)
                Register.append("phone", formValuesRegister.phone)
                Register.append("phonecode", formValuesRegister.phonecode)
                Register.append("device_token", formValuesRegister.device_token)
                Register.append("device_type", formValuesRegister.device_type)
                Register.append("birth_date", formValuesRegister.birth_date)
                Register.append("language_id", formValuesRegister.language_id)
                Register.append("profile-pic", formValuesRegister.profile)

                await axios.post('register', Register)
                    .then((result) => {
                        // console.log(result.data);
                        // debugger;
                        const message = result.data.message;
                        if (result.data.status == false) {
                            // const notify = () => toast(message);
                            // notify();
                        } else {
                            setRegisterError({})
                            const user_data = result.data.data;
                            localStorage.setItem('token', user_data.token);
                            localStorage.setItem('user_data', JSON.stringify(user_data));

                            window.location.href = '/festival';
                        }
                    }).catch((error) => {
                        setRegisterError(error.response.data)
                    })
            };
            Register();
        }
        //End Register

        //Login otp
        if (isDisabled) {
            const Login = async () => {
                const formData = new FormData()
                formData.append('otp', otp.otp)
                formData.append('user_id', loggedinData.id)
                formData.append('language_id', 2)
                await axios.post('users/loginVerify', formData)
                    .then((result) => {
                        const message = result.data.message;
                        setOtpMessage(message);
                        setOtpSuccess(true);
                        const user_data = result.data.data;
                        localStorage.setItem('token', loggedinData.token);
                        localStorage.setItem('user_data', JSON.stringify(loggedinData));

                        window.location.href = '/festival'
                    }).catch((error) => {
                        const message = error.response.data.message;
                        setOtpMessage(message);
                        setisDisabled(false);
                    })
            };
            Login();
        }
        //End Login otp

        //get languages
        const getlanguages = async () => {
            await axios.get('commonapi/language_master')
                .then((result) => {
                    const obj = result.data.data;
                    // console.log(obj)
                    setLanguageData(obj);
                    const message = result.data.message;
                }).catch((error) => {

                })
        };
        getlanguages();
        //get languages
    }, [formErrors, formErrorsRegister, isDisabled, isSubmit]);
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 6) {
            errors.password = "Password must be more than 6 characters";
        } else if (values.password.length > 10) {
            errors.password = "Password cannot exceed more than 10 characters";
        }

        return errors;
    };
    //login form

    // //Register form




    // useEffect(() => {
    //     // console.log(formValuesRegister);
    //     
    // }, [formErrorsRegister]);
    const validateRegister = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.first_name) {
            errors.first_name = "First name is required!";
        }

        if (!values.last_name) {
            errors.last_name = "Last name is required!";
        }


        if (!values.birth_date) {
            errors.birth_date = "Birth Date is required!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        if (!values.phone) {
            errors.phone = "Phone number is required!";
        }

        if (!values.profile) {
            errors.profile = "Profile is required!";
        }

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 6) {
            errors.password = "Password must be more than 6 characters";
        } else if (values.password.length > 10) {
            errors.password = "Password cannot exceed more than 10 characters";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Repeat Password is required";
        } else if (values.confirm_password.length < 6) {
            errors.confirm_password = "Repeat Password must be more than 6 characters";
        } else if (values.confirm_password.length > 10) {
            errors.confirm_password = "Repeat Password cannot exceed more than 10 characters";
        } else if (values.confirm_password != values.password) {
            errors.confirm_password = "Repeat Password cannot match the password";
        }

        return errors;
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };


    //Register form
    return (
        <>
            <footer className="c-footer">
                <div className="c-upper-footer">
                    <div className="container">
                        <a href="#" className="c-footer-logo">
                            <img src="/asset/images/logo-large.png" alt="" />
                        </a>
                        <div className="c-footer-content">
                            <p>© 2022 CPH4 All rights reserved.</p>
                            <p>
                                The CPH4 designation, the CPH4 logo are protected as trademarks and/or copyrights of CPH4. Any use of these trademarks for<br /> commercial purposes is prohibited. Use of the CPH4.crypto platform implies that you accept the Terms and Conditions and the Privacy
                                Provisions.
                            </p>
                        </div>
                        <div className="c-footer-content2">
                            <ul className="c-social-list">
                                <li>
                                    <a target='_blank' href="https://t.me/cph4crypto">
                                        <i className="fab fa-telegram-plane"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target='_blank' href="https://discord.com/invite/e8GNvSrQ">
                                        <i className="discord-icon">
                                            <img src="/asset/images/discord-icon.png" alt="" /></i>
                                    </a>
                                </li>
                                <li>
                                    <a target='_blank' href="https://twitter.com/CPH4crypto">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target='_blank' href="https://www.linkedin.com/company/cph4crypto/">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target='_blank' href="https://www.facebook.com/CPH4Crypto">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target='_blank' href="https://www.instagram.com/cph4.ch/">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                            <p className="mb-0">A company based in Switzerland - Registration:
                                <span className="c-text-pink"> CHE-282.341.230</span>
                                <span className="c-text-blue">Contact:<a className="c-text-blue-a" href="mailto:info@cph4.ch"> info@cph4.ch </a></span>
                            </p>

                        </div>
                    </div>
                </div>
                <div className="c-lower-footer">
                    <div className="container">
                        <ul>
                            <li onClick={scrollToTop}>
                                <Link to="/terms-conditions">Terms & Conditions</Link>
                            </li>
                            <li onClick={scrollToTop}>
                                <Link to="/aml-kyc">AML & KYC </Link>
                            </li>
                            <li onClick={scrollToTop}>
                                <Link to="/confidentility">Confidentility </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>

            {/* <!-- Return to Top --> */}
            <a onClick={scrollToTop} className="c-return-to-top">
                <i className="fas fa-chevron-up "></i>
            </a>

            {/* <!-- Register Modal --> */}
            <div className="modal fade c-login-modal" id="registerModal" tabIndex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="c-modal-logo">
                                <div className="c-modal-form">
                                    {Object.keys(RegisterError).length > 0 &&
                                        <div className='error_text' style={{ textAlign: 'center' }}>{RegisterError.message}</div>
                                    }
                                    <form onSubmit={handleRegisterSubmit}>
                                        <div className="c-form-field">
                                            <input type="text"
                                                className="form-control"
                                                placeholder="First Name"
                                                name="first_name"
                                                value={formValuesRegister.first_name}
                                                onChange={handleChangeRegister} />
                                            {formErrorsRegister.first_name != null &&
                                                <div className='error_text'>{formErrorsRegister.first_name}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Last Name"
                                                name="last_name"
                                                value={formValuesRegister.last_name}
                                                onChange={handleChangeRegister} />
                                            {formErrorsRegister.last_name != null &&
                                                <div className='error_text'>{formErrorsRegister.last_name}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Email"
                                                name="email"
                                                value={formValuesRegister.email}
                                                onChange={handleChangeRegister} />
                                            {formErrorsRegister.email != null &&
                                                <div className='error_text'>{formErrorsRegister.email}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <input type="date"
                                                className="form-control"
                                                placeholder="Birth date"
                                                name="birth_date"
                                                value={formValuesRegister.birth_date}
                                                onChange={handleChangeRegister} />
                                            {formErrorsRegister.birth_date != null &&
                                                <div className='error_text'>{formErrorsRegister.birth_date}</div>
                                            }
                                        </div>

                                        <div className="c-form-field">
                                            <select
                                                name="gender"
                                                className="form-control"
                                                onChange={handleChangeRegister}
                                                style={{ backgroundImage: 'none' }}
                                                value={formErrorsRegister.gender}
                                            >
                                                <option value="male">male</option>
                                                <option value="female">female</option>
                                            </select>
                                            {formErrorsRegister.gender != null &&
                                                <div className='error_text'>{formErrorsRegister.gender}</div>
                                            }
                                        </div>


                                        <div className="c-form-field">
                                            <input
                                                type="file"
                                                className="form-control"
                                                placeholder="Profile Image"
                                                name="profile"
                                                onChange={handleChangeRegister} />
                                            {formErrorsRegister.profile != null &&
                                                <div className='error_text'>{formErrorsRegister.profile}</div>
                                            }
                                        </div>

                                        <div className="c-form-field c-phone-item">
                                            {/* <!-- <div className="c-form-field">
                                                <label htmlFor="">+41</label>
                                            </div>
                                            <div className="c-form-field">
                                                <input type="text" className="form-control" placeholder="Phone">
                                            </div> --> */}
                                            <input type="tel" className="form-control c-phone-number" placeholder="Phone number" name="phone"
                                                value={formValuesRegister.phone}
                                                onChange={handleChangeRegister} />
                                            {formErrorsRegister.phone != null &&
                                                <div className='error_text'>{formErrorsRegister.phone}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input type="password" className="form-control" placeholder="Password" name="password"
                                                value={formValuesRegister.password}
                                                onChange={handleChangeRegister} />
                                            {formErrorsRegister.password != null &&
                                                <div className='error_text'>{formErrorsRegister.password}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input type="password" className="form-control" placeholder="Repeat password" name="confirm_password"
                                                value={formValuesRegister.confirm_password}
                                                onChange={handleChangeRegister} />
                                            {formErrorsRegister.confirm_password != null &&
                                                <div className='error_text'>{formErrorsRegister.confirm_password}</div>
                                            }
                                        </div>
                                        <div className="c-form-button">
                                            {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                            <button className="btn" type="submit" >
                                                <span>INSCRIPTION</span>
                                            </button>
                                        </div>
                                    </form>
                                    <div className="text-center">
                                        <a href="javascript:;" className="c-form-already-link" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#loginModal">I already have an account</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Login Modal --> */}
            <div className="modal fade c-login-modal" id="loginModal" tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="c-modal-logo">
                                <img src="/asset/images/logo.png" alt="" />
                            </div>
                            <div className="c-modal-form">
                                <form onSubmit={handleSubmit}>
                                    {Object.keys(loggedinError).length > 0 &&
                                        <div className='error_text' style={{ textAlign: 'center' }}>{loggedinError.message}</div>
                                    }
                                    <div className="c-form-field">

                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            name="email"
                                            value={formValues.email}
                                            onChange={handleChange} />
                                        {formErrors.email != null &&
                                            <div className='error_text'>{formErrors.email}</div>
                                        }
                                    </div>
                                    <div className="c-form-field">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            name="password"
                                            value={formValues.password}
                                            onChange={handleChange} />
                                        {formErrors.password != null &&
                                            <div className='error_text'>{formErrors.password}</div>
                                        }
                                    </div>

                                    <div className="c-form-button">
                                        {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                        <button className="btn" type="submit" >
                                            <span>Login</span>
                                        </button>
                                    </div>
                                </form>
                                {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#registerModal" */}
                                <div className="text-center">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/signup';
                                    }} className="c-form-already-link" >I don't have an account</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Verification Modal --> */}
            <div show='true' className="modal fade c-login-modal" id="verificationModal" tabIndex="-1" aria-labelledby="verificationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body c-verification-modal">
                            <div className="c-verification-head">
                                <h3>Verification code</h3>
                                <p>Please enter the verification code at 6 digits sent to the number:<br />
                                    {Object.keys(loggedinData).length > 0 &&
                                        `${loggedinData.phonecode} ${loggedinData.phone}`
                                    }
                                </p>
                            </div>


                            <div className="c-verification-content">
                                <div className="c-verification-inputs">

                                    <OtpInput
                                        value={otp.otp}
                                        onChange={setOTP}
                                        separator={<span>{ }</span>}
                                        inputStyle="inputStyle"
                                        numInputs={4}
                                        errorStyle="error"
                                        isInputNum={4}
                                        isInputSecure={false}
                                        shouldAutoFocus
                                        isDisabled={isDisabled} />



                                    {/*<input type="text" className="form-control" maxlength="1" />
                                    <input type="text" className="form-control" maxlength="1" />
                                    <input type="text" className="form-control" maxlength="1" />
                                    <input type="text" className="form-control" maxlength="1" />
                                    <input type="text" className="form-control" maxlength="1" />
                                    <input type="text" className="form-control" maxlength="1" />*/}
                                </div>
                                {OtpSuccess != false &&
                                    <div className='success_text'>{OtpMessage}</div>
                                }
                                {OtpSuccess != true &&
                                    <div className='error_text'>{OtpMessage}</div>
                                }
                                <div className="c-verification-desc">
                                    <p>Did not receive code ?
                                        <a href="#" onClick={Resendotp}> Send again</a>
                                    </p>


                                </div>
                                {/* <!-- <a href="#" className="c-form-already-link">Change phone number</a>--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Language Selection Modal --> */}
            <div className="modal fade c-login-modal c-language-modal" id="languageSelection" tabIndex="-1" aria-labelledby="languageSelectionLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body c-verification-modal">
                            <div className="c-verification-head">
                                <h3>Select your language</h3>
                            </div>

                            <div className="c-language-list">
                                {LanguageData.length > 0 &&
                                    LanguageData.map((language, index) => (
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor={`flexRadioDefault_${index}`}>
                                                <div className="c-language-image">
                                                    <img src={language.language_icon} alt="" />
                                                </div>
                                                <span>{language.language_name}</span>
                                            </label>
                                            <input className="form-check-input" onChange={(e) => language_set(language.language_code)} checked={(language.language_code === local_language_id) || (LanguageId === language.language_code) ? true : ''} name="language_id" type="radio" id={`flexRadioDefault_${index}`} />
                                        </div>
                                    ))
                                    // defaultChecked={language.id === 1}

                                }
                            </div>

                            <div className="c-language-description">
                                <p>We are using Google Translate for the translation of our ecosystem. So it is not perfect! Be kind we us please. </p>
                            </div>

                            <div className="c-language-button">
                                <button className="btn c-common-btn" onClick={save_language}><span>Save</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer