import React from 'react'
import { Link } from 'react-router-dom';

function login() {
    return (
        <>
            <div class="user-login">
                <div class="row d-flex align-items-lg-center align-items-md-center align-items-sm-center align-items-start">
                    <div class="col-lg-7 col-md-7 col-sm-6 col-12">
                        <img src="/asset/images/login-banner.png" class="login-banner w-100" />
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-6 col-12">
                        <div class="login-form">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <img src="/asset/images/logo.png" />
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center form-input">
                                <div class="col-lg-7 col-md-7 col-sm-12 col-12">
                                    <div class="border-bottom pb-4">
                                        <div class="form-group">
                                            <label>Username / Email Address</label>
                                            <input type="text" name="" maxLength="50" class="form-control" placeholder="Enter Username" />
                                        </div>
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="password" maxLength="50" name="" class="form-control" placeholder="Enter Password" />
                                        </div>
                                        <div class="remember d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-center mt-lg-4 mt-md-4 mt-sm-3 mt-3">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                                    <label class="custom-control-label" for="customCheck1">Remember me</label>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-centers">
                                            <Link class="btn btn-blue w-auto" to="/">Login</Link>
                                            {/* <button class="btn btn-blue w-auto">Login</button> */}
                                        </div>
                                        <div class="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-centers">
                                            <a href="#" class="forgot-link">Forgotten my password</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default login