import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import axios from '../axios';

const Prodcut_token = ({ content, active, onToggle, name, onClick, onChange }) => {

    const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
    const imagesize = 2;
    const Accname = name;
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const [Message, setMessage] = useState("");
    const initialValuesEditFestival = { product_name: '', about_ticket: '', file: '' };
    // const [formValuesAddFestival, setFormValuesAddFestival] = useState(initialValuesEditFestival);

    const [checked, setChecked] = useState(false);
    const [disable, setDisable] = useState(true);
    const [formValuesEditProduct, setFormValuesEditProduct] = useState(initialValuesEditFestival);
    // console.log(formValuesEditProduct)
    const [formErrorsEditProduct, setFormErrorsEditProduct] = useState({});
    const [isSubmitEditProduct, setIsSubmitEditProduct] = useState(false);
    const [formErrorsAddFestival, setFormErrorsAddFestival] = useState({});
    const [isSubmitAddFestival, setIsSubmitAddFestival] = useState(false);

    const [deleteproduct, setIsDeleteProduct] = useState(null);
    const [editProduct, setIsEditProduct] = useState(null);
    const ids = editProduct
    
    const [inputList, setInputList] = useState([{ name: '', content: '', checkbox_status: '', id: '' }]);
    const [inputListContent, setInputListcontent] = useState([]);
    const [inputListName, setInputListname] = useState([]);
    const [inputListCheckbox, setInputListcheckbox] = useState([]);
    const [inputListId, setInputListid] = useState([]);
   
    // handle input change
    // const handleInputChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const list = [...inputList];
    //     list[index][name] = value;
    //     setInputList(list);
    // };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleEditAttribute = (id, index) => {     
        const list = [...inputList];
        list[index][id] = id;
        setIsEditProduct(list);
    
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { name: '', content: '', checkbox_status: '1', id: '0' }]);
    };

    // product delete
    const handleClickDeleteProduct = (id) => {
        setIsDeleteProduct(id);
    };
    const [isChecked, setIsChecked] = useState(false);
    const handleInputChange = (e, index) => {
        if (e.target.files != undefined) {
            const { name, files } = e.target;
            if (!files[0].type.match(imageMimeType)) {
                const errors = {};
                errors.file = "This file format is not supported";
                // setFormErrorsAddFestival(errors);
                setFormValuesEditProduct({ ...formValuesEditProduct, [name]: "" });
            } else if ((files[0].size / 1024 / 1024) > imagesize) {
                const errors = {};
                errors.file = "Max upload file size is 2048";
                // setFormErrorsAddFestival(errors);
                setFormValuesEditProduct({ ...formValuesEditProduct, [name]: "" });
            } else {
                setFormValuesEditProduct({ ...formValuesEditProduct, [name]: files[0] });
                const errors = {};
                errors.file = "";
                // setFormErrors(errors);
            }
        }
        else {
            const { name, value } = e.target;
            // const [file] = e.target.files;
            setFormValuesEditProduct({ ...formValuesEditProduct, [name]: value });
            // setSrcImg(URL.createObjectURL(file));
            const list = [...inputList];
            list[index][name] = value;
            setInputList(list);
            // console.log(list)
            setInputListcontent(list);
            setInputListname(list);
            setInputListid(list);
            setInputListcheckbox(list);
        }
    };

    const handleSubmitEditProduct = (e) => {
        // const formData = new FormData(e.currentTarget);
        // e.preventDefault();
        // for (let [key, value] of formData.entries()) {
        //     //    console.log(key, value);
        // // }
        e.preventDefault();
        const data = new FormData(e.target);
        console.log(data.get('content'));
        setFormErrorsEditProduct(validateEditProduct(formValuesEditProduct));
        setIsSubmitEditProduct(true);
    };

    const validateEditProduct = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        // if (!values.product_name) {
        //     errors.product_name = "Product name is required!";
        // }

        return errors;
    };

    const contentEl = useRef();
    useEffect(() => {
        // delete product
        if (deleteproduct != null) {
            const deleteproductes = async () => {
                const id = {
                    id: deleteproduct
                }
                await axios.post('service/deleteProduct', id)
                    .then((result) => {
                        alert('record deleted');
                        // window.location.href = '/tokenization';
                        window.location.reload();
                    })
            };
            deleteproductes();
        }

        console.log(formValuesEditProduct);
        if (Object.keys(formErrorsEditProduct).length === 0 && isSubmitEditProduct) {
            const Productupdate = async () => {
                let ProductData = new FormData();
                if (formValuesEditProduct.product_name != '') {
                    ProductData.append("product_name", formValuesEditProduct.product_name)
                } else {
                    ProductData.append("product_name", content.product_name)
                }

                if (formValuesEditProduct.file != '') {
                    ProductData.append("file", formValuesEditProduct.file)
                } else {
                    ProductData.append("file", content.file)
                }

                ProductData.append("id", content.id)
                if (formValuesEditProduct.about_ticket != '') {
                    ProductData.append("about_ticket", formValuesEditProduct.about_ticket)
                } else {
                    ProductData.append("about_ticket", content.about_ticket)
                }


                for (var i = 0; i < inputList.length; i++) {
                    ProductData.append("attribute_detail[]", JSON.stringify(inputList[i]))
                    // console.log(inputList[i])
                }
                await axios.post('service/editProduct', ProductData)
                    .then((result) => {
                        console.log(result);
                        setMessage(result.data.message)
                        // window.location.reload();
                    }).catch((error) => {
                        console.log(error.response.data.message);
                        alert(error.response.data.message);
                        // setloggedinError(error.response.data);
                    })
            };
            Productupdate();
        }

    }, [formErrorsEditProduct, deleteproduct]);
    return (
        <>
            <div className="accordion-item lottery-page">
                <div className="acc-tabs" >
                    <span className="row acc-tabs-heading">
                        <div className="col-xl-4 col-lg-2 col-md-3 col-sm-1 col-10" onClick={onToggle}>
                            {content.product_name}
                        </div>
                        <div className="col-xl-5 col-lg-4 col-md-5 col-sm-5 col-12"></div>
                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12">
                            <span className='stand_status'>{content.status}</span>
                            <span className="form-check form-switch">
                                <input className={`form-check-input ${content.status == 'active' ? '' : 'myinactive'}`} checked={content.status == 'active' ? 'checked' : ''} onChange={onChange} type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" for="flexSwitchCheckDefault"></label>
                            </span>

                        </div>
                    </span>
                    {active === true && <h2 onClick={onToggle}> <i className='fa fa-chevron-up'></i></h2>}
                    {active === false && <h2 onClick={onToggle}> <i className='fa fa-chevron-down'></i></h2>}

                    {active &&
                        <div className="tabShowHide accordion_tab_content"
                            ref={contentEl}>
                            <form onSubmit={handleSubmitEditProduct}>
                                <table className="stand-tbl">
                                    <tbody>
                                        <tr>
                                            <td width="15%">Product Title Name </td>
                                            <td width="15%">
                                                <input
                                                    type="text"
                                                    defaultValue={content.product_name}
                                                    onChange={handleInputChange}
                                                    name="product_name"
                                                />
                                            </td>
                                            <td colSpan="2"></td>
                                            <td width="10%" className="pt-2" align="right">Image</td>
                                            <td><span className="browse_btn">
                                                <input type="file" onChange={handleInputChange} name="file" /></span>
                                            </td>
                                            {content.file == '' &&
                                                <td rowSpan="3" width="15%"><img src="/asset/images/demo-user.jpg" alt="profile.png" style={{ height: '100%', width: '100%' }} /></td>
                                            }
                                            {content.file != '' &&
                                                <td rowSpan="3" width="15%"><img src={`https://api.cph4.ch/${content.file.replace('public/', '')}`} style={{ height: '100%', width: '100%' }} /></td>
                                            }
                                        </tr>
                                        <tr>
                                            <td width="15%">About The Ticket </td>
                                            <td>
                                                <textarea rows="3" name="about_ticket" onChange={handleInputChange}>{content.about_ticket}</textarea>
                                            </td>
                                            <td colSpan="5"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                {content.attribute_detail.length > 0 &&
                                                    content.attribute_detail.map((x, i) => (
                                                        <div className="box productbox" >
                                                                <input type="checkbox"  
                                                                defaultValue={1}
                                                                name="setInputListcheckbox"
                                                                defaultChecked={x.checkbox_status == '1' ? 'checked' : ''}
                                                                // defaultValue={checked == true ? '1' :'' , '0'}
                                                                onChange={(e) => handleInputChange(e, i)}/>
                                                           
                                                            {/* <input

                                                                name="setInputListid"
                                                                placeholder="Enter id"
                                                                defaultValue={x.id}
                                                                onChange={(e) => handleInputChange(e, i)} 
                                                            />*/}

                                                            <input
                                                                name="setInputListname"
                                                                placeholder="Enter Name"
                                                                defaultValue={x.name}
                                                                disabled
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                            <input
                                                                className="ml10"
                                                                name="setInputListcontent"
                                                                placeholder="Enter Content"
                                                                defaultValue={x.content}
                                                                disabled
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                            {/* <a onClick={() => handleEditAttribute(x.id, i)}>edit</a> */}
                                                        
                                                        </div>
                                                    ))
                                                }



                                                {inputList.map((x, i) => {
                                                    return (
                                                        <div className="box productbox" >
                                                            <input type="checkbox" />

                                                            <input
                                                                name="name"
                                                                placeholder="Enter Name"
                                                                // value={x.name}
                                                                // vadefaultValuelue={x.name}
                                                                // onChange={handleInputChange}
                                                                value={x.name}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                            <input
                                                                className="ml10"
                                                                name="content"
                                                                placeholder="Enter Content"
                                                                // value={x.content}
                                                                // defaultValue={x.content}
                                                                // onChange={handleInputChange}
                                                                value={x.content}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                            <div className="btn-box custome-btn">
                                                                {inputList.length !== 1 && (
                                                                    <button className="mr10" onClick={() => handleRemoveClick(i)}>
                                                                        Remove
                                                                    </button>
                                                                )}
                                                                {inputList.length - 1 === i && (
                                                                    <button onClick={handleAddClick}>Add</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colSpan="5"></td>
                                            <td width="10%">
                                                <div className="custome ">
                                                    <button className="btn" onClick={() => handleClickDeleteProduct(content.id)}>
                                                        <span>Delete</span></button>
                                                </div>
                                            </td>
                                            <td width="10%">
                                                <div className="custome">
                                                    <button className="btn" type="submit" ><span>Save</span></button>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>

                                </table>
                            </form>
                        </div>
                    }
                </div>
            </div >

        </>

    );
};

export default Prodcut_token;