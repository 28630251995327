import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios';
function Home() {
    const [GetData, SetGetData] = useState([{}])

    useEffect(() => {
        const FestivalList = async () => {
            axios.get('commonapi/home-page')
                .then((result) => {

                    let getData = result.data.data
                    console.log(getData);
                    SetGetData(getData);
                }).catch((error) => {
                    console.log(error);
                })
        };
        FestivalList();
    }, []);
    return (
        <>
            <div classNameName="c-wrapper">
                <Header />


                <div className="c-home-page">
                    <section className="c-default-banner-section">
                        <div className="container-fluid">

                            <div className="c-default-banner-content">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="c-default-banner-left">
                                            <h1>CONTROL AND TRACK YOUR<br /> FESTIVAL FINANCE</h1>
                                            <div>
                                                <p>We have build a FREE FLEXIBLE and SECURE tool dedicated to Festival Director. The main purpose of the CPH4-Festival is to control, Track and manage your commissions on your festival.</p>
                                                <p>You can create and manage stands </p>
                                            </div>
                                            <a href="javascript:;" className="home-button"><span>ASK FOR INSCRIPTION</span></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="c-default-banner-right">
                                            <img src="asset/images/mobile-img.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="#" className="c-plus-link"><span>Proudly Engineered in Switzerland</span><img src="asset/images/plus.png" alt="" /></a>
                    </section>
                    <section className="c-what-is-section">
                        <div class="c-default-box">
                            {GetData.length > 0 &&
                                GetData.map((data, index) => (

                                    <div class="row c-default-row">
                                        <div class="col-sm-6">
                                            <img src={`https://cph4.ch/uploads/homepage_future/${data.file}`} alt="" />
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="c-default-block">
                                                <h2>{data.title}</h2>
                                                <p>{data.description}</p>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }
                        </div>
                        <div className="container-fluid">
                            <h2 className="c-section-title"><span>WHAT IS</span> CPH4-FESTIVAL FOR?</h2>
                            <p>As festival director</p>
                            <p>We have build a FREE, FLEXIBLE and SECURE tool dedicated to Festival Director. The main<br />
                                purpose of the CPH4-Festival is to control, Track and get commissions on your festival.</p>
                            <p>You can create and manage stands</p>
                            <div className="c-what-is-item">
                                <img src="asset/images/logo-1.png" alt="" />
                                <h3>DISCOVER</h3>
                                <p>We set up a process and a precise selection, in order to discover emerging Artist and talents. The purpose of this process is to unearth lesser-known artists with extraordinary creative potential and knowledge.</p>
                            </div>
                            <div className="c-what-is-item">
                                <img src="asset/images/logo-2.png" alt="" />
                                <h3>SUPPORT</h3>
                                <p>With the CPH4 community and all players in the ecosystem, we actively support artists in several ways by giving them visibility and access to physical and virtual galleries (Metaverse). Also want to support Art education by creating
                                    the first Art school in the metaverse. </p>
                            </div>
                            <div className="c-what-is-item">
                                <img src="asset/images/logo-3.png" alt="" />
                                <h3>PROMOTE</h3>
                                <p>Concretely, the objective of the CPH4 ecosystem is to highlight artists and Artworks to continue to bring the field of art to life and thus allow our community to discover talents.</p>
                            </div>
                            <div className="c-what-is-item">
                                <img src="asset/images/logo-4.png" alt="" />
                                <h3>INVEST</h3>
                                <p>Thanks to the CPH4 token, we allow everyone to invest in art and to participate and support artists and Artworks.</p>
                            </div>
                        </div>
                    </section>
                    <section className="c-dao-program">
                        <div className="container-fluid">
                            <h2 className="c-section-title">DAO Program</h2>
                            <div className="c-dao-container">
                                <div className="c-dao-content">
                                    <div>
                                        <p>The DAO is an organization operating through a computer program that provides governance rules to a community. </p>
                                        <p>A dedicated amount of CPH4 crypto is allocated to the DAO program. For each decision made by the holders on the referendum proposals. The voting power will be weighted based on the amount of CPH4 tokens users hold at the time
                                            of the referendum announcement and it will not cost any CPH4 tokens to vote.</p>
                                    </div>
                                    <p className="c-doa-raise">RAISE YOUR VOTE!</p>
                                </div>
                                <div className="c-dao-image">
                                    <img src="asset/images/dao.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="c-white-paper">
                        <div className="container-fluid">
                            <h2 className="c-section-title">White <span>Papper</span></h2>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="c-white-image">
                                        <img src="asset/images/white-paper.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="c-white-content">
                                        <img src="asset/images/favicon/apple-touch-icon.png" alt="" />
                                        <p>Investors, Art Lovers and Crypto Users trade Art more effectively with CPH4 ecosystem. It is important to highlight that CPH4 (DAO) is a community driven project which has been made by and for the art community, but also for
                                            crypto users - beginners and experienced - as well as for everyone since Art concerns us ours.</p>
                                        <button className="btn">
                                            <span>Open White Paper</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="c-tokenomic-section">
                        <div className="container-fluid">
                            <h2 className="c-section-title">TOKENOMIC</h2>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="c-tokennomic-image">
                                        <img src="asset/images/TOKENOMIC.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="c-tokenomic-content">
                                        <h3>TOKEN INFORMATIONS</h3>
                                        <ul className="c-tokenomic-list">
                                            <li>Token supply: 10 Billion</li>
                                            <li>Token pre-mined</li>
                                            <li>43% for public sale</li>
                                            <li>21% for Staking Program</li>
                                            <li>BEP-20 Token from Binance</li>
                                            <li>ICO end 25th March of 2023</li>
                                            <li>Token Burnable</li>
                                            <li>Multi-Utility Token</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="c-market-section">
                        <div className="container-fluid">
                            <div className="c-market-head">
                                <img src="asset/images/market-logo.png" alt="" />
                                <h2 className="c-section-title">Marketplace to experience</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="c-market-image">
                                        <img src="asset/images/market.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="c-market-content">
                                        <p>This CPH4 metaverse is the bridge between the real world and the virtual world. It is a virtual immersive world in which people can interact with each other and find elements of the real world. The goal of the CPH4 & Ottentik
                                            metaverse is to promote artists, Artworks and Art school in a virtual way and thus create a completely digital experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="c-metaverse-section">
                        <div className="container-fluid">
                            <h2 className="c-section-title">OUR <span>METAVERSE</span></h2>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="c-metaverse-content">
                                        <p>This CPH4 metaverse is the bridge between the real world and the virtual world. It is a virtual immersive world in which people can interact with each other and find elements of the real world. The goal of the CPH4 & Ottentik
                                            metaverse is to promote artists, Artworks and Art school in a virtual way and thus create a completely digital experience.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="c-metaverse-image">
                                        <img src="asset/images/metaverse.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="c-future-section">
                        <div className="container-fluid">
                            <div className="c-future-head">
                                <h2 className="c-section-title">CPH4 FUTUR LISTING</h2>
                                <p>CPH4 token will be traded on popular crypto exchanges to address the issue of holder liquidity. </p>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="c-future-item">
                                        <img src="asset/images/future-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="c-future-item">
                                        <img src="asset/images/future-2.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="c-future-item">
                                        <img src="asset/images/future-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="c-future-item">
                                        <img src="asset/images/future-4.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="c-future-item">
                                        <img src="asset/images/future-5.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="c-future-item">
                                        <img src="asset/images/future-6.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="c-future-item">
                                        <img src="asset/images/future-7.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="c-future-item">
                                        <img src="asset/images/future-8.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="c-future-item">
                                        <img src="asset/images/future-9.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="c-community-section">
                        <div className="container-fluid">
                            <h2 className="c-section-title">COMMUNITY</h2>
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <div className="c-community-item">
                                        <div className="c-community-image">
                                            <img src="asset/images/demo-user.jpg" alt="" />
                                        </div>
                                        <div className="c-community-content">
                                            <h3>SARAH ZAHNO <span>CEO & CO-FOUNDER</span></h3>
                                            <ul>
                                                <li>ART EXPERT</li>
                                                <li>+20y Experience</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="c-community-item">
                                        <div className="c-community-image">
                                            <img src="asset/images/demo-user.jpg" alt="" />
                                        </div>
                                        <div className="c-community-content">
                                            <h3>JULIEN VERNAY <span>CVO & CO-FOUNDER</span></h3>
                                            <ul>
                                                <li>Technology Expert</li>
                                                <li>+12y experience</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row c-community-row">
                                <div className="col-lg-6 col-xl-3">
                                    <div className="c-community-item">
                                        <div className="c-community-image">
                                            <img src="asset/images/demo-user.jpg" alt="" />
                                        </div>
                                        <div className="c-community-content">
                                            <h3>Sinan Seker <span>Crypto Expert</span></h3>
                                            <ul>
                                                <li>Technology Expert</li>
                                                <li>+12y experience</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-3">
                                    <div className="c-community-item">
                                        <div className="c-community-image">
                                            <img src="asset/images/demo-user.jpg" alt="" />
                                        </div>
                                        <div className="c-community-content">
                                            <h3>Pratik Rupani <span>Chief Technology Officer</span></h3>
                                            <ul>
                                                <li>+10y experience</li>
                                                <li>IT Architecture</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-3">
                                    <div className="c-community-item">
                                        <div className="c-community-image">
                                            <img src="asset/images/demo-user.jpg" alt="" />
                                        </div>
                                        <div className="c-community-content">
                                            <h3>Chirag Sheth<span>IT Managing Director</span></h3>
                                            <ul>
                                                <li>+12y experience</li>
                                                <li>Business & Risk Management</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-3">
                                    <div className="c-community-item">
                                        <div className="c-community-image">
                                            <img src="asset/images/demo-user.jpg" alt="" />
                                        </div>
                                        <div className="c-community-content">
                                            <h3>Devang Gandhi<span>Sr. Software Engineer</span></h3>
                                            <ul>
                                                <li>+12y experience</li>
                                                <li>Dev & Cryptocurrency expert</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="c-bepart-section">
                        <div className="container-fluid">
                            <h2 className="c-section-title">BE PART OF IT</h2>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="c-bepart-item">
                                        <h3>Make it happen</h3>
                                        <div>
                                            <p>Your mission is to speak to your community about the CPH4 crypto and make it spread the world as a “virus” with a positive impact. By sharing and sharing again, you help the community grow and play an essential role in
                                                the development of CPH4 ecosystem.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="c-bepart-item">
                                        <h3>Invest in cph4</h3>
                                        <div>
                                            <p>We have created our own secure wallet on the cph4 platform.</p>
                                            <p>ETH, BTC, USDT, BUSD, DOGE, XRP, BNB can be exchange easily.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="c-bepart-item">
                                        <h3>Stake your cph4</h3>
                                        <div>
                                            <p>Staking is the act of immobilizing your crypto currencies in a smart contract in order to participate in the operations that take place on a blockchain. Staking is similar to mining in that it rewards users who participate.
                                                You will thus store your cryptos while benefiting from advantages.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="c-call-to-action-buttons">
                        <div className="container-fluid">
                            <div className="c-call-to-action-content">
                                <button className="btn"><span>White Paper</span></button>
                                <button className="btn"><span>BUY CPH4</span></button>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Home
