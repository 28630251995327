import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import axios from '../axios';

const Accordion = ({ content, active, onToggle, name, onClick, onChange, onChangegift }) => {
    console.log(content)
    const Open = () => {
        document.getElementById('get_file').onclick = function () {
            document.getElementById('input_file').click();
        }
    }
    const Opens = () => {
        document.getElementById('get_files').onclick = function () {
            document.getElementById('input_files').click();
        }
    }
    const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
    const imagesize = 2;
    const [Artist, setArtist] = useState({});
    const [ticket, setTicket] = useState()
    const handleClick = () => setTicket(Math.random().toString(36).slice(2, 7))
    const Accname = name;
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const [Message, setMessage] = useState("");

    const initialValuesAddProduct = { product_name: "", file: "", about_ticket: "" };
    const initialValuesEditTokenization = { service_id: "", artist_id: "",name:"",
     email_of_artist_responsibility: "", artist_resale_rights: "", content: "", gift_code: "",
      file: "" , art_image:"" };
    const initialValuesEditProduct = { product_name: "", id: "" };
    const [formValuesEditProduct, setFormValuesEditProduct] = useState(initialValuesEditProduct);
    const [deleteproduct, setIsDeleteProduct] = useState(null);
    const [editProduct, setIsEditProduct] = useState(null);
    const [Product, setProduct] = useState({});
    const [formValuesAddProduct, setFormValuesAddProduct] = useState(initialValuesAddProduct);
    const [formValuesEditTokenization, setFormValuesEditTokenization] = useState(initialValuesEditTokenization);
    const [formErrorsEditTokenization, setFormErrorsEditTokenization] = useState({});
    const [isSubmitEditTokenization, setIsSubmitEditTokenization] = useState(false);
    const [deletetokenization, setIsDeleteTokenization] = useState(null);
    const [formErrorsAddProduct, setFormErrorsAddFestival] = useState({});
    const [isSubmitAddFestival, setIsSubmitAddFestival] = useState(false);

    //Add Prodcuct form
    const [inputList, setInputList] = useState([{ name: '', content: '' , checkbox_status:'1'}]);
   
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, {name: '', content: '', checkbox_status:'1' }]);
    };

    const handleClickDeleteProduct = (id) => {
        setIsDeleteProduct(id);
    };
    const handleClickeditProduct = (id) => {
        setIsEditProduct(id);
        console.log(true)
    };

    const handleChangeEditProduct = (e) => {
        const { name, value } = e.target;
        setFormValuesEditProduct({ ...formValuesEditProduct, [name]: value });
    };

    const handleClickDeleteTokenization = (id) => {
        setIsDeleteTokenization(id);
    };
    const handleInputChange = (e,index) => {
        if (e.target.files != undefined) {
            const { name, files } = e.target;
            if (!files[0].type.match(imageMimeType)) {
                const errors = {};
                errors.file = "This file format is not supported";
                // setFormErrorsAddFestival(errors);
                setFormValuesAddProduct({ ...formValuesAddProduct, [name]: "" });
            } else if ((files[0].size / 1024 / 1024) > imagesize) {
                const errors = {};
                errors.file = "Max upload file size is 2048";
                // setFormErrorsAddFestival(errors);
                setFormValuesAddProduct({ ...formValuesAddProduct, [name]: "" });
            } else {
                setFormValuesAddProduct({ ...formValuesAddProduct, [name]: files[0] });
                const errors = {};
                errors.file = "";
                // setFormErrors(errors);
            }
        }
        else {
            const { name, value } = e.target;
            setFormValuesAddProduct({ ...formValuesAddProduct, [name]: value });
            const list = [...inputList];
            list[index][name] = value;
            setInputList(list);
        }
    };

    const [img, setSrcImg] = useState();

    const handleChangeEditTokenization = (e) => {
        if (e.target.files != undefined) {
            const { name, files } = e.target;
            if (!files[0].type.match(imageMimeType)) {
                const errors = {};
                errors.file = "This file format is not supported";
                // setFormErrorsAddFestival(errors);
                setFormValuesEditTokenization({ ...formValuesEditTokenization, [name]: "" });
            } else if ((files[0].size / 1024 / 1024) > imagesize) {
                const errors = {};
                errors.file = "Max upload file size is 2048";
                // setFormErrorsAddFestival(errors);
                setFormValuesEditTokenization({ ...formValuesEditTokenization, [name]: "" });
            } else {
                setFormValuesEditTokenization({ ...formValuesEditTokenization, [name]: files[0] });
                const errors = {};
                errors.file = "";
                // setFormErrors(errors);
            }
        }
        else {
            const { name, value } = e.target;
            // const [file] = e.target.files;
            setFormValuesEditTokenization({ ...formValuesEditTokenization, [name]: value });
        }
    };
    const handleSubmitEditTokenization = (e) => {
        e.preventDefault();
        setFormErrorsEditTokenization(validateAddFestival(formValuesEditTokenization));
        setIsSubmitEditTokenization(true);
    };
    const handleSubmitAddToken = (e) => {
        e.preventDefault();
        setFormErrorsAddFestival(validateAddFestival(formValuesAddProduct));
        setIsSubmitAddFestival(true);
    };
    const validateAddFestival = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        // if (!values.product_name) {
        //     errors.product_name = "Product name is required!";
        // }
        return errors;
    };

    const contentEl = useRef();
    useEffect(() => {
        const getartist = async () => {
            await axios.get('users/artist')
                .then((result) => {
                    if (result.data.status == true) {
                        setArtist(result.data.data)
                    }
                })
        };
        getartist();
        // get product detail
        const getproduct = async () => {
            const service_id = {
                service_id: content.id
            }
            await axios.post('service/listProduct', service_id)
                .then((result) => {
                    if (result.data.status == true) {
                        setProduct(result.data.data)
                    }
                })
        };
        getproduct();
        // if (editProduct != null) {
        //     const editProducts = async () => {
        //         const data = {
        //             id: editProduct,
        //             product_name: formValuesEditProduct.product_name
        //         }
        //         await axios.post('service/editProduct', data)
        //             .then((result) => {
        //                 alert('record update');
        //                 window.location.reload();
        //             })
        //     };
        //     editProducts();
        // }

        // delete tokenization
        if (deletetokenization != null) {
            const deletetokenizations = async () => {
                const id = {
                    id: deletetokenization
                }
                await axios.post('service/delete', id)
                    .then((result) => {
                        alert('record deleted');
                        window.location.reload();
                    })
            };
            deletetokenizations();
        }

        // add Tokenizations
        if (Object.keys(formErrorsAddProduct).length === 0 && isSubmitAddFestival) {
            // console.log(inputList)
            const productcreate = async () => {
                let ProductData = new FormData();
                ProductData.append("product_name", formValuesAddProduct.product_name)
                ProductData.append("file", formValuesAddProduct.file)
                ProductData.append("service_id", content.id)
                ProductData.append("about_ticket", formValuesAddProduct.about_ticket)
                
                for(var i = 0; i < inputList.length; i++){
                    ProductData.append("attribute_detail[]", JSON.stringify(inputList[i]))
                    // console.log(inputList[i])
                }
                // console.log(ProductData)
                await axios.post('service/addProduct', ProductData)
                    .then((result) => {
                        console.log(result)
                        // setMessage(result.data.message)
                        // window.$('#Createfestival').modal('hide');
                        window.location.reload();
                    }).catch((error) => {
                        console.log(error.response.data.message);
                        alert(error.response.data.message);
                        // setloggedinError(error.response.data);
                    })
            };
            productcreate();
        }
        // edit Tokenizations
        if (Object.keys(formErrorsEditTokenization).length === 0 && isSubmitEditTokenization) {

            const Tokenizationsupdate = async () => {
                let StandData = new FormData();

                if (formValuesEditTokenization.file != '') {
                    StandData.append("file", formValuesEditTokenization.file)
                } else {
                    StandData.append("file", content.file)
                }
                if (formValuesEditTokenization.art_image != '') {
                    StandData.append("art_image", formValuesEditTokenization.art_image)
                } else {
                    StandData.append("art_image", content.art_image)
                }
                if (formValuesEditTokenization.artist_resale_rights != '') {
                    StandData.append("artist_resale_rights", formValuesEditTokenization.artist_resale_rights)
                } else {
                    StandData.append("artist_resale_rights", content.artist_resale_rights)
                }
                 if (formValuesEditTokenization.name != '') {
                    StandData.append("name", formValuesEditTokenization.name)
                } else {
                    StandData.append("name", content.name)
                }
                if (formValuesEditTokenization.gift_code != '') {
                    StandData.append("gift_code", formValuesEditTokenization.gift_code)
                } else {
                    StandData.append("gift_code", content.gift_code)
                }
                if (formValuesEditTokenization.email_of_artist_responsibility != '') {
                    StandData.append("email_of_artist_responsibility", formValuesEditTokenization.email_of_artist_responsibility)
                } else {
                    StandData.append("email_of_artist_responsibility", content.email_of_artist_responsibility)
                }
                if (formValuesEditTokenization.content != '') {
                    StandData.append("content", formValuesEditTokenization.content)
                } else {
                    StandData.append("content", content.content)
                }

                StandData.append("id", content.id)
                StandData.append("user_id", user_data.id)
                // StandData.append("artist_id", content.artist_id)
                
                await axios.post('service/update', StandData)
                    .then((result) => {
                        setMessage(result.data.message)
                        // window.$('#Createfestival').modal('hide');
                        window.location.reload();
                    }).catch((error) => {
                        console.log(error.response.data.message);
                        alert(error.response.data.message);
                        // setloggedinError(error.response.data);
                    })
            };
            Tokenizationsupdate();
        }

    }, [formErrorsAddProduct, deleteproduct, editProduct, deletetokenization, isSubmitEditTokenization]);
    return (
        <>
           
            <div className="accordion-item">
                <div className="acc-tabs" >
                    <span className="row acc-tabs-heading">
                        <div className="col-xl-4 col-lg-2 col-md-3 col-sm-1 col-10" onClick={onToggle}>
                            {content.name} 
                        </div>
                        <div className="col-xl-5 col-lg-4 col-md-5 col-sm-5 col-12"></div>
                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12">
                            <span className='stand_status'>{content.status}</span>
                            <span className="form-check form-switch">
                                <input className={`form-check-input ${content.status == 'active' ? '' : 'myinactive'}`} checked={content.status == 'active' ? 'checked' : ''} onChange={onChange} type="checkbox" id="flexSwitchCheckDefault" />
                                <label className="form-check-label" for="flexSwitchCheckDefault"></label>
                            </span>
                            
                        </div>
                    </span>
                    {active === true && <h2 onClick={onToggle}> <i className='fa fa-chevron-up'></i></h2>}
                    {active === false && <h2 onClick={onToggle}> <i className='fa fa-chevron-down'></i></h2>}
                    {/* {FestivalData.length === 0 && <div className='no_record'>No data found</div>} */}
                      
                    {active &&
                        <div className="tabShowHide accordion_tab_content"
                            ref={contentEl}>
                            <form onSubmit={handleSubmitEditTokenization}>
                                <table className="stand-tbl tokenization_tbl">
                                    <tbody>
                                        <tr>
                                            <td>Artisat Name </td>
                                            <td>
                                            <input type="text" defaultValue={content.name} onChange={handleChangeEditTokenization} name="name"></input>
                                                 {/* <select
                                                onChange={handleChangeEditTokenization}
                                                name="artist_id"
                                                id="artist_id"
                                                className="form-control"
                                                defaultValue={content.artist_id}>
                                                <option Selected >Select Artist</option>
                                                {Artist.length > 0 &&
                                                    Artist.map((res, i) => (
                                                        <option value={res.id}>{res.first_name}{res.people_name}</option>
                                                    ))
                                                }
                                            </select> */}
                                            </td>
                                           <td align="right" className="text_align_middle">Product</td><td>
                                                {/* <div className="custome-btn" onClick={(e) => {
                                                    e.preventDefault();
                                                    window.$('#Createproduct_' + content.id).modal('show');
                                                }}><button> Add</button></div> */}
                                                 <Link className="custome-btn" to={`/${content.id}/product`}><button> Add</button></Link>
                                            </td>
                                            <td className="text_align_middle">Image</td>
                                            <div className="custome-btn">
                                                <a id="get_file" onClick={() => Open()} className="upload_btn"> Upload</a>
                                                <input type="file" onChange={handleChangeEditTokenization} id="input_file" name="file" accept=".jpg,.jpeg,.png" style={{ display: 'none' }} />
                                            </div>

                                            {content.file == '' &&
                                                <td rowSpan="2" width="15%"><img src="/asset/images/demo-user.jpg" alt="profile.png" style={{ height: '100%', width: '100%' }} /></td>
                                            }
                                            {content.file != '' &&
                                                <td rowSpan="2" width="15%"><img src={`${content.file.replace('public/', '')}`} style={{ height: '100%', width: '100%' }} /></td>
                                            }
                                           
                                        </tr>
                                        <tr>
                                            <td width="15%">About The Artisat </td>
                                            <td width="10%"><textarea rows="5" onChange={handleChangeEditTokenization} name="content">{content.content}</textarea></td>

                                            <td colSpan="2">
                                                {Product.length > 0 &&
                                                    Product.map((res) => (
                                                        <p>
                                                            <input type="text" disabled name="product_name" onChange={handleChangeEditProduct} defaultValue={res.product_name} />
                                                            {/* <button variant="outlined" onClick={() => handleClickeditProduct(res.id)}>
                                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                                            </button> */}
                                                            {/* <Link To="product">Modify</Link> */}
                                                            <Link className="download_text" to={`/${res.id}/product`}>Modify</Link>
                                                            {/* <button onClick={() => handleClickDeleteProduct(res.id)}>
                                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                                            </button> */}

                                                        </p>
                                                    ))
                                                }
                                            </td>
                                            <td colSpan="3"></td>
                                        </tr>
                                        <tr>
                                            <td width="15%">Email Of Artisat Responsibility</td>
                                            <td width="10%"><input type="text" defaultValue={content.email_of_artist_responsibility} onChange={handleChangeEditTokenization} name="email_of_artist_responsibility"></input></td>
                                            <td width="10%" className="gift-code">Generate Gift Code</td>
                                            <td width="5%">

                                                {content.gift_status == 'active' &&
                                                    <div className="custome-btn">
                                                        <a onClick={handleClick}>Generate</a>
                                                        <input type="text" name="gift_code" onChange={handleChangeEditTokenization} defaultValue={ticket} readonl></input>
                                                    </div>
                                                }
                                                {content.gift_code}
                                                {/* <span className='stand_status'>{content.status}</span> */}
                                                <span className="form-check form-switch">
                                                    <input className={`form-check-input ${content.gift_status == 'active' ? '' : 'myinactive'}`} checked={content.gift_status == 'active' ? 'checked' : ''} onChange={onChangegift} type="checkbox" id="flexSwitchCheckDefault" />
                                                    <label className="form-check-label" for="flexSwitchCheckDefault"></label>
                                                </span>
                                            </td>
                                             <td className="text_align_middle">Image</td>
                                            <div className="custome-btn">
                                                <a id="get_files" onClick={() => Opens()} className="upload_btn"> Upload</a>
                                                <input type="file" onChange={handleChangeEditTokenization} id="input_files" name="art_image" accept=".jpg,.jpeg,.png" style={{ display: 'none' }} />
                                            </div>

                                            {content.artistDetail.art_image == '' &&
                                                <td rowSpan="2" width="15%"><img src="/asset/images/demo-user.jpg" alt="profile.png" style={{ height: '100%', width: '100%' }} /></td>
                                            }
                                            {content.artistDetail.art_image != '' &&
                                                <td rowSpan="2" width="15%"><img src={`${content.artistDetail.art_image.replace('public/', '')}`} style={{ height: '100%', width: '100%' }} /></td>
                                            }
                                        </tr>
                                        <tr>
                                            <td width="15%">Artisat resale rights</td>
                                            <td width="10%"><input type="text" defaultValue={content.artist_resale_rights} onChange={handleChangeEditTokenization} name="artist_resale_rights"></input></td>
                                            <td colSpan="3"></td>
                                        </tr>

                                        <tr>
                                            <td colSpan="5"></td>
                                            <td width="15%">
                                                <div className="custome">
                                                    <button className="btn" onClick={() => handleClickDeleteTokenization(content.id)}>
                                                        <span>Delete</span>
                                                    </button>
                                                </div>
                                            </td>
                                            <td width="10%">
                                                <div className="custome">
                                                    <button className="btn" type="submit" ><span>Save</span></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    }
                </div>
            </div >
            {/* <!-- Product Modal --> */}
            <div className="modal fade c-login-modal" id={`Createproduct_${content.id}`} tabindex="-1" aria-labelledby="Createproduct" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">

                            <div className="c-festival-create-form">
                                <div className="col-sm-12">
                                    <center> <h2>Create Product</h2></center>
                                    <form onSubmit={handleSubmitAddToken}>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Product name"
                                                name="product_name"
                                                id="product_name"
                                                maxLength="50"
                                                // value={content.id}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter About The Ticket"
                                                name="about_ticket"
                                                id="about_ticket"
                                                maxLength="50"
                                                onChange={handleInputChange} />

                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="file"
                                                className="form-control"
                                                placeholder="Enter Upload logo"
                                                name="file"
                                                id="file"
                                                accept=".png,.jpg,.jpeg,.webp"
                                                onChange={handleInputChange} />

                                        </div>
                                        
                                        {inputList.map((x, i) => {
                                            return (
                                                <div className="box">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <input
                                                                className="form-control"
                                                                name="name"
                                                                placeholder="Enter Name"
                                                                value={x.name}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <input
                                                                className="form-control"
                                                                name="content"
                                                                placeholder="Enter Content"
                                                                value={x.content}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="btn-box">
                                                        {inputList.length !== 1 && (
                                                            <button className="mr10" onClick={() => handleRemoveClick(i)}>
                                                                Remove
                                                            </button>
                                                        )}
                                                        {inputList.length - 1 === i && (
                                                            <button onClick={handleAddClick}>Add</button>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}


                                        <div className="custome">
                                            {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                            <button className="btn" type="submit" >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Accordion;