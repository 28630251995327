import React, { useEffect, useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios'

import { Routes, Route, Outlet, Link, useParams } from "react-router-dom";
import LoginHeader from '../component/loginHeader';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

function Dashboard() {
    const sessiontoken = localStorage.getItem('token');

    let { id } = useParams()
    // if (sessiontoken === null) {
    //     window.location.href = "/";
    // }
    const [startDate, setStartDate] = useState(new Date());
    const [DashboardData, setDashboardData] = useState({});

    useEffect(() => {
        const dashboard_data = async () => {
            let api_Data = {
                festivalId: id,
                date: moment(startDate).format("YYYY-MM-DD"),
                language_id: 2
            }
            await axios.post('dashboard_detail/user_festival_dashboard', api_Data)
                .then((result) => {
                    let res = result.data.data
                    let status = result.data.data
                    setDashboardData(res)
                }).catch((error) => {
                    console.log(error);
                })
        }
        dashboard_data();
    }, [startDate]);


    return (
        <>
            <Header />
            <div>Dashboard</div>

            <section className="c-dashboard-section dashboard">
                <LoginHeader Stand={false} standData={[]} />
                <div className="tab-content">
                    <div className="tab-pane" id="" role="" aria-labelledby="">
                        <h2>
                            <div className="container-fluid">
                                <span>GENERAL INFORMATION </span>
                                <div className='dashboard-search'>
                                    {/* <input type='text' classNameName='col-md-12' placeholder='Search a stand' /> */}
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        shouldCloseOnSelect={false}
                                    />
                                </div>
                            </div>
                        </h2>
                        {Object.keys(DashboardData).length > 0 &&
                            <>
                                <div className="container-fluid">
                                    <div className="tab-content-block">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="dashboard-block">
                                                    <div className="dashboard-box-inner">
                                                        <h3>YOUR COMMISSION *ALL INCLUDED+</h3>
                                                        <strong>{Number(DashboardData.your_commission).toFixed(0)}<span>{DashboardData.festival_currency_val}</span></strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="dashboard-block white-border">
                                                    <div className="dashboard-box-inner">
                                                        <div className="row no-gutters">
                                                            <div className="col-sm-6">
                                                                <h3> TOTAL SALES AMOUNT OF THE DAY</h3>
                                                                <strong>{Number(DashboardData.total_sales_amount_of_the_day).toFixed(0)}<span>{DashboardData.festival_currency_val}</span></strong>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h3>TOTAL SALES AMOUNT SINCE THE BEGINNING</h3>
                                                                <strong>{`${Number(DashboardData.total_sales_amount_since_the_beginnig).toFixed(0)}`}<span>{DashboardData.festival_currency_val}</span></strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="dashboard-block white-border">
                                                    <div className="dashboard-box-inner">
                                                        <div className="row no-gutters">
                                                            <div className="col-sm-6">
                                                                <h3> NUMBER OF BUYERS OF THE DAY</h3>
                                                                <strong>{Number(DashboardData.number_of_buyers_of_the_day)}<span>BUYERS</span></strong>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h3> NUMBER OF BUYERS SINCE THE BEGINNING</h3>
                                                                <strong>{Number(DashboardData.number_of_buyers_since_the_beginnig)}<span>BUYERS</span></strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                <div className="dashboard-block white-border">
                                                    <div className="dashboard-box-inner">
                                                        <div className="row no-gutters">
                                                            <div className="col-sm-6">
                                                                <h3>AVERAGE BASKET BY BUYER</h3>
                                                                <strong>{Number(DashboardData.average_basket_by_buyer)}<span>{DashboardData.festival_currency_val}</span></strong>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h3>AGE OF BUYERS</h3>
                                                                <strong>{Number(DashboardData.age_of_buyers)}<span>YEAR OLD</span></strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="stand_block">
                                    <h2>
                                        <div className="container-fluid">
                                            <span>Stand </span>
                                        </div>
                                    </h2>
                                    <div className="container-fluid">
                                        <div className="tab-content-block">
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                    <div className="dashboard-block white-border">
                                                        <div className="dashboard-box-inner">
                                                            <div className="row no-gutters">
                                                                <div className="col-sm-6 green-block">
                                                                    <h3>NUMBER OF ACTIVE STAND</h3>
                                                                    <strong>{DashboardData.number_of_active_stand}</strong>
                                                                </div>
                                                                <div className="col-sm-6  yellow-block">
                                                                    <h3> NUMBER OF INACTIVE STAND</h3>
                                                                    <strong>{DashboardData.number_of_inactive_stand}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                    <div className="dashboard-block white-border">
                                                        <div className="dashboard-box-inner">
                                                            <div className="row no-gutters">
                                                                {DashboardData.stand_best_seller == 0
                                                                    ?
                                                                    <div className="col-sm-6 green-block">
                                                                        <h3 className='stand_title'>STAND BEST SELLER</h3>
                                                                        <strong className='stand_price'>{Number(DashboardData.stand_best_seller)}<span>CPH4-F</span></strong>
                                                                    </div>
                                                                    :
                                                                    <div className="col-sm-6 green-block">
                                                                        <h3 className='stand_title'>STAND BEST SELLER</h3>
                                                                        <h3 className='stand_name'>{DashboardData.stand_best_seller.name} <img src={`http://festivalappapi.cph4.ch:5000/${DashboardData.stand_best_seller.upload_logo.replace('public/', '')}`} className="stand_image" alt="" /> </h3>
                                                                        <strong className='stand_price'>{Number(DashboardData.stand_best_seller.sales_amount)}<span>CPH4-F</span></strong>
                                                                    </div>
                                                                }

                                                                {DashboardData.stand_bad_seller == 0
                                                                    ?
                                                                    <div className="col-sm-6 yellow-block">
                                                                        <h3 className='stand_title'>STAND BAD SELLER</h3>
                                                                        <strong className='stand_price'>{Number(DashboardData.stand_bad_seller)}<span>CPH4-F</span></strong>
                                                                    </div>
                                                                    :
                                                                    <div className="col-sm-6 yellow-block">
                                                                        <h3 className='stand_title'>STAND BAD SELLER</h3>
                                                                        <h3 className='stand_name'>{DashboardData.stand_bad_seller.name}  <img src={`http://festivalappapi.cph4.ch:5000/${DashboardData.stand_bad_seller.upload_logo.replace('public/', '')}`} className="stand_image" alt="" /> </h3>
                                                                        <strong className='stand_price'>{Number(DashboardData.stand_bad_seller.sales_amount)}<span>CPH4-F</span></strong>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </section>

            <Footer />
            <Outlet />
        </>
    );
}

export default Dashboard
