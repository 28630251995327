import React, { useEffect, useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios'

import { Routes, Route, Outlet, Link, useParams } from "react-router-dom";
import LoginHeader from '../component/loginHeader';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function TeamManagement() {

    let { id } = useParams()

    const sessiontoken = localStorage.getItem('token');
    const sessionuser = JSON.parse(localStorage.getItem('user_data'));
    if (sessiontoken === null) {
        window.location.href = "/";
    }

    const [memberList, setMemberList] = useState([{}])
    const [memberDeleteId, setmemberDeleteId] = useState('')
    const [MemberDeletMsg, setMemberDeletMsg] = useState("")

    //Member Create
    const initialValues = { parent_user_id: sessionuser.id, first_name: "", people_name: "", email: '', password: '', confirm_password: '', phonecode: '', phone: '', festival_role: '', festival_id: id };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);



    //Member Update
    const initialUpdateValues = { user_id: "", parent_user_id: sessionuser.id, first_name: "", people_name: "", email: '', password: '', confirm_password: '', phonecode: '', phone: '', festival_role: '', festival_id: id };
    const [formUpdateValues, setFormUpdateValues] = useState(initialUpdateValues);
    const [formUpdateErrors, setFormUpdateErrors] = useState({});
    const [isUpdateSubmit, setIsUpdateSubmit] = useState(false);

    //CountryGet
    const [CountryGet, setCountryGet] = useState([{}]);

    const handleOnChange = (value, data, event, formattedValue) => {
        setFormValues({ ...formValues, ['phonecode']: '+ ' + data.dialCode, 'phone': value.slice(data.dialCode.length) });
    }


    const handleChangeAddTeam = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const validateAddTeam = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.first_name) {
            errors.first_name = "First name is required!";
        }

        if (!values.people_name) {
            errors.people_name = "Last name is required!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 8) {
            errors.password = "Password must be more than 8 characters";
        } else if (values.password.length > 10) {
            errors.password = "Password cannot exceed more than 10 characters";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Repeat Password is required";
        } else if (values.confirm_password.length < 8) {
            errors.confirm_password = "Repeat Password must be more than 8 characters";
        } else if (values.confirm_password.length > 10) {
            errors.confirm_password = "Repeat Password cannot exceed more than 10 characters";
        } else if (values.confirm_password != values.password) {
            errors.confirm_password = "Repeat Password cannot match the password";
        }

        if (!values.phonecode) {
            errors.phonecode = "Phone code is required!";
        }

        if (!values.phone) {
            errors.phone = "Phone number is required!";
        }

        if (!values.festival_role) {
            errors.festival_role = "Role is required!";
        }

        return errors;
    };

    const handleSubmitAddTeam = (e) => {
        e.preventDefault();
        setFormErrors(validateAddTeam(formValues));
        setIsSubmit(true);
    };

    //Update Member
    const handleOnUpdateChange = (value, data, event, formattedValue) => {
        setFormUpdateValues({ ...formUpdateValues, ['phonecode']: '+ ' + data.dialCode, 'phone': value.slice(data.dialCode.length) });
    }


    const handleChangeUpdateTeam = (e) => {
        const { name, value } = e.target;
        setFormUpdateValues({ ...formUpdateValues, [name]: value });
    };

    const validateUpdateTeam = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.first_name) {
            errors.first_name = "First name is required!";
        }

        if (!values.people_name) {
            errors.people_name = "Last name is required!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }

        // if (!values.password) {
        //     errors.password = "Password is required";
        // } else if (values.password.length < 8) {
        //     errors.password = "Password must be more than 8 characters";
        // } else if (values.password.length > 10) {
        //     errors.password = "Password cannot exceed more than 10 characters";
        // }

        // if (!values.confirm_password) {
        //     errors.confirm_password = "Repeat Password is required";
        // } else if (values.confirm_password.length < 8) {
        //     errors.confirm_password = "Repeat Password must be more than 8 characters";
        // } else if (values.confirm_password.length > 10) {
        //     errors.confirm_password = "Repeat Password cannot exceed more than 10 characters";
        // } else if (values.confirm_password != values.password) {
        //     errors.confirm_password = "Repeat Password cannot match the password";
        // }

        if (!values.phonecode) {
            errors.phonecode = "Phone code is required!";
        }

        if (!values.phone) {
            errors.phone = "Phone number is required!";
        }

        if (!values.festival_role) {
            errors.festival_role = "Role is required!";
        }

        return errors;
    };

    const handleSubmitUpdateTeam = (e) => {
        e.preventDefault();
        setFormUpdateErrors(validateUpdateTeam(formUpdateValues));
        setIsUpdateSubmit(true);
    };


    const memberdelete = (memberid) => {
        setmemberDeleteId(memberid)
    }

    const memberEdit = async (memberid) => {

        let api_Data = {
            user_id: memberid
        }
        await axios.post('team_management/MemberEdit', api_Data)
            .then((result) => {
                console.log('result', result)
                var userData = result.data.data
                const initialUpdateValues = {
                    user_id: memberid,
                    parent_user_id: sessionuser.id,
                    first_name: userData.first_name,
                    people_name: userData.people_name,
                    email: userData.email,
                    phonecode: userData.phonecode,
                    phone: userData.phone,
                    festival_role: userData.festival_role,
                    festival_id: id
                };
                setFormUpdateValues(initialUpdateValues)
                // let memberdata = result.data.message
                // setMemberDeletMsg(memberdata)
                // setIsSubmit(false)
                // window.location.reload();
            })
            .catch((error) => {
                console.log(error.response.data.message)
                // alert(error.response.data.message);
            })



        window.$('#EditTeam').modal('show');
        // setmemberDeleteId(memberid)
    }

    const festivalRoleChange = async (e, memberId) => {
        const { name, value } = e.target

        let api_Data = {
            user_id: memberId,
            festival_role: value
        }

        await axios.post('team_management/MemberUpdateForRole', api_Data)
            .then((result) => {
                // let memberdata = result.data.message
                // let status = result.data.status
                // if (status) {
                window.location.reload();
                // } else {
                //     alert(result.data.message)
                // }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }

    useEffect(() => {
        //member list
        const member_list = async () => {
            let apidata;
            if (sessionuser.parent_user_id != null) {
                apidata = {
                    user_id: sessionuser.parent_user_id,
                    festival_id: id
                }
            } else {
                apidata = {
                    user_id: sessionuser.id,
                    festival_id: id
                }
            }

            await axios.post('team_management/MemberList', apidata)
                .then((result) => {
                    let memberdata = result.data.data
                    setMemberList(memberdata)
                    // console.log(memberdata)
                })
                .catch((error) => {
                    let memberdata = error.response.data.data
                    setMemberList(memberdata)
                })
            return
        }
        member_list();

        //Member delete
        if (memberDeleteId != '') {
            const member_delete = async () => {
                let apidata = {
                    user_id: memberDeleteId
                }
                await axios.post('team_management/MemberDelete', apidata)
                    .then((result) => {
                        let memberdata = result.data.message
                        setMemberDeletMsg(memberdata)
                        setmemberDeleteId(memberDeleteId)
                        window.location.reload();
                    })
                    .catch((error) => (
                        console.log(error)
                    ))
            }
            member_delete();
        }

        //Member create
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            const member_create = async () => {
                await axios.post('team_management/MemberCreate', formValues)
                    .then((result) => {
                        let memberdata = result.data.message
                        // setMemberDeletMsg(memberdata)
                        setIsSubmit(false)
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        alert(error.response.data.message);
                    })
            }
            member_create();

        }

        //Member Update
        if (Object.keys(formUpdateErrors).length === 0 && isUpdateSubmit) {
            const member_update = async () => {
                await axios.post('team_management/MemberUpdate', formUpdateValues)
                    .then((result) => {
                        let memberdata = result.data.message
                        let status = result.data.status
                        if (status) {
                            window.location.reload();
                        } else {
                            alert(result.data.message)
                        }
                    })
                    .catch((error) => {
                        console.log('error', error)
                    })
                setIsUpdateSubmit(false)
            }
            member_update();

        }

        //countery get
        const CountryGet = async () => {
            await axios.get('commonapi/CountryGet')
                .then((result) => (
                    setCountryGet(result.data.data)
                )).catch((error) => {
                    console.log(error)
                })
        }
        CountryGet()


    }, [memberDeleteId, formErrors, formUpdateErrors])


    return (
        <>
            <div className="c-wrapper">
                <Header />

                <div>Team Management</div>

                <section className="c-dashboard-section">
                    <LoginHeader Stand={false} standData={[]} />
                    { /*<div className="tab-content">
                    <div className="tab-pane" id="my-festival" role="tabpanel" aria-labelledby="my-festival-tab">
                        <h2>
                            <div className="container-fluid">
                                <span>Team Management</span>
                            </div>
                        </h2>
                    </div>
                </div> */}

                    <div className="container-fluid">
                        <div className="team-management-page">
                            <table width="100%" className="" cellpadding="5">
                                {/* <thead>
                                    <tr>
                                        <td colspan="2"> <a href="javascript:;">Centre d'aide </a>
                                            <a href="javascript:;">Blog </a>
                                            <a href="javascript:;">Dashboard </a></td>
                                    </tr>
                                </thead> */}
                                <tbody>
                                    {/* <tr>
                                        <td colspan="2" height="30px"></td>
                                    </tr> */}
                                    <tr>
                                        <td style={{ paddingLeft: '18px', paddingTop: '18px' }}>
                                            <h2>
                                                {/* Gestion d'equipe Swello */}
                                                <span>invitez {'&'} gerez les members de votre eqipe </span>
                                            </h2>
                                        </td>
                                        <td style={{ paddingRight: '18px', paddingTop: '18px' }} align="right">
                                            <a
                                                href="javascript:;"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.$('#CreateTeam').modal('show');
                                                }}
                                                className="btn">+ inviter un nouvel utilisateur</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">

                                            <table width="97.5%" cellpadding="25" className="team-inner-tbl">
                                                <thead>
                                                    <tr>
                                                        <td align="left"> Utilisateurs</td>
                                                        <td align="left">
                                                            Roles</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {MemberDeletMsg != '' &&
                                                        <tr ><td colSpan={'2'} style={{ textAlign: 'center' }}>{MemberDeletMsg}</td></tr>
                                                    }
                                                    {memberList.length > 0 &&
                                                        memberList.map((res, index) => (
                                                            <tr>
                                                                <td align="left" style={{ width: '50%', }}>{res.email}</td>
                                                                <td align="right">
                                                                    <select defaultValue={res.festival_role} onChange={(e) => festivalRoleChange(e, res.id)}>
                                                                        <option value={'4'} selected={(res.festival_role === '4') ? 'selected' : ''} >Admin</option>
                                                                        <option value={'5'} selected={(res.festival_role === '5') ? 'selected' : ''} >Member</option>
                                                                    </select>
                                                                    <a
                                                                        href="javascript:;"
                                                                        className="tbl-close-btn"
                                                                        onClick={() => memberEdit(res.id)}
                                                                    >
                                                                        <i className='fa fa-edit' />
                                                                    </a>
                                                                    <a
                                                                        href="javascript:;"
                                                                        className="tbl-close-btn"
                                                                        onClick={() => memberdelete(res.id)}
                                                                    >
                                                                        x
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }


                                                    {memberList.length === 0 &&
                                                        <tr><td colSpan={'2'} style={{ textAlign: 'center' }}>No data Found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    {/* <!-- team management Modal --> */}
                    <div className="modal fade c-login-modal CreateTeam" id="CreateTeam" tabindex="-1" aria-labelledby="CreateTeam" aria-hidden="true">
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    {/* <div className="c-modal-logo">
                                            <h2></h2>
                                        </div> */}
                                    <div className="c-festival-create-form">
                                        <div className="col-sm-12">
                                            <center> <h2>Create User</h2></center>
                                            <form onSubmit={handleSubmitAddTeam} className="team_management">
                                                <div className="c-form-field">
                                                    <select
                                                        className='form-control'
                                                        name='festival_role'
                                                        value={formValues.festival_role}
                                                        onChange={handleChangeAddTeam}
                                                    >
                                                        <option value=''>Select Role</option>
                                                        <option value={'4'}>Admin</option>
                                                        <option value={'5'}>Member</option>
                                                    </select>
                                                    {formErrors.festival_role != null &&
                                                        <div className='error_text'>{formErrors.festival_role}</div>
                                                    }
                                                </div>
                                                <div className="c-form-field">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter First name"
                                                        name="first_name"
                                                        id="first_name"
                                                        maxLength="50"
                                                        value={formValues.first_name}
                                                        onChange={handleChangeAddTeam} />
                                                    {formErrors.first_name != null &&
                                                        <div className='error_text'>{formErrors.first_name}</div>
                                                    }
                                                </div>
                                                <div className="c-form-field">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter last name"
                                                        name="people_name"
                                                        id="people_name"
                                                        maxLength="50"
                                                        value={formValues.people_name}
                                                        onChange={handleChangeAddTeam} />
                                                    {formErrors.people_name != null &&
                                                        <div className='error_text'>{formErrors.people_name}</div>
                                                    }
                                                </div>
                                                <div className="c-form-field">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        name="email"
                                                        id="email"
                                                        maxLength="50"
                                                        value={formValues.email}
                                                        onChange={handleChangeAddTeam} />
                                                    {formErrors.email != null &&
                                                        <div className='error_text'>{formErrors.email}</div>
                                                    }
                                                </div>

                                                <div className="c-form-field  c-phone-field CreateTeam-phone">
                                                    <PhoneInput
                                                        enableSearch='true'
                                                        onChange={handleOnChange}
                                                        placeholder="Enter Phone Number"
                                                    />
                                                    {formErrors.phonecode != null &&
                                                        <div className='error_text'>{formErrors.phonecode}</div>
                                                    }
                                                    {formErrors.phone != null &&
                                                        <div className='error_text'>{formErrors.phone}</div>
                                                    }
                                                    {/* <select
                                                        name='phonecode'
                                                        id="phonecode"
                                                        value={formValues.phonecode}
                                                        onChange={handleChangeAddTeam}
                                                    >
                                                        {CountryGet.length > 0 &&
                                                            CountryGet.map((res, index) => (
                                                                <option value={`+${res.phone_code}`}>{`+${res.phone_code}`}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="phone"
                                                        placeholder="Phone number"
                                                        id="phone"
                                                        pattern="[0-9]*"
                                                        value={formValues.phone}
                                                        onChange={handleChangeAddTeam}

                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                    {formErrors.phonecode != null &&
                                                        <div className='error_text'>{formErrors.phonecode}</div>
                                                    }
                                                    {formErrors.phone != null &&
                                                        <div className='error_text'>{formErrors.phone}</div>
                                                    } */}
                                                </div>

                                                <div className="c-form-field">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                        name="password"
                                                        id="password"
                                                        maxLength="50"
                                                        value={formValues.password}
                                                        onChange={handleChangeAddTeam} />
                                                    {formErrors.password != null &&
                                                        <div className='error_text'>{formErrors.password}</div>
                                                    }
                                                </div>

                                                <div className="c-form-field">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Enter Repeat Password"
                                                        name="confirm_password"
                                                        id="confirm_password"
                                                        maxLength="50"
                                                        value={formValues.confirm_password}
                                                        onChange={handleChangeAddTeam} />
                                                    {formErrors.confirm_password != null &&
                                                        <div className='error_text'>{formErrors.confirm_password}</div>
                                                    }
                                                </div>

                                                <div className="custome">
                                                    {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                                    <button className="btn" type="submit" style={{ zIndex: '1' }} >
                                                        <span>Submit</span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <!-- team management Modal Edit --> */}
                    <div className="modal fade c-login-modal CreateTeam" id="EditTeam" tabindex="-1" aria-labelledby="EditTeam" aria-hidden="true">
                        <div className="modal-dialog modal-md modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    {/* <div className="c-modal-logo">
                                            <h2></h2>
                                        </div> */}
                                    <div className="c-festival-create-form">
                                        <div className="col-sm-12">
                                            <center> <h2>Update User</h2></center>
                                            <form onSubmit={handleSubmitUpdateTeam} className="team_management">
                                                <div className="c-form-field">
                                                    <select
                                                        className='form-control'
                                                        name='festival_role'
                                                        value={formUpdateValues.festival_role}
                                                        onChange={handleChangeUpdateTeam}
                                                    >
                                                        <option value=''>Select Role</option>
                                                        <option value={'4'}>Admin</option>
                                                        <option value={'5'}>Member</option>
                                                    </select>
                                                    {formUpdateErrors.festival_role != null &&
                                                        <div className='error_text'>{formUpdateErrors.festival_role}</div>
                                                    }
                                                </div>
                                                <div className="c-form-field">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter First name"
                                                        name="first_name"
                                                        id="first_name"
                                                        maxLength="50"
                                                        value={formUpdateValues.first_name}
                                                        onChange={handleChangeUpdateTeam} />
                                                    {formUpdateErrors.first_name != null &&
                                                        <div className='error_text'>{formUpdateErrors.first_name}</div>
                                                    }
                                                </div>
                                                <div className="c-form-field">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter last name"
                                                        name="people_name"
                                                        id="people_name"
                                                        maxLength="50"
                                                        value={formUpdateValues.people_name}
                                                        onChange={handleChangeUpdateTeam} />
                                                    {formUpdateErrors.people_name != null &&
                                                        <div className='error_text'>{formUpdateErrors.people_name}</div>
                                                    }
                                                </div>
                                                <div className="c-form-field">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        name="email"
                                                        id="email"
                                                        maxLength="50"
                                                        value={formUpdateValues.email}
                                                        onChange={handleChangeUpdateTeam} />
                                                    {formUpdateErrors.email != null &&
                                                        <div className='error_text'>{formUpdateErrors.email}</div>
                                                    }
                                                </div>

                                                <div className="c-form-field  c-phone-field CreateTeam-phone">
                                                    <PhoneInput
                                                        enableSearch='true'
                                                        onChange={handleOnUpdateChange}
                                                        placeholder="Enter Phone Number"
                                                        value={formUpdateValues.phonecode + ' ' + formUpdateValues.phone}

                                                    />
                                                    {formUpdateErrors.phonecode != null &&
                                                        <div className='error_text'>{formUpdateErrors.phonecode}</div>
                                                    }
                                                    {formUpdateErrors.phone != null &&
                                                        <div className='error_text'>{formUpdateErrors.phone}</div>
                                                    }
                                                    {/* <select
                                                        name='phonecode'
                                                        id="phonecode"
                                                        value={formValues.phonecode}
                                                        onChange={handleChangeAddTeam}
                                                    >
                                                        {CountryGet.length > 0 &&
                                                            CountryGet.map((res, index) => (
                                                                <option value={`+${res.phone_code}`}>{`+${res.phone_code}`}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="phone"
                                                        placeholder="Phone number"
                                                        id="phone"
                                                        pattern="[0-9]*"
                                                        value={formValues.phone}
                                                        onChange={handleChangeAddTeam}

                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                    {formErrors.phonecode != null &&
                                                        <div className='error_text'>{formErrors.phonecode}</div>
                                                    }
                                                    {formErrors.phone != null &&
                                                        <div className='error_text'>{formErrors.phone}</div>
                                                    } */}
                                                </div>

                                                <div className="custome">
                                                    {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                                    <button className="btn" type="submit" style={{ zIndex: '1' }} >
                                                        <span>Submit</span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <Footer />
                <Outlet />
            </div >
        </>
    );
}

export default TeamManagement
