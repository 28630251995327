import React, { useEffect, useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'

function PageNotFound() {
    return (
        <>
            <Header />
            <div>Dashboard</div>

            <section class="c-dashboard-section">
                <div class="tab-pane" id="" role="" aria-labelledby="">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12 col-lg-6 col-md-6 col-sm-12 text-center">
                                <h1>404</h1>
                                <p>Page Not Found</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default PageNotFound
