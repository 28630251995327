import React, { useEffect, useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios';

import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import LoginHeader from '../component/loginHeader';
import Tokenization from '../component/Tokenization';

import DatePicker from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
const imagesize = 2;

function Tokenizations() {
    const location = useLocation();
    const sessiontoken = localStorage.getItem('token');
    if (sessiontoken === null) {
        window.location.href = "/";
    }
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const initialValuesAddTicket = {
        user_id: "", name: "", file: "", art_image: "",
        email_of_artist_responsibility: "", artist_resale_rights: "", gift_code: "", number_of_tickets: "",
        content: "",
        // start_date_time: new Date(), end_date_time: new Date((new Date()).valueOf() + 1000 * 3600 * 24) 
    };
    const [formValuesAddFestival, setFormValuesAddFestival] = useState(initialValuesAddTicket);

    const [Artist, setArtist] = useState({});
    const [formErrorsAddFestival, setFormErrorsAddFestival] = useState({});
    const [isSubmitAddFestival, setIsSubmitAddFestival] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [Message, setMessage] = useState("");
    const [FestivalData, setFestivalData] = useState([{}]);
    const [clicked, setClicked] = useState("0");

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    //Add Festival form
    const handleChangeAddFestival = (e) => {
        if (e.target.files != undefined) {
            const { name, files } = e.target;
            if (!files[0].type.match(imageMimeType)) {
                const errors = {};
                errors.file = "This file format is not supported";
                setFormErrorsAddFestival(errors);
                setFormValuesAddFestival({ ...formValuesAddFestival, [name]: "" });
            } else if ((files[0].size / 1024 / 1024) > imagesize) {
                const errors = {};
                errors.file = "Max upload file size is 2048";
                setFormErrorsAddFestival(errors);
                setFormValuesAddFestival({ ...formValuesAddFestival, [name]: "" });
            } else {
                setFormValuesAddFestival({ ...formValuesAddFestival, [name]: files[0] });
                const errors = {};
                errors.file = "";
                // setFormErrors(errors);
            }
        } else {
            const { name, value } = e.target;
            setFormValuesAddFestival({ ...formValuesAddFestival, [name]: value });
        }
    };

    const handleSubmitAddFestival = (e) => {
        e.preventDefault();
        setFormErrorsAddFestival(validateAddFestival(formValuesAddFestival));
        setIsSubmitAddFestival(true);
    };


    // Add Festival form
    const validateAddFestival = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        // if (moment(new Date()).format("YYYY/MM/DD") > moment(values.start_date_time).format("YYYY/MM/DD")) {
        //     errors.start_date_time = "Start Date not allow";
        // }

        // if (moment(new Date()).format("YYYY/MM/DD") > moment(values.end_date_time).format("YYYY/MM/DD")) {
        //     errors.end_date_time = "End Date not allow";
        // }

        // if (!values.start_date_time) {
        //     errors.start_date_time = "Start Date is required!";
        // }
        // if (!values.end_date_time) {
        //     errors.end_date_time = "End Date is required!";
        // }
        // if (values.start_date_time > values.end_date_time) {
        //     errors.date = "Please enter end date greater then start date";
        // }
        if (!values.file) {
            errors.file = "Image is required!";
        }
        if (!values.art_image) {
            errors.art_image = "Cover Image is required!";
        }
        // if (!values.artist_id) {
        //     errors.artist_id = " Artist is required!";
        // }
        if (!values.gift_code) {
            errors.gift_code = " Gift Code is required!";
        }
        if (!values.name) {
            errors.name = "Name is required!";
        }
        if (!values.email_of_artist_responsibility) {
            errors.email_of_artist_responsibility = "Email Of Artist is required!";
        }


        return errors;
    };

    const FestivalDelete = (index) => {
        const deletefestival = async () => {
            let apidata = {}
            apidata.festival_id = index
            await axios.post('festival/festival_delete', apidata)
                .then((result) => {
                    window.location.reload();
                    // setMessage(result.data.message)
                }).catch((error) => {
                    console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        deletefestival();
    }

    //Status Change event
    const [ServiceId, SetServiceId] = useState('');
    const StatusChange = (id) => {
        SetServiceId(id);
    }
    const [giftId, SetGiftId] = useState('');
    const StatusChangegift = (id) => {
        SetGiftId(id);
    }


    useEffect(() => {
        const getartist = async () => {
            await axios.get('users/artist')
                .then((result) => {
                    if (result.data.status == true) {
                        setArtist(result.data.data)
                    }
                })
        };
        getartist();

        const FestivalList = async () => {
            let apidata = {}
            if (user_data.id != null) {
                apidata.user_id = user_data.id
            } else {
                apidata.user_id = user_data.id
            }

            await axios.post('service/listuserService', apidata)
                .then((result) => {
                    let getData = result.data.data
                    setFestivalData(getData);
                    setMessage('')
                    setisLoading(false)

                }).catch((error) => {
                    console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        FestivalList();

        if (Object.keys(formErrorsAddFestival).length === 0 && isSubmitAddFestival) {
            const Festivalcreate = async () => {
                let StandData = new FormData();
                StandData.append("name", formValuesAddFestival.name)
                StandData.append("user_id", user_data.id)
                // StandData.append("artist_id", formValuesAddFestival.artist_id)
                StandData.append("email_of_artist_responsibility", formValuesAddFestival.email_of_artist_responsibility)
                StandData.append("artist_resale_rights", formValuesAddFestival.artist_resale_rights)
                StandData.append("gift_code", formValuesAddFestival.gift_code)
                StandData.append("number_of_tickets", formValuesAddFestival.number_of_tickets)
                // StandData.append("end_date_time", formValuesAddFestival.end_date_time)
                // StandData.append("start_date_time", formValuesAddFestival.start_date_time)
                StandData.append("file", formValuesAddFestival.file)
                StandData.append("art_image", formValuesAddFestival.art_image)
                StandData.append("content", formValuesAddFestival.content)

                await axios.post('service/create', StandData)
                    .then((result) => {
                        console.log(result);
                        setMessage(result.data.message)
                        window.$('#Createfestival').modal('hide');
                        window.location.reload();
                    }).catch((error) => {
                        console.log(error.response.data.message);
                        alert(error.response.data.message);
                        // setloggedinError(error.response.data);
                    })
            };
            Festivalcreate();
        }

        // ticket status
        if (ServiceId != '') {
            const TokenizationStatus = async () => {
                let service_id = {
                    "service_id": ServiceId
                }
                await axios.post('service/service_change_status', service_id)
                    .then((result) => {
                        setMessage(result.data.message);
                        FestivalList();
                        SetServiceId('');
                        // window.location.reload()
                    }).catch((error) => {
                        console.log(error);
                        // setloggedinError(error.response.data);
                    })
            }
            TokenizationStatus();
        }
        // gift staus 
        if (giftId != '') {
            const GiftStatus = async () => {
                let service_id = {
                    "service_id": giftId
                }
                await axios.post('service/service_change_giftstatus', service_id)
                    .then((result) => {
                        setMessage(result.data.message);
                        FestivalList();
                        SetGiftId('');
                    }).catch((error) => {
                        console.log(error);
                        // setloggedinError(error.response.data);
                    })
            }
            GiftStatus();
        }
    }, [formErrorsAddFestival, ServiceId, giftId]);

    return (
        // this.state.isLoading ? *showLoadingScreen* 
        <>
            <Header />

            <div>FESTIVAL MANAGEMENT</div>

            <section className="c-dashboard-section">
                {/* {location.pathname != "/festival" &&
                    <LoginHeader Stand={false} standData={[]} />
                } */}

                <div className="tab-content">

                    {/* {location.pathname != "/festival" &&
                        <div className="tab-pane" id="my-festival" role="tabpanel" aria-labelledby="my-festival-tab">
                            <h2>
                                <div className="container-fluid">
                                    <span>FESTIVAL MANAGEMENT</span>
                                </div>
                            </h2>
                        </div>
                    } */}

                    <div className="container-fluid">
                        <h2 className="c-section-title">Tokenization</h2>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            window.$('#Createfestival').modal('show');
                        }} className="create-festival-link"> <span>+</span> Creat A New</a>
                    </div>

                    {Message != '' &&
                        <h3><div className='success_text' style={{ textAlign: "center" }}>{Message}</div></h3>
                    }
                    <div className="container-fluid festival-management-page">
                        <div className="accordion-content">
                            {isLoading == false && FestivalData.length > 0 &&
                                FestivalData.map((data, index) => (
                                    <Tokenization
                                        name="festival"
                                        onToggle={() => handleToggle(index)}
                                        onClick={() => {
                                            const confirmBox = window.confirm(
                                                "Do you really want to delete this Festival?"
                                            )
                                            if (confirmBox === true) {
                                                FestivalDelete(data.id)
                                            }
                                        }}
                                        onChange={() => StatusChange(data.id)}
                                        onChangegift={() => StatusChangegift(data.id)}
                                        active={clicked === index}
                                        key={index}
                                        content={data} />
                                ))
                            }

                            {FestivalData.length === 0 && <div className='no_record'>No data found</div>}
                        </div>

                    </div>
                </div>
            </section>

            {/* <!-- Login Modal --> */}
            <div className="modal fade c-login-modal" id="Createfestival" tabindex="-1" aria-labelledby="Createfestival" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {/* <div className="c-modal-logo">
                                <h2></h2>
                            </div> */}
                            <div className="c-festival-create-form">
                                <div className="col-sm-12">
                                    <center> <h2>Create Tokenization</h2></center>
                                    <form onSubmit={handleSubmitAddFestival}>
                                        {/* <div className="c-form-field">
                                            <select
                                                onChange={handleChangeAddFestival}
                                                value={formValuesAddFestival.artist_id}
                                                name="artist_id"
                                                id="artist_id"
                                                className="form-control"
                                            >
                                                <option Selected >Select Artist</option>
                                              {Artist.length > 0 &&
                                                    Artist.map((res, i) => (
                                                        <option value={res.id}>{res.first_name}{res.people_name}</option>
                                                    ))
                                                }
                                            </select>
                                            {formErrorsAddFestival.artist_id != null &&
                                                <div className='error_text'>{formErrorsAddFestival.artist_id}</div>
                                            }

                                        </div> */}
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Artist name"
                                                name="name"
                                                id="name"
                                                maxLength="50"
                                                value={formValuesAddFestival.name}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.name != null &&
                                                <div className='error_text'>{formErrorsAddFestival.name}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Number Of Ticket "
                                                name="number_of_tickets"
                                                id="number_of_tickets"
                                                maxLength="50"
                                                value={formValuesAddFestival.number_of_tickets}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.number_of_tickets != null &&
                                                <div className='error_text'>{formErrorsAddFestival.number_of_tickets}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter Email Of Artisat Responsibility"
                                                name="email_of_artist_responsibility"
                                                id="email_of_artist_responsibility"
                                                maxLength="50"
                                                value={formValuesAddFestival.email_of_artist_responsibility}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.email_of_artist_responsibility != null &&
                                                <div className='error_text'>{formErrorsAddFestival.email_of_artist_responsibility}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Artist Resale Rights"
                                                name="artist_resale_rights"
                                                id="artist_resale_rights"
                                                maxLength="50"
                                                value={formValuesAddFestival.artist_resale_rights}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.artist_resale_rights != null &&
                                                <div className='error_text'>{formErrorsAddFestival.artist_resale_rights}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Gift Code"
                                                name="gift_code"
                                                id="gift_code"
                                                maxLength="50"
                                                value={formValuesAddFestival.gift_code}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.gift_code != null &&
                                                <div className='error_text'>{formErrorsAddFestival.gift_code}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter About The Artist"
                                                name="content"
                                                id="content"
                                                maxLength="50"
                                                value={formValuesAddFestival.content}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.content != null &&
                                                <div className='error_text'>{formErrorsAddFestival.content}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            {/* <label>Thumb Image</label> */}
                                            <input
                                                type="file"
                                                className="form-control"
                                                placeholder="Enter Upload logo"
                                                name="file"
                                                id="file"
                                                accept=".png,.jpg,.jpeg,.webp"
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.file != null &&
                                                <div className='error_text'>{formErrorsAddFestival.file}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <label>Cover Image</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                placeholder="Enter Upload Cover Image"
                                                name="art_image"
                                                id="art_image"
                                                accept=".png,.jpg,.jpeg,.webp"
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.art_image != null &&
                                                <div className='error_text'>{formErrorsAddFestival.art_image}</div>
                                            }
                                        </div>
                                        {/* <p>How many Fesival-goer do you have in your festival?</p> */}
                                        {/* <input type="range" min="100" max="350" /> */}
                                        {/* <Slider
                                            min={50}
                                            max={100}
                                            tooltip={false}
                                            value={formValuesAddFestival.festival_count}
                                            handleLabel={formValuesAddFestival.festival_count}
                                            onChange={(value) => setFormValuesAddFestival({ ...formValuesAddFestival, 'festival_count': value })}
                                        />
                                        {formErrorsAddFestival.festival_count != null &&
                                            <div className='error_text'>{formErrorsAddFestival.festival_count}</div>
                                        } */}

                                        {/* <p>When your festival start and End?</p> */}

                                        {/* <div autocomplete="off" className="date-picker">
                                            <div className="flex-row d-flex justify-content-center">
                                                <div className="input-group input-daterange row">
                                                    <div className="default-datepicker">
                                                        <DatePicker
                                                            className="form-control text-left mr-2 col-md-6"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="Start date"
                                                            todayButton="Today"
                                                            selected={formValuesAddFestival.start_date_time}
                                                            onChange={(date) => setFormValuesAddFestival({ ...formValuesAddFestival, 'start_date_time': date })}
                                                            startDate={formValuesAddFestival.start_date_time}
                                                            endDate={formValuesAddFestival.end_date_time}
                                                            minDate={formValuesAddFestival.start_date_time}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        {formErrorsAddFestival.start_date_time != null &&
                                                            <div className='error_text'>{formErrorsAddFestival.start_date_time}</div>
                                                        }
                                                    </div>

                                                    <div className='default-datepicker'>
                                                        <DatePicker
                                                            className="form-control text-left mr-2  col-md-6"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="End date"
                                                            selected={formValuesAddFestival.end_date_time}
                                                            onChange={(date) => setFormValuesAddFestival({ ...formValuesAddFestival, 'end_date_time': date })}
                                                            selectsEnd
                                                            startDate={formValuesAddFestival.start_date_time}
                                                            endDate={formValuesAddFestival.end_date_time}
                                                            minDate={formValuesAddFestival.start_date_time}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        {formErrorsAddFestival.end_date_time != null &&
                                                            <div className='error_text'>{formErrorsAddFestival.end_date_time}</div>
                                                        }
                                                        {formErrorsAddFestival.date != null &&
                                                            <div className='error_text'>{formErrorsAddFestival.date}</div>
                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="custome">
                                            {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                            <button className="btn" type="submit" >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Outlet />
        </>
    );
}

export default Tokenizations
