import React, { useEffect, useState, setCookie } from 'react'
// import React from 'react'
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom'
import axios from '../axios';

function Header() {


    const sessiontoken = localStorage.getItem('token');

    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const location = useLocation();
    const [Token, setToken] = useState({});

    const logout = () => {
        localStorage.clear();
        window.location.href = 'signup'
    }
     useEffect(() => {
        // if (sessiontoken != null) {
            const tokencheck = async () => {
                const user_id = {
                    user_id: user_data.id
                }
                await axios.post('commonapi/user_status_check' , user_id)
                    .then((result) => {
                        console.log(result)
                        if (result.data.data != 'active') {
                            localStorage.clear();
                        }
                        else{
                            setToken(result.data)
                        }
                    })
            };
            tokencheck();
        // }
    }, []);
   
    // const googleTranslateElementInit = () => {
    //     // setCookie('google_translate_element', '/en/pt',1);
    //     new window.google.translate.TranslateElement(
    //         {
    //             includedLanguages: 'fr,en',
    //             // pageLanguage: "en",  
    //             autoDisplay: false
    //         },
    //         "google_translate_element"
    //     );
    // };

    // useEffect(() => {
    //     var addScript = document.createElement("script");
    //     addScript.setAttribute(
    //         "src",
    //         "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    //     );
    //     document.body.appendChild(addScript);
    //     window.googleTranslateElementInit = googleTranslateElementInit;
    // }, []);
    // const handleClick = () => {
    //     // document.cookie = "ck";
    //     console.log(document.cookie);
    // };


    return (
        <>
            {/* <div id="loader2">
                <img className="blurOut" src="/asset/images/logo.png" alt="" />
            </div> */}
            <header className="c-header">
                <div className="container-fluid">
                    {/* <div  id="google_translate_element">
                    </div> */}
                    {/* onClick={() => handleClick()} */}
                    <nav className="navbar navbar-expand-lg navbar-light">
                        {sessiontoken === null &&
                            <Link className="navbar-brand c-logo" to="/">
                                <img src="/asset/images/logo.png" alt="" />
                            </Link>
                        }
                        {sessiontoken !== null &&
                            <Link className="navbar-brand c-logo" to="/festival">
                                <img src="/asset/images/logo.png" alt="" />
                            </Link>
                        }


                        <div className="collapse navbar-collapse">
                            <div className="header_nav">
                                {sessiontoken !== null &&
                                    <Link className="" to="/festival" >
                                        <center><span>FESTIVAL</span></center>
                                    </Link>
                                }&emsp;
                               {sessiontoken !== null &&

                                    <Link className="lottery_menu" to="/lottery" >
                                        <center><span>LOTTERY</span></center>
                                    </Link>

                                }&emsp;
                                {sessiontoken !== null &&
                                    <Link className="tickets_menu" to="/tokenization" >
                                        <center><span>TOKENIZATION</span></center>
                                    </Link>
                                }
                            </div>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href="javascript:;" data-bs-toggle="modal" data-bs-target="#languageSelection">
                                        <img src="/asset/images/global.png" alt="" />
                                    </a>
                                </li>

                                {location.pathname === '/' && sessiontoken === null &&
                                    <li className="nav-item">
                                        <a class="btn c-header-btn" href="/director">
                                            <span>
                                                <img src="asset/images/refresh-icon.png" alt="" /> <b>FOR FESTIVAL DIRECTOR</b>
                                            </span>
                                        </a>
                                    </li>
                                }


                                {(location.pathname === '/director' || location.pathname === '/signup') && sessiontoken === null &&
                                    <li className="nav-item">
                                        <a class="btn c-header-btn header-login-btn" href="javascript:;" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#loginModal">
                                            <span>
                                                <b>Login</b>
                                            </span>
                                        </a>
                                    </li>
                                }

                                {(location.pathname === '/director' || location.pathname === '/signup') && sessiontoken === null &&

                                    <li className="nav-item">
                                        <a class="btn c-header-btn" href="/signup">
                                            <span>
                                                <img src="asset/images/refresh-icon.png" alt="" /> <b>FESTIVAL-GOER</b>
                                            </span>
                                        </a>
                                    </li>
                                }

                                {sessiontoken !== null &&
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="c-profile-image">
                                                <span>
                                                    {user_data.photo == '' &&
                                                        <img src="/asset/images/default_image.png" alt="" />
                                                    }

                                                    {user_data.photo != '' &&
                                                        <img src={`https://api.cph4.ch/${user_data.photo.replace('public/', '')}`} alt="" />
                                                    }
                                                </span>
                                            </div>
                                            <div className="c-dropdown">
                                                <img src="/asset/images/dropdown.svg" alt="" />
                                            </div>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDarkDropdownMenuLink">
                                            <li>
                                                <Link className="dropdown-item" to="/festival">My Festival</Link>
                                            </li>
                                            <li>

                                                {/* style={{ pointerEvents: 'none', opacity: '0.6' }} */}
                                                <Link className="dropdown-item" to="/profile">Edit Profile</Link>
                                            </li>
                                            <li><a className="dropdown-item" href="#" onClick={logout}> Log out</a></li>
                                            <li style={{ display: 'none' }}><a className="dropdown-item" href="#" onClick={logout}> Log out</a></li>
                                        </ul>
                                    </li>
                                }

                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

        </>
    );
}

export default Header