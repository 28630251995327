import React, { useEffect, useState } from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import axios from '../axios';

import { Routes, Route, Outlet, Link, useLocation } from "react-router-dom";
import LoginHeader from '../component/loginHeader';
import Accordion from '../component/Accordion';

import DatePicker from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'



function FesivalList() {
    const location = useLocation();

    const sessiontoken = localStorage.getItem('token');
    if (sessiontoken === null) {
        window.location.href = "/";
    }

    const user_data = JSON.parse(localStorage.getItem('user_data'));



    const initialValuesAddFestival = { festival_name: "", festival_position: user_data.festival_role, festival_count: "50", start_date: new Date(), end_date: new Date((new Date()).valueOf() + 1000 * 3600 * 24), first_name: user_data.first_name, last_name: user_data.people_name, email: user_data.email, phone_number: user_data.phone };
    const [formValuesAddFestival, setFormValuesAddFestival] = useState(initialValuesAddFestival);
    const [formErrorsAddFestival, setFormErrorsAddFestival] = useState({});
    const [isSubmitAddFestival, setIsSubmitAddFestival] = useState(false);

    const [isLoading, setisLoading] = useState(true);

    const [Message, setMessage] = useState("");

    const [FestivalData, setFestivalData] = useState([{}]);


    const [clicked, setClicked] = useState("0");

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    //Add Festival form
    const handleChangeAddFestival = (e) => {
        const { name, value } = e.target;
        setFormValuesAddFestival({ ...formValuesAddFestival, [name]: value });
    };

    const handleSubmitAddFestival = (e) => {
        e.preventDefault();
        setFormErrorsAddFestival(validateAddFestival(formValuesAddFestival));
        setIsSubmitAddFestival(true);
    };


    // Add Festival form
    const validateAddFestival = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.festival_name) {
            errors.festival_name = "Festival name is required!";
        }

        if (!values.festival_position) {
            errors.festival_position = "Position Of Festival is required!";
        }

        if (!values.festival_count) {
            errors.festival_count = "Festival goer is required!";
        }

        if (moment(new Date()).format("YYYY/MM/DD") > moment(values.start_date).format("YYYY/MM/DD")) {
            errors.start_date = "Start Date not allow";
        }

        if (moment(new Date()).format("YYYY/MM/DD") > moment(values.end_date).format("YYYY/MM/DD")) {
            errors.end_date = "End Date not allow";
        }

        if (!values.start_date) {
            errors.start_date = "Start Date is required!";
        }

        if (!values.end_date) {
            errors.end_date = "End Date is required!";
        }


        if (values.start_date > values.end_date) {
            errors.date = "Please enter end date greater then start date";
        }

        return errors;
    };

    const FestivalDelete = (index) => {
        const deletefestival = async () => {
            let apidata = {}
            apidata.festival_id = index
            await axios.post('festival/festival_delete', apidata)
                .then((result) => {
                    // console.log(result.data.message)
                    window.location.reload();
                    // setMessage(result.data.message)
                }).catch((error) => {
                    console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        deletefestival();
    }

    //Status Change event
    const [FestivalId, SetFestivalId] = useState('');
    const StatusChange = (id) => {
        SetFestivalId(id);
    }


    useEffect(() => {

        const FestivalList = async () => {
            let apidata = {}
            if (user_data.parent_user_id != null) {
                apidata.member_id = user_data.parent_user_id
            } else {
                apidata.member_id = user_data.id
            }

            await axios.post('festival/memberFestivalList', apidata)
                .then((result) => {

                    let getData = result.data.data
                    setFestivalData(getData);
                    setMessage('')
                    setisLoading(false)

                }).catch((error) => {
                    console.log(error);
                    // setloggedinError(error.response.data);
                })
        };
        FestivalList();

        if (Object.keys(formErrorsAddFestival).length === 0 && isSubmitAddFestival) {
            const Festivalcreate = async () => {
                formValuesAddFestival.user_id = user_data.id
                await axios.post('festival/memberFestivalCreate', formValuesAddFestival)
                    .then((result) => {
                        console.log(result);
                        setMessage(result.data.message)
                        window.$('#Createfestival').modal('hide');
                        window.location.reload();
                    }).catch((error) => {
                        console.log(error.response.data.message);
                        alert(error.response.data.message);
                        // setloggedinError(error.response.data);
                    })
            };
            Festivalcreate();
        }

        if (FestivalId != '') {
            console.log(FestivalId);
            const FestivalStatus = async () => {
                let festival_id = {
                    "festival_id": FestivalId
                }
                await axios.post('festival/festival_status_change', festival_id)
                    .then((result) => {
                        setMessage(result.data.message);
                        FestivalList();
                        SetFestivalId('');
                        // window.location.reload()
                    }).catch((error) => {
                        console.log(error);
                        // setloggedinError(error.response.data);
                    })
            }
            FestivalStatus();
        }



    }, [formErrorsAddFestival, FestivalId]);

    return (
        // this.state.isLoading ? *showLoadingScreen* 
        <>
            <Header />

            <div>FESTIVAL MANAGEMENT</div>

            <section className="c-dashboard-section">
                {location.pathname != "/festival" &&
                    <LoginHeader Stand={false} standData={[]} />
                }

                <div className="tab-content">

                    {location.pathname != "/festival" &&
                        <div className="tab-pane" id="my-festival" role="tabpanel" aria-labelledby="my-festival-tab">
                            <h2>
                                <div className="container-fluid">
                                    <span>FESTIVAL MANAGEMENT</span>
                                </div>
                            </h2>
                        </div>
                    }

                    <div className="container-fluid">
                        <h2 className="c-section-title"> Festival Management</h2>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            window.$('#Createfestival').modal('show');
                        }} className="create-festival-link"> <span>+</span> CREATE FESTIVAL </a>
                    </div>

                    {Message != '' &&
                        <h3><div className='success_text' style={{ textAlign: "center" }}>{Message}</div></h3>
                    }
                    <div className="container-fluid festival-management-page">
                        <div className="accordion-content">

                            {isLoading == false && FestivalData.length > 0 &&
                                FestivalData.map((data, index) => (
                                    <Accordion
                                        name="festival"
                                        onToggle={() => handleToggle(index)}
                                        onClick={() => {
                                            const confirmBox = window.confirm(
                                                "Do you really want to delete this Festival?"
                                            )
                                            if (confirmBox === true) {
                                                FestivalDelete(data.id)
                                            }
                                        }}
                                        onChange={() => StatusChange(data.id)}
                                        active={clicked === index}
                                        key={index}
                                        content={data} />
                                ))
                            }

                            {FestivalData.length === 0 && <div className='no_record'>No data found</div>}
                        </div>

                    </div>
                </div>
            </section>

            {/* <!-- Login Modal --> */}
            <div className="modal fade c-login-modal" id="Createfestival" tabindex="-1" aria-labelledby="Createfestival" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            {/* <div className="c-modal-logo">
                                <h2></h2>
                            </div> */}
                            <div className="c-festival-create-form">
                                <div className="col-sm-12">
                                    <center> <h2>Create Festival</h2></center>
                                    <form onSubmit={handleSubmitAddFestival}>
                                        <div className="c-form-field">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter festival name"
                                                name="festival_name"
                                                id="festival_name"
                                                maxLength="50"
                                                value={formValuesAddFestival.festival_name}
                                                onChange={handleChangeAddFestival} />
                                            {formErrorsAddFestival.festival_name != null &&
                                                <div className='error_text'>{formErrorsAddFestival.festival_name}</div>
                                            }
                                        </div>
                                        <div className="c-form-field">
                                            <select
                                                name='festival_position'
                                                className="form-control"
                                                onChange={handleChangeAddFestival}
                                                value={formValuesAddFestival.festival_position}>
                                                <option value="" >Select Festival Position</option>
                                                <option value={'4'}>Festival Director</option>
                                                <option value={'5'}>Employee Manage</option>
                                            </select>
                                            {/* <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Your position for this festival"
                                                name="festival_position"
                                                id="festival_position"
                                                value={formValuesAddFestival.festival_position}
                                                onChange={handleChangeAddFestival} /> */}
                                            {formErrorsAddFestival.festival_position != null &&
                                                <div className='error_text'>{formErrorsAddFestival.festival_position}</div>
                                            }
                                        </div>
                                        <p>How many Fesival-goer do you have in your festival?</p>
                                        {/* <input type="range" min="100" max="350" /> */}
                                        <Slider
                                            min={50}
                                            max={100}
                                            tooltip={false}
                                            value={formValuesAddFestival.festival_count}
                                            handleLabel={formValuesAddFestival.festival_count}
                                            onChange={(value) => setFormValuesAddFestival({ ...formValuesAddFestival, 'festival_count': value })}
                                        />
                                        {formErrorsAddFestival.festival_count != null &&
                                            <div className='error_text'>{formErrorsAddFestival.festival_count}</div>
                                        }

                                        <p>When your festival start and End?</p>

                                        <div autocomplete="off" className="date-picker">
                                            <div className="flex-row d-flex justify-content-center">
                                                <div className="input-group input-daterange row">
                                                    <div className="default-datepicker">
                                                        <DatePicker
                                                            className="form-control text-left mr-2 col-md-6"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="Start date"
                                                            todayButton="Today"
                                                            selected={formValuesAddFestival.start_date}
                                                            onChange={(date) => setFormValuesAddFestival({ ...formValuesAddFestival, 'start_date': date })}
                                                            startDate={formValuesAddFestival.start_date}
                                                            endDate={formValuesAddFestival.end_date}
                                                            minDate={formValuesAddFestival.start_date}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        {formErrorsAddFestival.start_date != null &&
                                                            <div className='error_text'>{formErrorsAddFestival.start_date}</div>
                                                        }
                                                    </div>

                                                    <div className='default-datepicker'>
                                                        <DatePicker
                                                            className="form-control text-left mr-2  col-md-6"
                                                            dateFormat="dd-MM-yyyy"
                                                            placeholderText="End date"
                                                            selected={formValuesAddFestival.end_date}
                                                            onChange={(date) => setFormValuesAddFestival({ ...formValuesAddFestival, 'end_date': date })}
                                                            selectsEnd
                                                            startDate={formValuesAddFestival.start_date}
                                                            endDate={formValuesAddFestival.end_date}
                                                            minDate={formValuesAddFestival.start_date}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        {formErrorsAddFestival.end_date != null &&
                                                            <div className='error_text'>{formErrorsAddFestival.end_date}</div>
                                                        }
                                                        {formErrorsAddFestival.date != null &&
                                                            <div className='error_text'>{formErrorsAddFestival.date}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="custome">
                                            {/* data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#verificationModal" */}
                                            <button className="btn" type="submit" >
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Outlet />
        </>
    );
}

export default FesivalList
